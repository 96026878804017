import {
  faAngleDown,
  faAngleUp,
  faBoxesStacked,
  faCaretDown,
  faCog,
  faCreditCard,
  faCube,
  faFileDownload,
  faFileLines,
  faList,
  faLock,
  faMagnifyingGlass,
  faSignOut,
  faStar,
  faTableCells,
  faTabletAlt,
  faTree,
  faTruckMoving,
  faUserCircle,
  faUsers
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import OrganizationSelector, {
  currentOrganizationState
} from "common/navigation/OrganizationSelector"
import {
  accountSettingsState,
  featureEnabled,
  measurementsModeState
} from "common/recoil/atoms"
import useAuthActions from "login/useAuthActions"
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Image,
  Nav,
  NavDropdown,
  Offcanvas,
  Row
} from "react-bootstrap"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { selector, useRecoilState, useRecoilValue } from "recoil"
import { offcanvasSidebarState } from "./Header"
import { subscriptionExistsSelector } from "billing/TimbeterPro"
import { capitalize } from "lodash"
import LogsIcon from "./LogsIcon"
import { LocaleSelector } from "common/navigation/LocaleSelector"

export const isCurrentUserAdminSelector = selector({
  key: "isCurrentUserAdmin",
  get: ({ get }) =>
    get(currentUserSelector)?.roles?.some(
      role =>
        role?.name?.includes("admin") &&
        role?.type === "Organization" &&
        role?.resource_id === get(currentOrganizationState)?.id
    )
})

export const currentUserSelector = selector({
  key: "currentUser",
  get: ({ get }) => get(accountSettingsState)
})

export default function Sidebar() {
  const [showOffcanvas, setShowOffcanvas] = useRecoilState(
    offcanvasSidebarState
  )
  const measurementsMode = useRecoilValue(measurementsModeState)
  const currentUserIsAdmin = useRecoilValue(isCurrentUserAdminSelector)
  const customFieldsEnabled = useRecoilValue(featureEnabled("custom_fields"))
  const reportsEnabled = useRecoilValue(featureEnabled("reports"))
  const duplicatesDetectionEnabled = useRecoilValue(
    featureEnabled("duplicate_storage_measurements")
  )
  const inventoriesEnabled = useRecoilValue(featureEnabled("inventories"))
  const currentOrganization = useRecoilValue(currentOrganizationState)
  const account = useRecoilValue(accountSettingsState)
  const { logOut } = useAuthActions()
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <div className="bg-gradient h-100 p-0 d-none col-md-4 col-xl-3 col-xxxl-2 d-md-block position-fixed bg-light">
        <Navigation />
      </div>

      <Offcanvas
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        className="w-auto"
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>
            <img
              className="logo ms-3"
              src="/logos/timbeter.svg"
              alt="Timbeter"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-2">
          <Navigation />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )

  function Navigation() {
    return (
      <Nav
        as="nav"
        variant="pills"
        className="sidebar h-100 d-block"
        style={{ left: 0 }}
      >
        <div className="d-none bg-primary d-md-block p-3">
          <img src="/logos/timbeter.svg" className="logo" alt="Timbeter" />
        </div>
        <div
          className="px-2 d-flex flex-column"
          style={{ overflowY: "scroll", height: "calc(100% - 57px)" }}
        >
          {!subscriptionExists && (
            <Button
              onClick={() => navigate("/billing#subscription-plans")}
              size="sm"
              variant="warning"
              className="w-full bg-gradient fw-medium my-2"
            >
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                className="me-2"
                style={{ width: "1rem" }}
              />
              <Trans>Start subscription</Trans>
            </Button>
          )}
          {/* <Nav.Item className="d-inline-flex me-2">
            <Nav.Link as={NavLink} to="/measurements">

            </Nav.Link>
          </Nav.Item> */}
          <Dropdown as={ButtonGroup} className="w-full mt-2 mb-1">
            <Button
              className="text-start text-truncate"
              size="sm"
              style={{
                padding: "4px 12px",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px"
              }}
              variant={
                location.pathname.includes("/measurements")
                  ? "primary"
                  : "light"
              }
              onClick={() => navigate("/measurements")}
            >
              {measurementsMode === "timber" && (
                <LogsIcon
                  fill={
                    location.pathname.includes("/measurements")
                      ? "white"
                      : "black"
                  }
                />
              )}
              {measurementsMode === "lumber" && (
                <FontAwesomeIcon
                  icon={faTableCells}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
              )}
              {measurementsMode === "container" && (
                <FontAwesomeIcon
                  icon={faCube}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
              )}
              {measurementsMode === "truck" && (
                <FontAwesomeIcon
                  icon={faTruckMoving}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
              )}
              {measurementsMode === "cmpc" && (
                <Image
                  width={15}
                  height={16}
                  src="/logos/cmpc.png"
                  className="me-2"
                />
              )}
              {measurementsMode === "cmpc"
                ? measurementsMode.toUpperCase()
                : capitalize(measurementsMode)}{" "}
              <Trans>dashboard</Trans>
            </Button>
            <Dropdown.Toggle
              size="sm"
              className="px-2 flex-grow-0"
              style={{
                borderBottomRightRadius: "6px",
                borderTopRightRadius: "6px"
              }}
              variant={
                location.pathname.includes("/measurements")
                  ? "primary"
                  : "light"
              }
            >
              <FontAwesomeIcon
                size="lg"
                icon={faCaretDown}
                style={{ width: "1.5rem" }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <NavDropdown.Item
                onClick={() => navigate("/measurements/timber")}
              >
                <LogsIcon />
                <Trans>Timber</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/measurements/container")}
              >
                <FontAwesomeIcon
                  icon={faCube}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
                <Trans>Container</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/measurements/truck")}>
                <FontAwesomeIcon
                  icon={faTruckMoving}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
                <Trans>Truck</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/measurements/lumber")}
              >
                <FontAwesomeIcon
                  icon={faTableCells}
                  size="lg"
                  className="me-2"
                  style={{ width: "1rem" }}
                />
                <Trans>Lumber</Trans>
              </NavDropdown.Item>
              {(process.env.REACT_APP_ENV !== "production" ||
                currentOrganization?.id === 1 ||
                currentOrganization?.id === 3664 ||
                currentOrganization?.id === 790) && (
                <NavDropdown.Item
                  onClick={() => navigate("/measurements/cmpc")}
                >
                  <Image
                    width={15}
                    height={16}
                    src="/logos/cmpc.png"
                    className="me-2"
                  />
                  <Trans>CMPC</Trans>
                </NavDropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Nav.Item>
            <Nav.Link
              className="d-flex align-items-center"
              as={NavLink}
              to="/exports"
            >
              <FontAwesomeIcon
                icon={faFileDownload}
                className="me-2"
                size="lg"
                style={{ width: "1rem" }}
              />
              <span className="flex-grow-1">
                <Trans>Exports</Trans>
              </span>
            </Nav.Link>
          </Nav.Item>
          {currentUserIsAdmin ? (
            <>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/devices">
                  <FontAwesomeIcon
                    icon={faTabletAlt}
                    className="me-2"
                    size="lg"
                    style={{ width: "1rem" }}
                  />
                  <Trans>Devices</Trans>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  className="text-truncate"
                  to="/timber/species"
                >
                  <FontAwesomeIcon
                    icon={faTree}
                    className="me-2"
                    size="lg"
                    style={{ width: "1rem" }}
                  />
                  <Trans>Timber management</Trans>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/users">
                  <FontAwesomeIcon
                    size="lg"
                    icon={faUsers}
                    className="me-2"
                    style={{ width: "1rem" }}
                  />
                  <Trans>Users</Trans>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  className="d-flex align-items-center"
                  to="/custom_fields"
                >
                  <FontAwesomeIcon
                    icon={faList}
                    size="lg"
                    className="me-2"
                    style={{ width: "1rem" }}
                  />
                  <span className="flex-grow-1">
                    <Trans>Custom fields</Trans>
                  </span>
                  {!customFieldsEnabled && (
                    <FontAwesomeIcon icon={faLock} className="opacity-25" />
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center"
                  as={NavLink}
                  to="/reports"
                >
                  <FontAwesomeIcon
                    icon={faFileLines}
                    size="lg"
                    className="me-2"
                    style={{ width: "1rem" }}
                  />
                  <span className="flex-grow-1">
                    <Trans>Reports</Trans>
                  </span>
                  {!reportsEnabled && (
                    <FontAwesomeIcon icon={faLock} className="opacity-25" />
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center"
                  as={NavLink}
                  to="/inventories"
                >
                  <FontAwesomeIcon
                    icon={faBoxesStacked}
                    size="lg"
                    className="me-2"
                    style={{ width: "1rem" }}
                  />
                  <span className="flex-grow-1">
                    <Trans>Inventories</Trans>
                  </span>
                  {!inventoriesEnabled && (
                    <FontAwesomeIcon icon={faLock} className="opacity-25" />
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center text-truncate"
                  as={NavLink}
                  to="/analyze_reports"
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="me-2"
                    style={{ width: "1rem" }}
                    size="lg"
                  />
                  <span className="flex-grow-1">
                    <Trans>Duplicates detection</Trans>
                  </span>
                  {!duplicatesDetectionEnabled && (
                    <FontAwesomeIcon icon={faLock} className="opacity-25" />
                  )}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center"
                  as={NavLink}
                  to="/settings"
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    className="me-2"
                    style={{ width: "1rem" }}
                    size="lg"
                  />
                  <span className="flex-grow-1">
                    <Trans>Settings</Trans>
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center"
                  as={NavLink}
                  to="/billing"
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className="me-2"
                    style={{ width: "1rem" }}
                    size="lg"
                  />
                  <span className="flex-grow-1">
                    <Trans>Billing</Trans>
                  </span>
                </Nav.Link>
              </Nav.Item>
            </>
          ) : null}
          <Dropdown align="end">
            <Dropdown.Toggle
              style={{ padding: "10px 12px", borderRadius: "6px" }}
              variant=""
              size="sm"
              className="hover-gray d-flex mb-2 border py-2 mt-1 text-start w-full shadow-sm bg-white rounded-3"
            >
              <Row
                className="align-items-center flex-nowrap text-truncate"
                style={{ width: "200rem" }}
              >
                <Col xs="auto" className="px-0 px-2">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="opacity-25"
                    size="2x"
                  />
                </Col>
                <Col className="px-0 text-truncate">
                  <Row xs="1">
                    <Col className="fw-semibold">
                      {account?.name || account?.email}
                    </Col>
                    <Col className="text-muted small">
                      {currentOrganization ? (
                        <span className="text-wrap">
                          {currentOrganization.name}
                        </span>
                      ) : (
                        <span className="text-black-50">
                          <Trans>No organization</Trans>
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <Row xs="1" className="lh-1" style={{ width: "min-content" }}>
                    <Col>
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        className="opacity-50"
                      />
                    </Col>
                    <Col>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="opacity-50"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <LocaleSelector />
              <OrganizationSelector />
              <NavDropdown.Item onClick={logOut}>
                <FontAwesomeIcon
                  icon={faSignOut}
                  className="me-2 opacity-50"
                  style={{ width: "1rem" }}
                />

                <Trans>Sign out</Trans>
              </NavDropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Nav.Item onClick={() => window.Intercom("show")}>
            <Nav.Link className="d-flex align-items-center  text-secondary opacity-75">
              <span className="flex-grow-1">
                <FontAwesomeIcon icon={faCircleQuestion} className="me-2 opacity-50" />
                <Trans>Contact support</Trans>
              </span>
            </Nav.Link>
          </Nav.Item> */}
          {/* {process.env.NODE_ENV !== "production" && (
        <div
          style={{ fontSize: "0.75rem" }}
          className="text-center mt-3 initialism fw-semibold text-black-50"
        >
          {process.env.NODE_ENV === "development" && "Development"}
          {process.env.REACT_APP_ENV === "staging" && "Staging"}
        </div>
      )} */}
        </div>
      </Nav>
    )
  }
}
