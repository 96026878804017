import { useEffect, useState } from "react"
import { Form, Button, Card, InputGroup, Image, Spinner } from "react-bootstrap"
import Select from "common/other/Select"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import woodTypes from "common/enums/woodTypes"
import { t, Trans } from "@lingui/macro"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementsStateFamily,
  volumeFormulasState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import MeasurementCustomFields from "custom_fields/MeasurementCustomFields"
import { measurementCustomFields } from "custom_fields/MeasurementCustomField"
import { measurementEditorState } from "./editor/useEditorState"
import {
  densityUnitSelector,
  lengthUnitSelector,
  referenceUnitSelector,
  weightUnitSelector
} from "common/recoil/selectors"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faEdit } from "@fortawesome/free-solid-svg-icons"

const handleWheel = event => {
  event.preventDefault()
}

export default function MeasurementDetails({ measurement: m }) {
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(m.id + "")
  )
  const [coefficient, setCoefficient] = useState(0)
  const getMeasurementCustomFields = useRecoilValue(measurementCustomFields)
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const referenceUnit = useRecoilValue(referenceUnitSelector)
  const weightUnit = useRecoilValue(weightUnitSelector)
  const densityUnit = useRecoilValue(densityUnitSelector)
  const [shipmentNumber, setShipmentNumber] = useState(
    measurement?.shipment_number || ""
  )
  const [measurementType, setMeasurementType] = useState(
    measurement?.measurement_type_id || ""
  )
  const [woodType, setWoodType] = useState(measurement?.wood_type_id || "")
  const [woodQuality, setWoodQuality] = useState(
    measurement?.wood_quality_id || ""
  )
  const [woodAssortment, setWoodAssortment] = useState(
    measurement?.assortment_id || ""
  )
  const [logLength, setLogLength] = useState(measurement?.log_length || "")
  const [volumeFormula, setVolumeFormula] = useState(
    measurement?.volume_formula || ""
  )
  const [weight, setWeight] = useState(measurement?.weight || "")
  const [weightDensity, setWeightDensity] = useState(
    measurement?.weight_density || ""
  )
  const assortments = useRecoilValue(woodAssortmentsState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const woodSpecies = useRecoilValue(woodTypesState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const [photoLoaded, setPhotoLoaded] = useState(false)
  const fetch = useFetch()
  const formatVolume = useVolumeFormatter()
  const [saving, setSaving] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    updateMeasurement()
  }

  const updateMeasurement = () => {
    setSaving(true)
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        shipment_number: shipmentNumber || undefined,
        measurement_type_id: Number(measurementType) ?? undefined,
        wood_type_id: woodType || undefined,
        wood_quality_id: woodQuality || undefined,
        wood_characteristic_id: woodAssortment || undefined,
        log_length: logLength || undefined,
        weight: weight || undefined,
        weight_density: Number(weightDensity) || undefined,
        volume_formula: volumeFormula || undefined,
        custom_fields: getMeasurementCustomFields,
        coefficient: coefficient || undefined
      }
    })
      .then(updatedMeasurement => {
        // console.log(updatedMeasurement)
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement,
            assortment_id: woodAssortment
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  function handleCopyToClipboard(event) {
    navigator.clipboard.writeText(
      window.location.host + "/public_measurement/" + measurement.token
    )
    toast("Copied to clipboard!")
  }

  useEffect(() => {
    setCoefficient(measurement?.coefficient)
  }, [measurement])

  return (
    measurement && (
      <Card className="mb-4">
        <Card.Header>
          <FontAwesomeIcon icon={faEdit} className="me-2" />
          <Trans>Measurement details</Trans>
        </Card.Header>
        <Image
          alt={t`Open measurement editor`}
          title={t`Open measurement editor`}
          fluid
          style={{ display: photoLoaded ? "block" : "none" }}
          src={measurement.photo_optimized}
          onError={e => (e.target.src = "/android-chrome-192x192.png")}
          onClick={() => {
            window.history.pushState("forward", null, "#editor")
            measurementEditor({ id: measurement.id + "", type: "timber" })
          }}
          onLoad={() => setPhotoLoaded(true)}
          className="cursor-pointer  mb-0 border-bottom"
        />
        <p className="m-2 mb-0 d-flex justify-content-evenly">
          <span>
            <span className="text-muted small">
              <Trans>Volume</Trans>:
            </span>{" "}
            {formatVolume(measurement.volume)}
          </span>
          <span>
            <span className="text-muted small">
              <Trans>Log count</Trans>:
            </span>{" "}
            {measurement.logs.length}
          </span>
        </p>
        {!photoLoaded && (
          <p className="text-muted m-3 text-center">
            <Spinner
              size="sm"
              as="span"
              animation="border"
              role="status"
              className="me-2"
              aria-hidden="true"
            />
            <Trans>Loading photo</Trans>
          </p>
        )}
        <Card.Body>
          <Form id="update-measurement" onSubmit={handleSubmit}>
            {measurement?.measurement_area?.length > 0 && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <Trans>Coefficient</Trans>
                </Form.Label>
                <input
                  type="number"
                  min={0}
                  max={1}
                  onFocus={e =>
                    e.currentTarget.addEventListener("wheel", handleWheel, {
                      passive: false
                    })
                  }
                  onBlur={e =>
                    e.target.removeEventListener("wheel", handleWheel)
                  }
                  step={0.0001}
                  className="form-control"
                  disabled={measurement?.logs?.length > 0}
                  value={coefficient}
                  onChange={event => {
                    setCoefficient(event.target.value)
                  }}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Shipment number</Trans>
              </Form.Label>
              <Form.Control
                defaultValue={measurement.shipment_number}
                onChange={event => setShipmentNumber(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Measurement type</Trans>
              </Form.Label>
              <Select
                onChange={event => setMeasurementType(event.value)}
                value={{
                  value: measurementType,
                  label: measurementTypes[measurementType]
                }}
                options={Object.entries(measurementTypes).map(([id, type]) => ({
                  value: id,
                  label: type
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood type</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodType(event.value)}
                value={{ value: woodType, label: woodTypes[woodType] }}
                options={woodSpecies.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood quality</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodQuality(event.value)}
                value={
                  woodQuality && {
                    value: woodQuality,
                    label: woodQualities?.filter($ => $.id === woodQuality)[0]
                      .name
                  }
                }
                options={woodQualities?.map($ => ({
                  value: $.id,
                  label: $.name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood assortment</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodAssortment(event.value)}
                value={
                  woodAssortment && {
                    value: woodAssortment,
                    label: assortments?.filter($ => $.id === woodAssortment)[0]
                      ?.name
                  }
                }
                options={assortments?.map($ => ({
                  value: $.id,
                  label: $.name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Log length</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control-append"
                  onChange={event => setLogLength(event.target.value)}
                  value={`${logLength}`}
                />
                <InputGroup.Text>{lengthUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Pile heights average</Trans>
              </Form.Label>
              <Form.Control readOnly disabled defaultValue={"–"} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Volume formula</Trans>
              </Form.Label>
              <Select
                onChange={event => setVolumeFormula(event.value)}
                value={{
                  value: volumeFormula,
                  label: volumeFormulas
                    ? volumeFormulas[volumeFormula]
                    : "Loading..."
                }}
                options={Object.entries(volumeFormulas || {}).map(
                  ([value, label]) => ({
                    value,
                    label
                  })
                )}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Weight</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  onChange={event => setWeight(event.target.value)}
                  className="form-control-append"
                  value={weight}
                  placeholder={t`Enter number`}
                />
                <InputGroup.Text>{weightUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Weight density</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  onChange={event => setWeightDensity(event.target.value)}
                  className="form-control-append"
                  value={weightDensity}
                />
                <InputGroup.Text>{densityUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Weight per log</Trans>
              </Form.Label>
              <Form.Control readOnly disabled defaultValue={"–"} />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>
                <Trans>Reference size</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control-append"
                  readOnly
                  disabled
                  defaultValue={
                    measurement.references.length &&
                    measurement.references[0].size
                  }
                />
                <InputGroup.Text>{referenceUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <MeasurementCustomFields measurementId={measurement.id} />
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Public URL</Trans>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  readOnly
                  disabled
                  defaultValue={
                    window.location.host +
                    "/public_measurement/" +
                    measurement.token
                  }
                />
                <Button variant="secondary" onClick={handleCopyToClipboard}>
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup>
            </Form.Group>
            <div
              className={`d-grid mt-3 ${measurement.inventory_id && "d-none"}`}
            >
              <Button form="update-measurement" type="submit" disabled={saving}>
                {saving ? (
                  <Spinner
                    size="sm"
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <Trans>Save</Trans>
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    )
  )
}
