import { useState, useEffect, Suspense } from "react"
import { Link } from "react-router-dom"
import {
  faEdit,
  faMapMarkerAlt,
  faTrashAlt,
  faChartArea,
  faWarehouse,
  faChevronDown,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Col, Dropdown, Row } from "react-bootstrap"
import useFetch from "common/hooks/useFetch"
import {
  storageStatuses,
  storageStatusesColors
} from "common/enums/storageStatuses"
import StorageMarker from "timber-measurement/map/StorageMarker"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  roundingPrecisionState,
  showMapState,
  // storagePaginationState,
  truckStorageExpandedState,
  truckStoragesState
} from "common/recoil/atoms"
import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"
import { t } from "@lingui/macro"
import ActiveState from "timber-measurement/ActiveState"
import AddManualMeasurementModal from "timber-measurement/AddManualMeasurementModal"
import storageOptions from "timber-measurement/storageOptions"
import { setStorageMarkers } from "common/recoil/selectors"
import TruckMeasurementRowList from "./TruckMeasurementRowList"
import { RowLoading } from "timber-measurement/MeasurementRowList"

export default function TruckStorageRow({ storage }) {
  const fetch = useFetch()
  const [showActiveState, setShowActiveState] = useState(false)
  const [storages, setStorages] = useRecoilState(truckStoragesState)
  const roundingPrecision = useRecoilValue(roundingPrecisionState)
  const [latitude] = useState(Number(storage.latitude))
  const [longitude] = useState(Number(storage.longitude))
  // const [storagePagination, setStoragePagination] = useRecoilState(storagePaginationState(storage.id))
  const [expanded, setExpanded] = useRecoilState(
    truckStorageExpandedState(storage.id)
  )
  const setShowMap = useSetRecoilState(showMapState)
  let storageVolume = 0
  if (storage.volume?.unit) {
    storageVolume = Number(storage.volume.scalar)
  } else {
    storageVolume = Number(storage.volume)
  }
  const storageEmpty = Number(storage.logs_count) === 0 && storageVolume === 0
  const setMarkers = useSetRecoilState(setStorageMarkers)

  useEffect(() => {
    if (expanded && showActiveState) {
      setShowActiveState(false)
    }
    // eslint-disable-next-line
  }, [expanded])

  const toggleExpand = () => setExpanded(!expanded)

  return (
    <>
      <tr id={storage.id} className="bg-white">
        <td className="ps-3">
          <div onClick={!storageEmpty ? toggleExpand : null}>
            {storageEmpty && (
              <FontAwesomeIcon
                icon={faWarehouse}
                className="text-secondary opacity-50"
              />
            )}
            {!storageEmpty && (
              <FontAwesomeIcon
                size="lg"
                icon={expanded ? faChevronDown : faChevronRight}
                className="text-primary"
              />
            )}
          </div>
        </td>
        <td
          title={storage.name}
          className="fw-semibold"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
        >
          <div
            className={`d-flex align-items-center ${
              !storageEmpty && "hover-dark cursor-pointer"
            }`}
            onClick={!storageEmpty ? toggleExpand : null}
          >
            <div
              className="text-primary"
              style={{
                maxWidth: "18ch",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {storage.name}
            </div>
          </div>
        </td>
        <td>
          {latitude && longitude ? (
            <div className="lh-1">
              {/* <FontAwesomeIcon
                size="lg"
                icon={faMapMarkerAlt}
                className="me-2 text-primary hover-dark cursor-pointer"
                onClick={() => {
                  setShowMap(true)
                  setMarkers(storages?.map((s, i) => <StorageMarker key={i} storage={s} open={storage.id === s.id} />))
                  setTimeout(() => window.document.getElementById("map")?.scrollIntoView(), 100)
                }}
              ></FontAwesomeIcon>
              <span className="me-2" style={{ fontSize: "14px"}}>
                {latitude.toFixed(5)}, {longitude.toFixed(5)}
              </span> */}
              <Row xs="auto" className="flex-nowrap">
                <Col xs="auto" className="align-self-center">
                  <FontAwesomeIcon
                    size="xl"
                    icon={faMapMarkerAlt}
                    className="text-primary hover-dark cursor-pointer"
                    onClick={() => {
                      setShowMap(true)
                      setMarkers(
                        storages?.map((s, i) => (
                          <StorageMarker
                            key={i}
                            storage={s}
                            open={storage.id === s.id}
                          />
                        ))
                      )
                      setTimeout(
                        () =>
                          window.document
                            .getElementById("map")
                            ?.scrollIntoView(),
                        100
                      )
                    }}
                  ></FontAwesomeIcon>
                </Col>
                <Col className={`${!expanded && "text-body"}`}>
                  <Row className="small">{latitude.toFixed(5)}</Row>
                  <Row className="small">{longitude.toFixed(5)}</Row>
                </Col>
              </Row>
            </div>
          ) : (
            <span className="ms-2">–</span>
          )}
        </td>
        <td className={`${!expanded && "text-body"}`}>
          {new Intl.DateTimeFormat("en-GB", { dateStyle: "medium" }).format(
            new Date(storage.updated_at)
          )}
        </td>
        <td>
          {/* <FontAwesomeIcon
            icon={faCircle}
            className="me-2"
            style={{
              width: "12px",
              color: storageStatusesColors[storage.storage_state_id]
            }}
          />
          {storageStatuses[storage.storage_state_id] || "ENUM_ERROR"} */}
          <Badge
            bg={storageStatusesColors[storage.storage_state_id]}
            text={
              storageStatusesColors[storage.storage_state_id] === "none" &&
              "dark"
            }
          >
            {storageStatuses[storage.storage_state_id] || "ENUM_ERROR"}
          </Badge>
        </td>
        <td className={`${!expanded && "text-body"}`}>
          {storage.logs_count || "–"}
        </td>
        <td className={`${!expanded && "text-body"}`}>
          {storageVolume ? storageVolume.toFixed(roundingPrecision) : 0} m³
        </td>
        <td>
          <div style={{ display: "flex" }} className="justify-content-end">
            <FontAwesomeIcon
              onClick={() => {
                setShowActiveState(!showActiveState)
                if (!showActiveState) {
                  setExpanded(false)
                }
              }}
              icon={faChartArea}
              size="lg"
              className={`text-primary cursor-pointer me-2  ${
                showActiveState ? "p-1 bg-primary text-light rounded" : "p-1"
              }`}
              style={{
                visibility:
                  Number(storage.logs_count) === 0 &&
                  storageVolume === 0 &&
                  "hidden"
              }}
              disabled
            ></FontAwesomeIcon>
            <Dropdown>
              <Dropdown.Toggle as={storageOptions} />
              <Dropdown.Menu rootCloseEvent="click">
                {/* <Link to={`/storage/${storage.id}`} className="dropdown-item">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faShareAlt}
                    className="me-2"
                  />
                  Share
                </Link> */}
                <Suspense fallback>
                  <AddManualMeasurementModal storage={storage} />
                </Suspense>
                <Link
                  className="dropdown-item"
                  to={{
                    pathname: `/storage/${storage.id}`
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="me-2 opacity-50"
                    style={{ width: "1rem" }}
                  />
                  <Trans>Edit</Trans>
                </Link>
                <Dropdown.Item onClick={deleteStorage}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="me-2 opacity-50"
                    style={{ width: "1rem" }}
                  />
                  <Trans>Remove</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </td>
      </tr>
      <Suspense fallback>
        {showActiveState && <ActiveState id={storage.id} />}
      </Suspense>
      <Suspense fallback={<RowLoading />}>
        {expanded && <TruckMeasurementRowList storage={storage} />}
      </Suspense>
    </>
  )

  function deleteStorage() {
    if (window.confirm(t`Are you sure?`)) {
      fetch(`/storages/${storage.id}`, {
        method: "DELETE"
      })
        .then(() => {
          setStorages(storages.filter($ => $.id !== storage.id))
          toast.success(<Trans>Storage deleted</Trans>)
        })
        .catch(() => {
          toast.error(<Trans>Failed to delete storage</Trans>)
        })
    }
  }
}
