import { useState } from "react"
import { Form, Button, Card } from "react-bootstrap"
import Select from "common/other/Select"
import useFetch from "common/hooks/useFetch"
import LocationSelector from "dashboard/LocationSelector"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { measurementsStateFamily } from "common/recoil/atoms"
import { t, Trans } from "@lingui/macro"
import { toast } from "react-toastify"
import Spinner from "common/other/Spinner"
import { storagesListQuery } from "common/recoil/selectors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

const MeasurementLocation = ({ measurement }) => {
  const fetch = useFetch()
  const [storageId, setStorageId] = useState(measurement.storage_id)
  const [latitude, setLatitude] = useState(measurement.latitude || 0)
  const [longitude, setLongitude] = useState(measurement.longitude || 0)
  const storages = useRecoilValue(storagesListQuery)
  const setMeasurement = useSetRecoilState(
    measurementsStateFamily(measurement.id)
  )
  const [saving, setSaving] = useState(false)

  const options = storages?.length
    ? storages.map(storage => ({
        value: storage.id,
        label: storage.name
      }))
    : []

  function setPosition({ lat, lng }) {
    setTimeout(() => setLatitude(lat.toFixed(5)))
    setTimeout(() => setLongitude(lng.toFixed(5)))
  }

  async function updateMeasurement(event) {
    event.preventDefault()
    setSaving(true)
    await fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        storage_id: storageId,
        latitude: latitude,
        longitude: longitude
      }
    })
      .then(response => {
        if (response) {
          setMeasurement({
            ...measurement,

            latitude,
            longitude
          })
        }
        toast.success(<Trans>Saved changes</Trans>)
      })
      .catch(() =>
        toast.error(<Trans>Failed to update measurement location</Trans>)
      )
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
        <Trans>Measurement location</Trans>
      </Card.Header>
      <LocationSelector
        position={[latitude, longitude]}
        setPosition={setPosition}
        tooltip={t`Measurement coordinates`}
      />
      <Card.Body className="pt-2 border-top">
        <Form onSubmit={updateMeasurement} id="updateMeasurement">
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Latitude</Trans>
            </Form.Label>
            <Form.Control
              placeholder={t`Drag marker to update`}
              onChange={event =>
                setLatitude(Number(event.target.value) ? event.target.value : 0)
              }
              value={latitude ?? 0}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Longitude</Trans>
            </Form.Label>
            <Form.Control
              placeholder={t`Drag marker to update`}
              onChange={event =>
                setLongitude(
                  Number(event.target.value) ? event.target.value : 0
                )
              }
              value={longitude ?? 0}
            />
          </Form.Group>
          <Form.Group controlId="formStorage">
            <Form.Label>
              <Trans>Storage</Trans>
            </Form.Label>
            <Select
              value={
                options.filter($ => Number($.value) === Number(storageId))[0]
              }
              onChange={event => setStorageId(event.value)}
              options={options}
            />
          </Form.Group>
          <div
            className={`d-grid mt-4 ${measurement.inventory_id && "d-none"}`}
          >
            <Button
              form="updateMeasurement"
              type="submit"
              disabled={
                (measurement.latitude === latitude &&
                  measurement.longitude === longitude) ||
                (!latitude && !longitude) ||
                saving
              }
            >
              {saving ? <Spinner /> : <Trans>Save</Trans>}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default MeasurementLocation
