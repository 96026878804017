import { Row, Col, Button } from "react-bootstrap"
import MeasurementTimber from "./MeasurementTimber"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import LogisticsMap from "./logistics/LogisticsMap.js"
import { truckMeasurementsStateFamily } from "common/recoil/atoms"
import MeasurementLocation from "timber-measurement/MeasurementLocation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "@lingui/macro"
import useExportsState from "exports/useExportsState"
import { useEffect } from "react"
import useFetch from "common/hooks/useFetch"

export default function TruckMeasurementView() {
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    truckMeasurementsStateFamily(Number(measurementId))
  )
  const fetch = useFetch()
  const navigate = useNavigate()
  const { createTruckMeasurementPdfReport } = useExportsState()

  useEffect(() => {
    if (!measurement) {
      fetch("/truck_measurements/" + measurementId).then(response => {
        setMeasurement(response)
      })
    }
    // eslint-disable-next-line
  }, [])

  return measurement ? (
    <>
      <span
        className="fw-medium text-white-50 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
        <Trans>Back to dashboard</Trans>
      </span>
      <hr className="text-light" />
      <Row>
        <Col xs="12" className="mb-3">
          <h5 className="text-light mb-0">
            {measurement.photo_file_name}
            <Button
              onClick={() => createTruckMeasurementPdfReport(measurement.id)}
              size="sm"
              variant="light"
              className="ms-3"
            >
              <Trans>Export to PDF</Trans>
            </Button>
          </h5>
          <div className="text-white-50">
            <b className="me-1">ID:</b>
            <span>{measurement.id}</span>
          </div>
        </Col>
        <Col lg={6} xxl={4}>
          <Row>
            <Col id="location">
              <MeasurementLocation measurement={measurement} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <LogisticsMap measurement={measurement} />
            </Col>
          </Row>
        </Col>
        <Col lg={6} xxl={4} className="mt-4 mt-md-0">
          <Row>
            <Col>
              <MeasurementTimber measurement={measurement} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : null
}
