import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Form, Button, Card, Container, Row, Col, Image } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { t } from "@lingui/macro"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import useFetch from "common/hooks/useFetch"
import {
  INVALID_PASSWORD_RESET_TOKEN,
  PASSWORD_ALREADY_USED,
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS
} from "./LoginForm"

export default function ResetForm() {
  const navigate = useNavigate()
  const { token: tokenFromURL } = useParams()
  const [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [token, setToken] = useState(tokenFromURL)
  const customFetch = useFetch()
  const updatePassword = event => {
    event.preventDefault()
    customFetch("/logins/reset_password", {
      method: "POST",
      body: {
        token,
        new_password: newPassword,
        password_confirmation: confirmNewPassword
      }
    })
      .then(response => {
        if (response.error === INVALID_PASSWORD_RESET_TOKEN) {
          return toast.warn(<Trans>This password reset link is invalid</Trans>)
        }

        if (
          response.error === PASSWORD_ALREADY_USED ||
          response.error === PASSWORD_DOES_NOT_MEET_REQUIREMENTS
        ) {
          return toast.error(
            <Trans>
              New password must not match current password. New password must
              not contain any of the previous passwords
            </Trans>
          )
        }

        if (response) {
          setToken("")
          toast.success(
            <Trans>
              Password updated! Please log in with your new password
            </Trans>
          )
          navigate("/login")
        } else {
          toast.error(
            <Trans>Something went wrong. Please contact our support</Trans>
          )
        }
      })
      .finally(() => {
        setNewPassword("")
        setConfirmNewPassword("")
      })
  }

  const passwordAtleast12Characters = newPassword?.length >= 12
  const passwordContains1Uppercase = /[A-Z]/.test(newPassword)
  const passwordContains1Lowercase = /[a-z]/.test(newPassword)
  const passwordContains1Number = /[0-9]/.test(newPassword)
  const passwordContains1Symbol = /[`!@#€$%^&*() _+\-={};':"|,.<>?~]/.test(
    newPassword
  )

  return (
    <Container className="position-absolute top-50 start-50 translate-middle">
      <title>
        <Trans>Login</Trans> | Timbeter
      </title>
      <Row className="justify-content-center">
        <Col xs="auto">
          <div className="text-center mb-4 mt-2">
            <Image
              style={{ width: "16rem" }}
              fluid
              src="/logos/timbeter.svg"
              alt="Timbeter logo"
            />
          </div>
          <div>
            <Card style={{ maxWidth: "32rem", width: "auto" }}>
              <Card.Body>
                <div>
                  <Card.Title>
                    <Trans>Reset your password</Trans>
                  </Card.Title>
                  <Card.Text className="mb-3">
                    <Trans>Update your password below to continue.</Trans>
                  </Card.Text>
                  <Form onSubmit={updatePassword}>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        <Trans>New password</Trans>
                      </Form.Label>
                      <Form.Control
                        autoComplete="new"
                        type={showPassword ? "text" : "password"}
                        spellCheck={false}
                        autoCorrect="false"
                        required
                        aria-required
                        autoCapitalize="false"
                        value={newPassword}
                        onChange={event => setNewPassword(event.target.value)}
                      />
                      <Button
                        title={t`Show password`}
                        onClick={() => setShowPassword(!showPassword)}
                        variant="none"
                        tabIndex={-1}
                        style={{
                          position: "absolute",
                          right: 0,
                          marginTop: "-30px",
                          marginRight: "28px"
                        }}
                        className="p-0 border-0"
                      >
                        {newPassword &&
                          (showPassword ? (
                            <FontAwesomeIcon
                              className="opacity-25"
                              icon={faEyeSlash}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="opacity-25"
                              icon={faEye}
                            />
                          ))}
                      </Button>
                      <Form.Text
                        as="p"
                        className="text-black-50 opacity-75 mt-1 lh-1"
                      >
                        <span
                          className={`${
                            passwordAtleast12Characters
                              ? "text-success"
                              : "text-danger"
                          } me-2 text-nowrap`}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            className="me-1"
                          />
                          <Trans>Minimum length 12</Trans>
                        </span>
                        <span
                          className={`${
                            passwordContains1Uppercase
                              ? "text-success"
                              : "text-danger"
                          } me-2 text-nowrap`}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            className="me-1"
                          />
                          <Trans>Contains 1 uppercase letter</Trans>
                        </span>
                        <span
                          className={`${
                            passwordContains1Lowercase
                              ? "text-success"
                              : "text-danger"
                          } me-2 text-nowrap`}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            className="me-1"
                          />
                          <Trans>Contains 1 lowercase letter</Trans>
                        </span>
                        <span
                          className={`${
                            passwordContains1Number
                              ? "text-success"
                              : "text-danger"
                          } me-2 text-nowrap`}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            className="me-1"
                          />
                          <Trans>Contains 1 number</Trans>
                        </span>
                        <span
                          className={`${
                            passwordContains1Symbol
                              ? "text-success"
                              : "text-danger"
                          } me-2 text-nowrap`}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            size="xs"
                            className="me-1"
                          />
                          <Trans>Contains 1 symbol</Trans>
                        </span>
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans>Confirm new password</Trans>
                      </Form.Label>
                      <Form.Control
                        aria-required
                        required
                        autoComplete="new"
                        type={showPassword ? "text" : "password"}
                        spellCheck={false}
                        autoCorrect="false"
                        autoCapitalize="false"
                        value={confirmNewPassword}
                        onChange={event =>
                          setConfirmNewPassword(event.target.value)
                        }
                      />
                      <Button
                        title={t`Show password`}
                        onClick={() => setShowPassword(!showPassword)}
                        variant="none"
                        style={{
                          position: "absolute",
                          right: 0,
                          marginTop: "-30px",
                          marginRight: "28px"
                        }}
                        className="p-0 border-0"
                      >
                        {confirmNewPassword &&
                          (showPassword ? (
                            <FontAwesomeIcon
                              className="opacity-25"
                              icon={faEyeSlash}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="opacity-25"
                              icon={faEye}
                            />
                          ))}
                      </Button>
                    </Form.Group>
                    <Button
                      type="submit"
                      className="w-full"
                      disabled={
                        !(
                          passwordContains1Uppercase &&
                          passwordContains1Lowercase &&
                          passwordAtleast12Characters &&
                          passwordContains1Number &&
                          passwordContains1Symbol
                        ) || newPassword !== confirmNewPassword
                      }
                    >
                      <Trans>Update</Trans>
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
            <div className="small d-flex justify-content-between  mt-3">
              <Link
                className="text-light opacity-50 text-decoration-none fw-medium"
                to="/login"
              >
                <Trans>Back to login page</Trans>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
