import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { organizationsState, reportsState } from "common/recoil/atoms"
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function ReportsTable() {
  const reports = useRecoilValue(reportsState)

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Reports</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...reports].map(report => (
          <ReportRow key={report.id} report={report} />
        ))}
      </tbody>
    </Table>
  )
}

function ReportRow({ report }) {
  const organizations = useRecoilValue(organizationsState)
  const recipientOrganization = organizations.find(
    $ => $.id === report.recipient_organization_id
  )?.name
  const navigate = useNavigate()

  return (
    <tr onClick={() => navigate("/reports/" + report.id)}>
      <td>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={`${
            report.confirmed ? "text-primary" : "text-secondary"
          } me-2`}
        />
        {report.name || "–"}
        {recipientOrganization ? (
          <>
            <br />
            <small className="text-muted">{recipientOrganization}</small>
          </>
        ) : null}
      </td>
    </tr>
  )
}
