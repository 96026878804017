import { faEllipsisH } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import measurementTypes from "common/enums/measurementTypes"
import useFetch from "common/hooks/useFetch"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { formatTime } from "common/utils/formatDateTime"
import { useEffect } from "react"
import { Dropdown, Image } from "react-bootstrap"
import { toast } from "react-toastify"
import {
  atomFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { lumberProductsState } from "timber/LumberProducts"

export const lumberMeasurementsStateFamily = atomFamily({
  key: "lumberMeasurement",
  default: null
})

export default function LumberMeasurementRow({ measurement: m }) {
  const [measurement, setMeasurement] = useRecoilState(
    lumberMeasurementsStateFamily(m.id)
  )
  useEffect(() => {
    if (!measurement) {
      setMeasurement(m)
    }
    // eslint-disable-next-line
  }, [])
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const lumberProducts = useRecoilValue(lumberProductsState)
  const formatVolume = useVolumeFormatter()
  const fetch = useFetch()
  const deleteMeasurement = () => {
    fetch("/lumber_measurements/", {
      method: "DELETE",
      body: {
        measurement_ids: [measurement.id]
      }
    }).then(response => {
      if (response) {
        toast.success(<Trans>Measurement deleted</Trans>)
        window.location.reload()
      }
    })
  }

  return (
    measurement && (
      <tr style={{ background: "white" }}>
        <td>
          <div className="d-flex align-items-center">
            <Image
              thumbnail
              src={measurement.photo_thumb}
              onError={e => (e.target.src = "/android-chrome-192x192.png")}
              onClick={() => {
                window.history.pushState("forward", null, "#editor")
                measurementEditor({
                  id: measurement.id,
                  type: "lumber",
                  storage: measurement.storage_id
                })
              }}
              className="me-2 hover-dark"
              style={{
                width: "64px",
                height: "64px",
                cursor: "pointer"
              }}
            />
            <p style={{ whiteSpace: "break-spaces" }}>
              {/* <Link
              className="text-primary link"
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/lumber_measurement/" + measurement.id
              }}
            > */}
              {measurement.photo_file_name
                ? measurement.photo_file_name.split(".")[0]
                : "NO_PHOTO_ERROR"}
              {/* </Link> */}
              <br />
              <small style={{ color: "black" }}>
                <strong>{measurement.id}</strong> /{" "}
                {measurement.created_at.substring(0, 10)}{" "}
                {formatTime(measurement.created_at)}
              </small>
            </p>
          </div>
        </td>
        <td>
          {measurementTypes[measurement.measurement_type_id] || "ENUM_ERROR"}
        </td>
        <td>
          {measurement.lumber_product_id
            ? lumberProducts.find($ => $.id === measurement.lumber_product_id)
                .name
            : "–"}
        </td>
        <td>
          {measurement.plank_length ? measurement.plank_length + " m" : "–"}
        </td>
        <td>{measurement.plank_count || "–"}</td>
        <td>{measurement.volume ? formatVolume(measurement.volume) : "–"}</td>
        <td>
          <Dropdown align="end">
            <Dropdown.Toggle variant="none" className="p-0 border-0">
              <FontAwesomeIcon icon={faEllipsisH} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  if (window.confirm(t`Are you sure?`)) {
                    deleteMeasurement()
                  }
                }}
              >
                <Trans>Delete</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  )
}
