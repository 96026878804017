import useFetch from "common/hooks/useFetch"

export default function useAuthActions() {
  const fetch = useFetch()
  const logOut = ({ sessionExpired }) => {
    fetch("/logout", {
      method: "POST"
    }).then(() => {
      localStorage.removeItem("access_token")
      localStorage.removeItem("access_token_expires_at")
      window.location.assign("/login")
    })
  }

  return {
    logOut
  }
}
