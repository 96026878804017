import {
  Button,
  Card,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { deviceState } from "devices/EditDeviceCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faTablet } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "@lingui/macro"
import { useNavigate } from "react-router-dom"

export default function MeasurementDevice({ deviceId }) {
  const device = useRecoilValue(deviceState(deviceId))
  const navigate = useNavigate()

  return device ? (
    <Card className="mt-4">
      <Card.Header>
        <FontAwesomeIcon icon={faTablet} className="me-2" />
        <Trans>Measurement device</Trans>
      </Card.Header>
      <Card.Body>
        <FormGroup className="mb-3">
          <FormLabel>
            <Trans>Name</Trans>
          </FormLabel>
          <FormControl defaultValue={device.name || "–"} disabled readOnly />
          <FormLabel>
            <Trans>Email</Trans>
          </FormLabel>
          <FormControl
            defaultValue={device.user_email || "–"}
            disabled
            readOnly
          />
          <FormLabel>
            <Trans>Hardware ID</Trans>
          </FormLabel>
          <FormControl defaultValue={device.hw_id} disabled readOnly />
        </FormGroup>
        <Button
          variant="secondary"
          onClick={() => navigate("/devices/" + deviceId)}
        >
          <FontAwesomeIcon icon={faCog} className="me-2 opacity-50" />
          <Trans>Device settings</Trans>
        </Button>
      </Card.Body>
    </Card>
  ) : null
}
