import { Trans } from "@lingui/macro"
import {
  reportsState,
  volumeFormulasState,
  volumeUnitsState
} from "common/recoil/atoms"
import { capitalize } from "lodash"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilValue, useSetRecoilState } from "recoil"
import ReactDatePicker from "react-datepicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"

export default function NewReportCard() {
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const volumeUnits = useRecoilValue(volumeUnitsState)
  const [name, setName] = useState("")
  const setReports = useSetRecoilState(reportsState)
  const [volumeFormula, setVolumeFormula] = useState("cylinder")
  const [volumeUnit, setVolumeUnit] = useState("m^3")
  const [reportedAt, setReportedAt] = useState("")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const fetch = useFetch()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New report</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="new-report" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Volume formula</Trans>
            </Form.Label>
            <Form.Select
              value={volumeFormula}
              onChange={event => setVolumeFormula(event.target.value)}
              required
            >
              {Object.entries(volumeFormulas).map(([value, label]) => (
                <option key={value} value={value}>
                  {capitalize(label)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Volume unit</Trans>
            </Form.Label>
            <Form.Select
              value={volumeUnit}
              onChange={event => setVolumeUnit(event.target.value)}
              required
            >
              {volumeUnits.map($ => (
                <option key={$} value={$}>
                  {formatVolumeUnit($)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Reported at</Trans>
            </Form.Label>
            <ReactDatePicker
              dateFormat="dd.MM.yyyy"
              className="form-control"
              selected={reportedAt}
              onChange={date => setReportedAt(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/reports")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button type="submit" form="new-report" disabled={loading}>
          <Trans>Add report</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    fetch("/reports", {
      method: "POST",
      body: {
        name,
        volume_formula: volumeFormula,
        volume_unit: volumeUnit,
        reported_at: reportedAt
      }
    })
      .then(response => {
        if (!response.error) {
          setReports($ => [...$, response])
          toast.success(<Trans>Added</Trans>)
          navigate("/reports")
        }
      })
      .finally(() => setLoading(false))
  }
}
