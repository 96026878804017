import { faArrowDown, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Col, Modal } from "react-bootstrap"
import { selector, useRecoilValue } from "recoil"
import { subscriptionItemsState } from "."
import { organizationPlanDate } from "./AdditionalFeatures"

const timbeterEnterpriseSelector = selector({
  key: "timbeterEnterprise",
  get: ({ get }) =>
    get(subscriptionItemsState).find(
      item => item.package_id === "enterprise_subscription"
    )
})

export default function TimbeterEnterprise() {
  const planExists = useRecoilValue(timbeterEnterpriseSelector)
  const [showModal, setShowModal] = useState(false)
  const billingDate = useRecoilValue(organizationPlanDate)

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Timbeter Enterprise</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled text-start small bg-light px-4 py-3 rounded">
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Unlimited measurements
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Diameter, contour, density & truck measurements
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Measurement sharing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Measurement editing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Panorama
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Diameter profiles
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Loading tool
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              QR code detection
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Manual measurement
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Custom fields
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Trainings
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Enterprise storage module
            </li>
          </ul>
          <p className="text-black-50 my-4 fw-medium text-center">
            <Trans>To get a price estimate contact our Sales team below</Trans>{" "}
            <FontAwesomeIcon icon={faArrowDown} size="xs" />
          </p>
          <Button
            className="w-full rounded-pill"
            onClick={() => {
              window.Intercom(
                "showNewMessage",
                t`Hi! I'm interested in Timbeter Enterprise.`
              )
            }}
          >
            <Trans>Contact us</Trans>
          </Button>
        </Modal.Body>
      </Modal>

      <Col xs="12" sm="6" xl="4">
        <Card className="rounded border-0 h-100">
          <div className="bg-primary text-light rounded-top fw-semibold bg-gradient">
            {planExists &&
              (planExists.cancelled ? (
                <>
                  <Trans>Active until</Trans>{" "}
                  {new Date(billingDate).toLocaleDateString()}
                </>
              ) : (
                <>
                  <Trans>Active</Trans> · {planExists.quantity}{" "}
                  <Trans>licenses</Trans>
                </>
              ))}
          </div>
          {/* <div className="bg-warning rounded-top small fw-semibold bg-gradient">
        Subscription
      </div> */}
          <Card.Body className="d-flex flex-column justify-content-between">
            <p className="fw-semibold py-2">
              <img
                src="/logos/timber.svg"
                width="28"
                className="rounded me-2"
                alt="Timbeter"
              />
              <Trans>Timbeter Enterprise</Trans>
            </p>
            <p className="text-muted fs-6 p-4 ">
              <Trans>
                For large forestry organizations seeking enterprise-grade
                digital timber measurements.
              </Trans>
            </p>
            {planExists ? (
              <Button variant="secondary" className="w-full" disabled>
                <Trans>Managed by Timbeter</Trans>
              </Button>
            ) : (
              <Button
                onClick={() => setShowModal(true)}
                className="w-100 link-dark"
                variant="link"
              >
                <Trans>Learn more</Trans>
              </Button>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}
