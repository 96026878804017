import { Button, Col, InputGroup, FormControl, Spinner } from "react-bootstrap"
import { t, Trans } from "@lingui/macro"
import {
  faEdit,
  faPlusCircle,
  faTrash,
  faHandPointer,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  REFERENCE,
  LOGS,
  LOAD_LINES,
  MEASUREMENT_AREA,
  PLANKS,
  EDIT
} from "common/enums/constants"
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import {
  featureEnabled,
  measurementAreaState,
  measurementEditorLoadsState,
  woodAssortmentsState,
  woodCullsState
} from "common/recoil/atoms"
import {
  measurementByIdAndTypeQuery,
  truckStoragesQuery
} from "common/recoil/selectors"
import { toast } from "react-toastify"
import useEditorState, {
  logsState,
  measurementEditorState,
  selectedLogState
} from "./useEditorState"
import { editorSavingChangesState, editorSettingsState } from "."
import ReferencePane from "./ReferencePane"
import {
  selectedLogAssortmentState,
  selectedLogCullState,
  selectedLogQrCodeState
} from "./Log"
import { useEffect } from "react"
import useFetch from "common/hooks/useFetch"
import useShapesActions from "./useShapesActions"
import { selectedPlankState } from "./Plank"
import Select from "common/other/Select"

export default function EditMode() {
  const culls = useRecoilValue(woodCullsState)
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const edit = useRecoilValue(editorSettingsState("edit"))
  const setLogs = useSetRecoilState(logsState)
  const loads = useRecoilValue(measurementEditorLoadsState)
  const { updateLogsAndReferences, updatePlanks } = useEditorState()
  const measurementArea = useRecoilValue(measurementAreaState)
  const selectedLog = useRecoilValue(selectedLogState)
  const refreshTruckStorages = useRecoilRefresher_UNSTABLE(truckStoragesQuery)
  const selectedPlank = useRecoilValue(selectedPlankState)
  const { id: measurementId, type: measurementType } = useRecoilValue(
    measurementEditorState
  )
  const [measurement, setMeasurement] = useRecoilState(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const resetSelectedLog = useResetRecoilState(selectedLogState)
  const [qrCode, setQrCode] = useRecoilState(selectedLogQrCodeState)
  const [assortment, setAssortment] = useRecoilState(selectedLogAssortmentState)
  const [cull, setCull] = useRecoilState(selectedLogCullState)
  const locked = measurement?.locked_at
  const mode = useRecoilValue(editorSettingsState("mode"))
  const fetch = useFetch()
  const { getSelectedLog, deleteLog, deletePlank } = useShapesActions()
  const editorSavingChanges = useRecoilValue(editorSavingChangesState)
  const qrEnabled = useRecoilValue(featureEnabled("qr_code"))

  const updateMeasurementArea = measurementArea => {
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        measurement_area: measurementArea
      }
    }).then(response => {
      let updatedAttributes = { ...measurement }
      updatedAttributes.volume = response.volume
      updatedAttributes.measurement_area = measurementArea
      updatedAttributes.coefficient = response.coefficient

      setMeasurement({
        ...measurement,
        ...updatedAttributes
      })

      toast.success(<Trans>Your changes have been saved</Trans>)
    })
  }

  useEffect(() => {
    function handleDelete(event) {
      if (
        event.key === "Delete" &&
        mode === "EDIT" &&
        getSelectedLog() !== null
      ) {
        setLogs(logs =>
          logs.map((log, index) => {
            if (index !== getSelectedLog()) return log
            return {
              ...log,
              isDeleted: true
            }
          })
        )
        resetSelectedLog()
      }
    }

    window.document.addEventListener("keydown", handleDelete)

    return () => {
      window.document.removeEventListener("keydown", handleDelete)
    }
  }, [getSelectedLog, mode, resetSelectedLog, setLogs])

  return mode === EDIT ? (
    <>
      {edit === MEASUREMENT_AREA && (
        <Col className="px-3 py-2">
          <span className="text-white-50 small me-3">
            <FontAwesomeIcon icon={faHandPointer} className="me-2" />
            <Trans>Drag nodes to change the measurement area</Trans>
          </span>
          <span className="text-white-50 small me-3">
            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
            <Trans>Double-click node to remove node</Trans>
          </span>
          <span className="text-white-50 small me-3">
            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
            <Trans>Double-click near contour to add node</Trans>
          </span>
          <Button onClick={() => updateMeasurementArea(measurementArea)}>
            <Trans>Save</Trans>
          </Button>
        </Col>
      )}
      {edit === PLANKS && (
        <div className="d-flex py-2 px-3 flex-col">
          <Col xs="auto text-white-50">
            <div className="small">
              <FontAwesomeIcon size="sm" icon={faPlusCircle} className="me-2" />
              <Trans>Double–click to add a plank</Trans>
            </div>
            <div className="small">
              <FontAwesomeIcon size="sm" icon={faEdit} className="me-2" />
              <Trans>Click on a plank to edit its size or location</Trans>
            </div>
            <div className="small">
              <FontAwesomeIcon size="sm" icon={faTrash} className="me-2" />
              <Trans>
                Select a plank and click "Remove plank" to remove it
              </Trans>
            </div>
          </Col>

          <Col className="align-self-center px-3">
            {selectedPlank !== null ? (
              <Button
                variant="outline-danger"
                className="mx-4 rounded-pill px-3"
                onClick={deletePlank}
              >
                <Trans>Remove plank</Trans>
              </Button>
            ) : (
              <span className="mx-4 opacity-50">
                <Trans>No plank selected</Trans>
              </span>
            )}
            <Button
              disabled={editorSavingChanges}
              onClick={updatePlanks}
              className="rounded-pill px-3"
            >
              {editorSavingChanges ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          </Col>
        </div>
      )}
      {mode === "EDIT" && edit === null && (
        <Col className="px-3 py-2">
          <span className="text-white-50 small me-3">
            <Trans>Select something to edit.</Trans>
          </span>
        </Col>
      )}
      {edit === REFERENCE && <ReferencePane />}
      {edit === LOAD_LINES && (
        <div className="d-flex p-3">
          <Button
            className="px-4 rounded-pill bg-gradient"
            onClick={updateLoadLines}
          >
            <Trans>Save changes</Trans>
          </Button>
        </div>
      )}
      <>
        {edit === LOGS && (
          <div
            style={{ color: "rgba(255, 255, 255, 50%)" }}
            className="d-flex py-2 px-3"
          >
            <Col xs="auto" className="me-3">
              <div className="small">
                <FontAwesomeIcon
                  size="sm"
                  icon={faPlusCircle}
                  className="me-2"
                />
                <Trans>Double–click to add a circle</Trans>
              </div>
              <div className="small">
                <FontAwesomeIcon size="sm" icon={faEdit} className="me-2" />
                <Trans>Click on a circle to edit its diameter</Trans>
              </div>
              <div className="small">
                <FontAwesomeIcon size="sm" icon={faTrash} className="me-2" />
                <Trans>Select a circle to remove it</Trans>
              </div>
            </Col>
            {measurementType === "container" && qrEnabled && (
              <>
                <Col xs="auto" className="me-3">
                  {/* <div className="d-flex align-items-center">
                    <div
                      className="d-inline-block me-2"
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 50,
                        border: "1px solid lime"
                      }}
                    />
                    <span className="small">
                      <Trans>Log with no QR code</Trans>
                    </span>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div
                      className="d-inline-block me-2"
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 50,
                        border: "1px solid dodgerblue"
                      }}
                    />
                    <span className="small">
                      <Trans>Log with QR code</Trans>
                    </span>
                  </div>
                </Col>
                <Col xs="auto" className="me-3">
                  <div>
                    <InputGroup size="sm">
                      <InputGroup.Text className="text-muted">
                        QR code
                      </InputGroup.Text>
                      <FormControl
                        value={qrCode}
                        onChange={event => setQrCode(event.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <Button
                    variant="warning"
                    className="mt-2"
                    onClick={() => {
                      if (
                        window.confirm(
                          t`This will remove all logs without a QR code`
                        )
                      ) {
                        setLogs(logs =>
                          logs.map(log => {
                            if (
                              log?.qr_code !== undefined &&
                              log?.qr_code !== null &&
                              log?.qr_code !== ""
                            )
                              return log
                            return {
                              ...log,
                              isDeleted: true
                            }
                          })
                        )
                      }
                    }}
                  >
                    <Trans>Remove logs without QR code</Trans>
                  </Button>
                </Col>
              </>
            )}
            <Col className="align-self-center">
              {selectedLog !== null ? (
                <Button
                  variant="outline-danger"
                  onClick={deleteLog}
                  className="me-4 px-3 rounded-pill"
                >
                  <Trans>Remove log</Trans>
                </Button>
              ) : (
                <span className="me-4 opacity-50">
                  <Trans>No log selected</Trans>
                </span>
              )}
              {selectedLog !== null && (
                <>
                  <div
                    className="d-inline-block me-3"
                    style={{ width: "10rem" }}
                  >
                    <Select
                      placeholder={<Trans>No assortment</Trans>}
                      value={
                        assortment
                          ? {
                              value: assortment,
                              label: woodAssortments.find(
                                $ => $.id === assortment
                              )?.name
                            }
                          : null
                      }
                      onChange={event => setAssortment(event.value)}
                      options={woodAssortments?.map($ => ({
                        value: $.id,
                        label: $.name
                      }))}
                    />
                  </div>
                  <div
                    className="d-inline-block me-3"
                    style={{ width: "10rem" }}
                  >
                    <Select
                      placeholder={<Trans>No cull</Trans>}
                      value={
                        cull
                          ? {
                              value: cull,
                              label: culls.find($ => $.id === cull)?.reason
                            }
                          : null
                      }
                      onChange={event => setCull(event.value)}
                      options={culls?.map($ => ({
                        value: $.id,
                        label: $.reason
                      }))}
                    />
                  </div>
                </>
              )}
              <Button
                className="px-3 rounded-pill"
                onClick={() => {
                  resetSelectedLog()
                  updateLogsAndReferences()
                }}
                disabled={editorSavingChanges ? true : locked ? true : false}
              >
                {editorSavingChanges ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                ) : (
                  <Trans>Save changes</Trans>
                )}
              </Button>
            </Col>
          </div>
        )}
      </>
    </>
  ) : null

  async function updateLoadLines() {
    fetch(`/truck_measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        truck_measurement: {
          loads: loads
        }
      }
    })
      .then(response => {
        // setLoads(response.loads)
        setMeasurement({
          ...measurement,
          ...response,
          loads
        })
        refreshTruckStorages()
        toast.success(<Trans>Your changes have been saved</Trans>)
      })
      .catch(() =>
        toast.error(
          <Trans>Encountered an error while updating load lines</Trans>
        )
      )
  }
}
