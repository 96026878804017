import { Button, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementByIdAndTypeQuery,
  measurementByIdQuery
} from "common/recoil/selectors"
import { Trans } from "@lingui/macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faList } from "@fortawesome/free-solid-svg-icons"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { useEffect } from "react"

export const MeasurementSquareError = ({ id }) => (
  <div
    className="bg-danger rounded me-3 p-1 mb-3"
    style={{ width: "80px", height: "80px" }}
  >
    <small className="text-light lh-1 fw-medium">
      <Trans>Failed to load</Trans>
      <span className="opacity-50"> ID:{id}</span>
    </small>
  </div>
)

export const MeasurementSquareLoading = () => (
  <div
    className="bg-gainsboro rounded me-3 mb-3"
    style={{ width: "192px", height: "192px" }}
  />
)

export default function MeasurementSquare({ id, duplicateCaseMeasurements }) {
  const measurement = useRecoilValue(measurementByIdQuery(id))
  const setMeasurement = useSetRecoilState(
    measurementByIdAndTypeQuery({
      id: measurement.id,
      type: "timber"
    })
  )
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const navigate = useNavigate()

  useEffect(() => {
    if (measurement) {
      setMeasurement(measurement)
    }
  }, [measurement, setMeasurement])

  return (
    <Card>
      <Card.Img
        variant="top"
        style={{
          maxWidth: "192px"
        }}
        src={measurement.photo_optimized}
      />
      <div>
        <Button
          size="sm"
          variant="primary"
          className="w-50 rounded-0"
          onClick={() =>
            measurementEditor({
              id: measurement.id,
              type: "timber",
              duplicateCaseMeasurements: duplicateCaseMeasurements
            })
          }
        >
          <FontAwesomeIcon icon={faEdit} className="opacity-50 me-1" />
          <Trans>Editor</Trans>
        </Button>
        <Button
          size="sm"
          variant="primary"
          className="w-50 rounded-0"
          onClick={() => navigate("/measurement/" + measurement.id)}
        >
          <FontAwesomeIcon icon={faList} className="opacity-50 me-1" />
          <Trans>Details</Trans>
        </Button>
      </div>

      {/* <Image
        onError={e => (e.target.src = "/android-chrome-192x192.png")}
        // onClick={() => setShowEditor(true)}
        className="me-3 mb-3 hover-dark click-dark"
        style={{
          width: "192px",
          cursor: "pointer"
        }}
        thumbnail
      /> */}
    </Card>
  )
}
