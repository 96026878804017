import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Button, Card, Form, InputGroup, Row } from "react-bootstrap"
import { toast } from "react-toastify"

export default function Timber({ measurement, token }) {
  return measurement ? (
    <Card body className="mb-3">
      <Row>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Measured at</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={
              new Date(measurement.created_at).toLocaleString() || "-"
            }
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Coefficient</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={measurement.coefficient || "-"}
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Shipment number</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={measurement.shipment_number || "-"}
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Measurement type</Trans>
          </Form.Label>
          <Form.Control
            defaultValue={measurement.measurement_type_id}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Wood type</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={measurement.wood_type || "-"} />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Wood quality</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={measurement.wood_quality_id || "-"}
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Wood assortment</Trans>
          </Form.Label>
          <Form.Control
            defaultValue={measurement.wood_characteristic || "-"}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Log length</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={
              measurement.log_length
                ? measurement.log_length.log_length +
                  " " +
                  unitToSymbol(measurement.log_length.unit)
                : "-"
            }
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Log count</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={measurement?.logs?.length} />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Pile heights average</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={"-"} />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Volume</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={
              measurement.volume
                ? measurement.volume.volume +
                  " " +
                  unitToSymbol(measurement.volume.unit)
                : "-"
            }
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Volume formula</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={measurement.volume_formula} />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Weight</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={
              measurement.weight
                ? measurement.weight.weight +
                  " " +
                  unitToSymbol(measurement.weight.unit)
                : "-"
            }
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Weight density</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={measurement.weight_density || "-"}
          />
        </Form.Group>
        <Form.Group className="mb-2 col-auto col">
          <Form.Label>
            <Trans>Weight per log</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={"-"} />
        </Form.Group>
        <Form.Group className="mb-4 col-auto col">
          <Form.Label>
            <Trans>Reference size</Trans>
          </Form.Label>
          <Form.Control
            disabled
            defaultValue={
              measurement?.references[0]?.size
                ? measurement?.references[0]?.size + " m"
                : "-"
            }
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>
            <Trans>Public URL</Trans>
          </Form.Label>
          <InputGroup>
            <Form.Control
              className="small"
              readOnly
              disabled
              defaultValue={
                window.location.host + "/public_measurement/" + token
              }
            />
            <Button variant="secondary" onClick={handleCopyToClipboard}>
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </InputGroup>
        </Form.Group>
      </Row>
    </Card>
  ) : null

  function handleCopyToClipboard(event) {
    navigator.clipboard.writeText(window.location.href)
    toast("Copied to clipboard!")
  }

  function unitToSymbol(unit) {
    return (
      {
        m: "m",
        "m^3": "m³"
      }[unit] || unit
    )
  }
}
