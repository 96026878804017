import {
  faFileExcel,
  faFilter,
  faMapLocation,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import { measurementsModeState, showMapState } from "common/recoil/atoms"
import useExportsState from "exports/useExportsState"
import { Suspense, useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import Map from "timber-measurement/map/Map"
import TimberFilters from "timber-measurement/TimberFilters"
import TimberStorageRowList from "timber-measurement/TimberStorageRowList"
import Toolbar from "./Toolbar"

export default function TimberDashboard() {
  const [showFilters, setShowFilters] = useState(false)
  const { createDashboardExcelReport } = useExportsState()
  const [showMap, setShowMap] = useRecoilState(showMapState)
  const [module, setModule] = useRecoilState(measurementsModeState)
  const fetch = useFetch()
  const navigate = useNavigate()
  useEffect(() => {
    if (module === "timber") return
    fetch("/account", {
      method: "PATCH",
      body: {
        user: {
          recent_measurement_mode: "timber"
        }
      }
    }).then(() => setModule("timber"))
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="d-flex flex-wrap gap-3">
        <Button
          className="rounded-pill px-3"
          variant={showFilters ? "light" : "outline-light"}
          onClick={() => setShowFilters(!showFilters)}
        >
          <FontAwesomeIcon icon={faFilter} className="me-2" />
          <Trans>Search filters</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant={showMap ? "light" : "outline-light"}
          onClick={() => setShowMap(!showMap)}
        >
          <FontAwesomeIcon icon={faMapLocation} className="me-2" />
          <Trans>Map</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant="light"
          onClick={() => createDashboardExcelReport()}
        >
          <FontAwesomeIcon icon={faFileExcel} className="me-2" />
          <Trans>Export to excel</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant="light"
          onClick={() => navigate("/storage/new")}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <Trans>New storage</Trans>
        </Button>
      </div>
      <hr className="text-light" />
      {showFilters && (
        <>
          <Card bg="light" body className="mt-3">
            <Suspense fallback={<Loading color="dark" />}>
              <TimberFilters />
            </Suspense>
          </Card>
          <hr className="text-light" />
        </>
      )}
      <Map />
      <Toolbar />
      <TimberStorageRowList />
    </div>
  )
}
