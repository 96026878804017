import { faCheck, faCog, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Spinner from "common/other/Spinner"
import {
  organizationPlanDate,
  organizationPlanInterval
} from "billing/AdditionalFeatures"
import { useEffect, useState } from "react"
import { Button, Card, Col, Form, InputGroup, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  billingCurrencySelector,
  pricingState,
  subscriptionItemsState
} from "."
import { subscriptionExistsSelector } from "./TimbeterPro"
import { invoicesState } from "./Overview"
import { useNavigate } from "react-router-dom"
import { pricePreviewBillingCycle } from "./SubscriptionPlans"

const containerSelector = selector({
  key: "containerSubscription",
  get: ({ get }) =>
    get(subscriptionItemsState).find(
      item => item.package_id === "container_subscription"
    )
})

export default function Container() {
  const fetch = useFetch()
  const pricePreviewBilling = useRecoilValue(pricePreviewBillingCycle)
  const orgBilling = useRecoilValue(organizationPlanInterval)
  const billing = orgBilling || pricePreviewBilling
  const pricing = useRecoilValue(pricingState)
  const [subscriptionItems, setSubscriptionItems] = useRecoilState(
    subscriptionItemsState
  )
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const [confirmingPayment, setConfirmingPayment] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [pricePreview, setPricePreview] = useState()
  const [loadingPricePreview, setLoadingPricePreview] = useState(false)
  const planExists = useRecoilValue(containerSelector)
  const billingDate = useRecoilValue(organizationPlanDate)
  const [invoice, setInvoice] = useState()
  const setInvoices = useSetRecoilState(invoicesState)
  const navigate = useNavigate()
  const billingCurrency = useRecoilValue(billingCurrencySelector)

  function addPlan() {
    if (subscriptionExists) {
      setConfirmingPayment(true)

      if (planExists) {
        fetch("/subscription/items", {
          method: "PATCH",
          body: {
            package_id: "container_subscription",
            quantity
          }
        }).then(response => {
          setInvoice(response)
          setInvoices(invoices => [response, ...invoices])
          setConfirmingPayment(false)
        })
      } else {
        fetch("/subscription/items", {
          method: "POST",
          body: {
            package_id: "container_subscription",
            quantity
          }
        }).then(response => {
          setInvoice(response)
          setInvoices(invoices => [response, ...invoices])
          setConfirmingPayment(false)
        })
      }
    } else {
      fetch("/checkout/subscription", {
        method: "POST",
        body: {
          period: billing === "yearly" ? 12 : 1,
          line_items: [
            {
              quantity,
              package_id: "container_subscription"
            }
          ]
        }
      }).then(response => {
        window.location.assign(response.redirect_url)
      })
    }
  }

  useEffect(() => {
    if (
      showModal &&
      (quantity > planExists?.quantity || (subscriptionExists && !planExists))
    ) {
      setPricePreview()
      setLoadingPricePreview(true)
      fetch("/subscription/items/preview", {
        method: "POST",
        body: {
          package_id: "container_subscription",
          quantity
        }
      })
        .then(response => {
          setPricePreview(response)
        })
        .finally(() => {
          setLoadingPricePreview(false)
        })
    }
    // eslint-disable-next-line
  }, [showModal, quantity])

  function cancelPlan() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/subscription/items/container_subscription", {
        method: "DELETE"
      })
        .then(response => {
          // console.log(response)
          setShowModal(false)
          setSubscriptionItems(items =>
            items.map(item =>
              item.package_id === "container_subscription"
                ? {
                    ...item,
                    cancelled: true
                  }
                : item
            )
          )
          toast.success(<Trans>Canceled plan</Trans>)
        })
        .catch(() => {
          toast.error(<Trans>Failed to cancel plan</Trans>)
        })
    }
  }

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        {!invoice ? (
          <>
            <Modal.Header className="pb-0" closeButton>
              <Modal.Title>
                <Trans>Container</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <ul className="list-unstyled text-start small bg-light px-4 py-3 rounded">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Unlimited measurements
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Measurement editing
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Measurement sharing
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Bills of lading
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  Contracts
                </li>
              </ul>
              <Form.Group className="mb-2">
                <Form.Label>
                  <Trans>Device licenses</Trans>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={quantity} readOnly />
                  <Button onClick={() => setQuantity(quantity + 1)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  {/* <Button
                    variant="outline-primary"
                    disabled={quantity === 1}
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button> */}
                </InputGroup>
                {(quantity > planExists?.quantity ||
                  (subscriptionExists && !planExists)) && (
                  <div className="bg-light px-3 py-2 rounded mt-2">
                    <p className="small text-muted">
                      <Trans>Total</Trans>
                    </p>
                    {pricePreview ? (
                      <span className="fw-semibold">
                        {billingCurrency}
                        {pricePreview.amount_due}
                      </span>
                    ) : (
                      <Spinner variant="dark" />
                    )}
                  </div>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="w-full position-relative"
                disabled={
                  loadingPricePreview ||
                  confirmingPayment ||
                  quantity === planExists?.quantity
                }
                onClick={addPlan}
              >
                <span className="position-absolute start-0 align-items-center ps-3 opacity-25">
                  {confirmingPayment && <Spinner />}
                </span>
                <span>
                  {subscriptionExists ? (
                    planExists ? (
                      <Trans>Confirm</Trans>
                    ) : (
                      <Trans>Start plan</Trans>
                    )
                  ) : (
                    <Trans>Checkout</Trans>
                  )}
                </span>
              </Button>
              {planExists && (
                <Button
                  onClick={cancelPlan}
                  variant="outline-secondary"
                  className="w-full border-0"
                >
                  <Trans>Cancel plan</Trans>
                </Button>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header className="pb-0" closeButton>
              <Modal.Title>
                <Trans>Order received</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3">
                A new pending invoice has been generated and is awaiting
                payment.
              </p>
              <Button
                onClick={() => navigate("/billing/invoices")}
                variant="info"
                className="w-full"
              >
                <Trans>Invoices</Trans>
              </Button>
            </Modal.Body>
          </>
        )}
      </Modal>
      <Col xs="12" md="6" lg="4">
        <Card className="mb-4 rounded border-0">
          <div className="bg-primary text-light rounded-top fw-semibold bg-gradient">
            {planExists &&
              (planExists.cancelled ? (
                <>
                  <Trans>Active until</Trans>{" "}
                  {new Date(billingDate).toLocaleDateString()}
                </>
              ) : (
                <>
                  <Trans>Active</Trans> · {planExists.quantity}{" "}
                  <Trans>licenses</Trans>
                </>
              ))}
          </div>
          <Card.Header className="py-3 shadow">
            <p className="fw-semibold py-2">
              <img
                src="/logos/container.svg"
                width="28"
                className="rounded me-2"
                alt="Container"
              />
              <Trans>Container</Trans>
            </p>
            <div className="pt-2 pb-4 lh-1">
              <Card.Title className="display-6 fw-semibold mb-0 lh-1">
                <span className="fs-5">{billingCurrency}</span>
                {billing === "monthly"
                  ? Math.round(pricing.container_subscription.monthly_price)
                  : Math.round(
                      pricing.container_subscription.yearly_price / 12
                    )}
              </Card.Title>
              <small
                className="text-black-50 opacity-75"
                style={{ fontSize: "0.75rem" }}
              >
                <Trans>device/month</Trans>
              </small>
            </div>
            {billing === "yearly" ? (
              <p className="text-muted small mb-4">
                <Trans>Billed as</Trans> {billingCurrency}
                {Math.round(pricing.container_subscription.yearly_price)}{" "}
                <Trans>per year</Trans>
              </p>
            ) : (
              <p className="text-muted small mb-4">
                <Trans>Billed as</Trans> {billingCurrency}
                {Math.round(pricing.container_subscription.monthly_price)}{" "}
                <Trans>per month</Trans>
              </p>
            )}
            {subscriptionItems?.some(
              item => item.package_id === "container_subscription"
            ) ? (
              <Button
                size="sm"
                variant="secondary"
                className="w-full"
                disabled={planExists.cancelled}
                onClick={() => {
                  setQuantity(
                    subscriptionItems.find(
                      item => item.package_id === "container_subscription"
                    ).quantity || 0
                  )
                  setShowModal(true)
                }}
              >
                <FontAwesomeIcon icon={faCog} className="me-2 opacity-50" />
                <Trans>Plan settings</Trans>
              </Button>
            ) : (
              <Button
                className="w-100"
                variant="dark"
                onClick={() => setShowModal(true)}
              >
                <Trans>Add plan</Trans>
              </Button>
            )}
          </Card.Header>
        </Card>
      </Col>
    </>
  )
}
