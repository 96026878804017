import { Card, Table } from "react-bootstrap"

export default function LogsDiameters({ logsSheet }) {
  return logsSheet?.logs?.length > 0 ? (
    <Card className="mb-3">
      <Table size="sm" className="caption-top">
        <thead>
          <tr>
            <th />
            <th>Diameter</th>
            <th>Log length</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {logsSheet.logs.map($ => (
            <tr key={$.diameter}>
              <td />
              <td>
                {$.diameter} <small>{logsSheet.summary["diameter_unit"]}</small>
              </td>
              <td>
                {$["log_length"]}{" "}
                <small>{logsSheet.summary["length_unit"]}</small>
              </td>
              <td>{$.logs}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="table-info">
            <td className="p-2">
              <small>Average</small>
            </td>
            <td className="py-3">
              {logsSheet.summary.average_diameter}{" "}
              <small>{logsSheet.summary.diameter_unit}</small>
            </td>
            <td className="py-3">
              {logsSheet.logs[0]["log_length"]}{" "}
              <small>{logsSheet.summary.length_unit}</small>
            </td>
            <td className="py-3">
              {logsSheet.summary.total_logs} <small>in total</small>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Card>
  ) : null
}
