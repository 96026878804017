import { Card, Col, Container, Row, Button } from "react-bootstrap"
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil"
import {
  featureEnabled,
  measurementsModeState,
  reportsState,
  roundingPrecisionState,
  selectedMeasurementsState,
  storagesState
} from "common/recoil/atoms"
import Select from "common/other/Select"
import { t, Trans } from "@lingui/macro"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import {
  reportsQuery,
  storagesListQuery,
  truckStoragesQuery
} from "common/recoil/selectors"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"
import useExportsState from "exports/useExportsState"

export default function Toolbar() {
  const fetch = useFetch()
  const module = useRecoilValue(measurementsModeState)
  const storages = useRecoilValue(storagesListQuery)
  const reportsEnabled = useRecoilValue(featureEnabled("reports"))
  const reports = useRecoilValue(reportsState)
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const [deleting, setDeleting] = useState(false)
  const roundingPrecision = useRecoilValue(roundingPrecisionState)
  const refreshStorages = useRecoilRefresher_UNSTABLE(storagesState)
  const refreshTruckStorages = useRecoilRefresher_UNSTABLE(truckStoragesQuery)
  const refreshsReports = useRecoilRefresher_UNSTABLE(reportsQuery)
  const { createDashboardExcelReport } = useExportsState()

  return selectedMeasurements ? (
    <Container
      fluid="lg"
      className="sticky-top"
      style={{
        top: "1rem",
        display: selectedMeasurements.length ? "block" : "none",
        width: "auto"
      }}
    >
      <Card
        bg="primary"
        className="shadow-lg text-white text-center bg-gradient"
        style={{ padding: "1rem 2rem" }}
      >
        <Row className="mb-2">
          <Col>
            <p className="mb-2">
              {selectedMeasurements.length} measurement(s) selected
            </p>
            <p className="opacity-50 mb-2 fs-6">
              {selectedMeasurements.map($ => (
                <span key={$.id}>{$.photo_file_name}</span>
              ))}
            </p>
            <h3>
              {selectedMeasurements
                .reduce(
                  (acc, val) => Number(acc) + Number(val?.volume ?? val.volume),
                  0
                )
                .toFixed(roundingPrecision)}{" "}
              m³
            </h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="me-2"
              style={{
                display: "inline-block",
                width: "200px"
              }}
            >
              <Select
                placeholder={t`Move to storage`}
                onChange={event => {
                  fetch("/measurements/move_storage", {
                    method: "PATCH",
                    body: {
                      move_storage: selectedMeasurements.map(({ id }) => ({
                        measurement_id: id,
                        storage_id: event.value
                      }))
                    }
                  }).then(response => {
                    toast.success(<Trans>Measurements moved</Trans>)
                    refreshStorages()
                    setSelectedMeasurements([])
                  })
                }}
                options={storages?.map(storage => ({
                  value: storage.id,
                  label: storage.name
                }))}
              />
            </div>
            {reportsEnabled && (
              <div
                className="me-2"
                style={{
                  display: "inline-block",
                  width: "200px"
                }}
              >
                <Select
                  placeholder={t`Add to report`}
                  onChange={event => {
                    if (
                      window.confirm(
                        t`Selected measurements will be added to the report`
                      )
                    ) {
                      // console.log(selectedMeasurements)
                      if (
                        selectedMeasurements.some(
                          $ =>
                            !$.assortment_id &&
                            $.logs.some(
                              $ => !$.wood_characteristic_id && !$.cull_id
                            )
                        )
                      ) {
                        return toast.warn(
                          <Trans>
                            Measurements must have assortment or diameter
                            profile attached
                          </Trans>
                        )
                      }
                      fetch(`/reports/${event.value}/add_measurements`, {
                        method: "PATCH",
                        body: {
                          measurement_ids: selectedMeasurements.map(
                            ({ id }) => id
                          )
                        }
                      }).then(response => {
                        refreshsReports()
                        toast.success(
                          <Trans>Measurements added to report</Trans>
                        )
                        setSelectedMeasurements([])
                      })
                    }
                  }}
                  options={reports?.map(report => ({
                    value: report.id,
                    label: report.name
                  }))}
                />
              </div>
            )}
            <Button
              variant="outline-light"
              className="me-2"
              onClick={() => setSelectedMeasurements([])}
            >
              <Trans>Deselect</Trans>
            </Button>
            <Button
              variant="danger"
              className="me-2"
              disabled={deleting}
              onClick={() => {
                if (window.confirm(t`Are you sure?`)) {
                  const ids = selectedMeasurements.map(({ id }) => id)
                  setDeleting(true)
                  if (module === "timber") {
                    fetch(`/measurements`, {
                      method: "DELETE",
                      body: {
                        measurement_ids: ids
                      }
                    })
                      .then(response => {
                        toast.success(<Trans>Measurements deleted</Trans>)
                        refreshStorages()
                        setSelectedMeasurements([])
                      })
                      .finally(() => setDeleting(false))
                  } else if (module === "truck") {
                    fetch(`/truck_measurements`, {
                      method: "DELETE",
                      body: {
                        measurement_ids: ids
                      }
                    })
                      .then(response => {
                        toast.success(<Trans>Measurements deleted</Trans>)
                        if (module === "timber") {
                          refreshStorages()
                        } else if (module === "truck") {
                          refreshStorages()
                          refreshTruckStorages()
                        }
                        setSelectedMeasurements([])
                      })
                      .finally(() => setDeleting(false))
                  }
                }
              }}
            >
              {deleting ? <Trans>Deleting...</Trans> : <Trans>Delete</Trans>}
            </Button>
            <Button
              variant="light"
              className="me-2"
              onClick={() =>
                createDashboardExcelReport(selectedMeasurements.map($ => $.id))
              }
            >
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              <Trans>Export</Trans>
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  ) : null
}
