import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import { measurementsModeState } from "common/recoil/atoms"
import AddBillOfLadingModal from "container-measurement/AddBillOfLadingModal"
import BillOfLadingRowList from "container-measurement/BillOfLadingRowList"
import ContainerFilters from "container-measurement/ContainerFilters"
import { Suspense, useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import Toolbar from "./Toolbar"
import { selectedContainerMeasurementsState } from "container-measurement/Toolbar"

export default function ContainerDashboard() {
  const [showFilters, setShowFilters] = useState(false)
  const [module, setModule] = useRecoilState(measurementsModeState)
  const selectedMeasurements = useRecoilValue(
    selectedContainerMeasurementsState
  )
  const fetch = useFetch()
  useEffect(() => {
    if (module === "container") return
    fetch("/account", {
      method: "PATCH",
      body: {
        user: {
          recent_measurement_mode: "container"
        }
      }
    }).then(() => setModule("container"))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="d-flex flex-wrap gap-3">
        <Button
          className="rounded-pill px-3"
          variant={showFilters ? "light" : "outline-light"}
          onClick={() => setShowFilters(!showFilters)}
        >
          <FontAwesomeIcon icon={faFilter} className="me-2" />
          <Trans>Search filters</Trans>
        </Button>
        <AddBillOfLadingModal />
      </div>
      <hr className="text-light" />
      {showFilters && (
        <Card bg="light" body>
          <Suspense fallback={<Loading color="dark" />}>
            <ContainerFilters />
          </Suspense>
        </Card>
      )}
      {selectedMeasurements?.length > 0 ? <Toolbar /> : null}
      <Suspense fallback={<Loading />}>
        <BillOfLadingRowList />
      </Suspense>
    </>
  )
}
