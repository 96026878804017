import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import {
  filteredStoragesState,
  filteredTruckStoragesState
} from "common/recoil/atoms"
import { querySelector } from "common/recoil/selectors"
import { toast } from "react-toastify"
import { atom, selector, useRecoilCallback, useRecoilState } from "recoil"

export const excelExportsState = atom({
  key: "excelExports",
  default: selector({
    key: "excelexports/default",
    get: ({ get }) => get(querySelector("/excel_exports"))
  })
})

export const pdfExportsState = atom({
  key: "pdfExports",
  default: selector({
    key: "pdfExports/default",
    get: ({ get }) => get(querySelector("/pdf_exports"))
  })
})

// dashboard_excel: :measurement_ids,
// container_dashboard_excel: :measurement_ids,
// log_by_log_excel: :measurement_id,
// container_log_by_log_excel: :container_id,
// bill_of_lading_invoice_excel: :bill_of_lading_id,
// inventories_excel: :inventory_id,
// exports_excel: :report_id,
// bill_of_lading_tally_sheet_excel: :bill_of_lading_id,
// wendel_log_excel: :storage_id,
// red_october_summary_excel: :storage_id

export default function useExportsState() {
  const [excelExports, setExcelExports] = useRecoilState(excelExportsState)
  const [pdfExports, setPdfExports] = useRecoilState(pdfExportsState)
  const fetch = useFetch()

  const getAllTimberMeasurementIds = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const filteredStorages = await snapshot.getPromise(
          filteredStoragesState
        )
        return filteredStorages.reduce((ids, storage) => {
          return storage?.measurements
            ? [...ids, ...storage?.measurements]
            : ids
        }, [])
      },
    []
  )

  const getAllTruckMeasurementIds = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const filteredStorages = await snapshot.getPromise(
          filteredTruckStoragesState
        )
        return filteredStorages.reduce((ids, storage) => {
          return storage?.measurements
            ? [...ids, ...storage?.measurements]
            : ids
        }, [])
      },
    []
  )

  const createDashboardExcelReport = useRecoilCallback(
    () => async ids => {
      const measurementIds = await getAllTimberMeasurementIds()
      if (ids?.length === 0 && !measurementIds?.length === 0) {
        return toast.error(
          <Trans>Unable to export. No measurements found</Trans>
        )
      }
      fetch("/excel_exports", {
        method: "POST",
        body: {
          measurement_ids: ids ?? measurementIds,
          report_type: "dashboard_excel"
        }
      }).then(response => {
        if (response?.id) {
          setExcelExports([...excelExports, response])
          toast.info(<Trans>Started generating export...</Trans>)
        } else {
          toast.error(<Trans>Failed to export to excel</Trans>)
        }
      })
    },
    []
  )

  const createLumberDashboardExcelReport = useRecoilCallback(
    () => async ids => {
      if (ids?.length === 0) {
        return toast.error(
          <Trans>Unable to export. No measurements found</Trans>
        )
      }
      fetch("/excel_exports", {
        method: "POST",
        body: {
          measurement_ids: ids,
          report_type: "lumber_dashboard_excel"
        }
      }).then(response => {
        if (response?.id) {
          setExcelExports([...excelExports, response])
          toast.info(<Trans>Started generating export...</Trans>)
        } else {
          toast.error(<Trans>Failed to export to excel</Trans>)
        }
      })
    },
    []
  )

  const createTruckDashboardExcelReport = useRecoilCallback(
    () => async () => {
      const measurementIds = await getAllTruckMeasurementIds()
      fetch("/excel_exports", {
        method: "POST",
        body: {
          measurement_ids: measurementIds,
          report_type: "truck_dashboard_excel"
        }
      }).then(response => {
        if (response?.id) {
          setExcelExports([...excelExports, response])
          toast.info(<Trans>Started generating export...</Trans>)
        } else {
          toast.error(<Trans>Failed to export to excel</Trans>)
        }
      })
    },
    []
  )

  const createContainerDashboardExcelReport = useRecoilCallback(
    () => filters => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          report_type: "container_dashboard_excel",
          ...filters
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createTruckMeasurementPdfReport = useRecoilCallback(
    () => id => {
      fetch("/pdf_exports/" + id, {
        method: "POST",
        body: {
          report_type: "truck_measurement_pdf"
        }
      }).then(response => {
        if (response.id) {
          setPdfExports([...pdfExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createContainerLogByLogReport = useRecoilCallback(
    () => containerId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          container_id: Number(containerId),
          report_type: "container_log_by_log_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createInventoryExcel = useRecoilCallback(
    () => inventoryId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          inventory_id: Number(inventoryId),
          report_type: "inventories_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createLogByLogReport = useRecoilCallback(
    () => measurementId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          measurement_id: Number(measurementId),
          report_type: "log_by_log_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createMeasurementPdf = useRecoilCallback(
    () => measurementId => {
      fetch("/pdf_exports/" + measurementId, {
        method: "POST",
        body: {
          report_type: "measurement_pdf"
        }
      }).then(response => {
        if (response.id) {
          setPdfExports([...pdfExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createBillOfLadingInvoiceReport = useRecoilCallback(
    () => billOfLadingId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          bill_of_lading_id: Number(billOfLadingId),
          report_type: "bill_of_lading_invoice_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createBillOfLadingTallySheet = useRecoilCallback(
    () => billOfLadingId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          bill_of_lading_id: Number(billOfLadingId),
          report_type: "bill_of_lading_tally_sheet_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createWendelLogReportExcel = useRecoilCallback(
    () => storageId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          storage_id: Number(storageId),
          report_type: "wendel_log_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createRedOctoberSummaryExcel = useRecoilCallback(
    () => storageId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          storage_id: Number(storageId),
          report_type: "red_october_summary_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  const createReportsExcel = useRecoilCallback(
    () => reportId => {
      fetch("/excel_exports", {
        method: "POST",
        body: {
          report_id: Number(reportId),
          report_type: "reports_excel"
        }
      }).then(response => {
        if (response.id) {
          setExcelExports([...excelExports, response])
        }
        toast.info(<Trans>Started generating export...</Trans>)
      })
    },
    []
  )

  return {
    createDashboardExcelReport,
    createTruckDashboardExcelReport,
    createContainerDashboardExcelReport,
    createLogByLogReport,
    createContainerLogByLogReport,
    createBillOfLadingInvoiceReport,
    createBillOfLadingTallySheet,
    createWendelLogReportExcel,
    createRedOctoberSummaryExcel,
    createReportsExcel,
    createMeasurementPdf,
    createInventoryExcel,
    createLumberDashboardExcelReport,
    createTruckMeasurementPdfReport
  }
}
