import { selector, selectorFamily } from "recoil"
import {
  filteredStoragesState,
  markersState,
  measurementEditorLoadsState,
  openStorageIdsState,
  selectedMeasurementsState,
  timberFilterState,
  accountSettingsState,
  measurementsStateFamily,
  containersState,
  featureEnabled,
  truckMeasurementsStateFamily,
  truckFilterState,
  filteredTruckStoragesState
} from "common/recoil/atoms"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { containerMeasurementState } from "container-measurement/ContainerMeasurementRowList"
import { logsState } from "timber-measurement/editor/useEditorState"
import { lumberMeasurementsState } from "lumber-measurement/LumberMeasurementsTable"
import normalizeJson from "common/utils/normalizeJson"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import { truckDiameterMeasurement } from "cmpc-dashboard/MeasurementsList"
import { lumberMeasurementsStateFamily } from "lumber-measurement/LumberMeasurementRow"

export const storageSelector = selectorFamily({
  key: "storageById",
  get:
    id =>
    ({ get }) => {
      const response = get(querySelector("/storages/" + id))
      return response?.storage
    },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent"
  }
})

export const storagesListQuery = selector({
  key: "storagesListQuery",
  get: ({ get }) => get(querySelector("/storages/list"))
})

export const storagesQuery = selector({
  key: "storagesQuery",
  get: ({ get }) => {
    const fromFilter = get(timberFilterState("from"))

    const response = get(
      querySelector(
        `/dashboard_filter?${new URLSearchParams({
          measurements_included: true,
          ...(fromFilter && {
            date_range_start: new Intl.DateTimeFormat("et-EE").format(
              fromFilter
            )
          })
        })}`
      )
    )

    return response?.storages?.length > 0 ? response.storages : []
  }
})

export const truckStoragesQuery = selector({
  key: "truckStoragesQuery",
  get: ({ get }) => {
    const fromFilter = get(truckFilterState("from"))

    const response = get(
      querySelector(
        `/dashboard_filter?${new URLSearchParams({
          measurements_included: true,
          truck_measurements: true,
          ...(fromFilter && {
            date_range_start: new Intl.DateTimeFormat("et-EE").format(
              fromFilter
            )
          })
        })}`
      )
    )

    return response?.storages?.length > 0 ? response.storages : []
  }
})

export const loadSelector = selectorFamily({
  key: "load",
  get:
    id =>
    ({ get }) =>
      get(measurementEditorLoadsState).find($ => $.id === id),
  set:
    id =>
    ({ get, set }, newValue) => {
      let loads = get(measurementEditorLoadsState)
      loads = loads.map($ => {
        if ($.id !== id) return $
        return {
          ...$,
          ...newValue
        }
      })
      set(measurementEditorLoadsState, loads)
    }
})

export const enabledFeaturesQuery = selector({
  key: "enabledFeaturesQuery",
  get: ({ get }) => get(querySelector("/enabled_features"))
})

export const woodCullsQuery = selector({
  key: "woodCullsQuery",
  get: ({ get }) => get(querySelector("/culls"))
})

export const diameterProfilesQuery = selector({
  key: "diameterProfilesQuery",
  get: async ({ get }) => {
    try {
      const diameterProfiles = await get(querySelector("/diameter_profiles"))
      return diameterProfiles?.length ? diameterProfiles : []
    } catch {
      return []
    }
  }
})

export const usersQuery = selector({
  key: "usersQuery",
  get: ({ get }) => get(querySelector("/users"))
})

export const reportsQuery = selector({
  key: "reportsQuery",
  get: async ({ get }) => {
    if (get(featureEnabled("reports"))) {
      const reports = await get(querySelector("/reports"))
      return reports?.length ? reports : []
    }

    return []
  }
})

export const setMeasurementMarkers = selector({
  key: "setMeasurementMarkers",
  get: ({ get }) => get(markersState),
  set:
    markers =>
    ({ get, set }) => {
      set(markersState, markers)
    }
})

export const setStorageMarkers = selector({
  key: "setStorageMarkers",
  get: ({ get }) => get(markersState),
  set: ({ get, set }, markers) => set(markersState, markers)
})

export const activeStateQuery = selectorFamily({
  key: "activeStateQuery",
  get:
    id =>
    ({ get }) =>
      get(querySelector(`/storages/${id}/active_state`))
})

export const truckMeasurementByIdQuery = selectorFamily({
  key: "truckMeasurementById",
  get:
    id =>
    async ({ get }) => {
      const measurement = await get(querySelector(`/truck_measurements/${id}`))

      return measurement
    }
})

export const volumeUnitSelector = selector({
  key: "volumeUnit",
  get: ({ get }) => {
    const account = get(accountSettingsState)
    return formatVolumeUnit(account.volume_unit)
  }
})

export const lengthUnitSelector = selector({
  key: "lengthUnit",
  get: ({ get }) => get(accountSettingsState).length_unit
})

export const referenceUnitSelector = selector({
  key: "referenceUnit",
  get: ({ get }) => get(accountSettingsState).reference_unit
})

export const weightUnitSelector = selector({
  key: "weightUnit",
  get: ({ get }) => get(accountSettingsState).weight_unit
})

export const densityUnitSelector = selector({
  key: "densityUnit",
  get: ({ get }) => get(accountSettingsState).density_unit
})

export const diameterUnitSelector = selector({
  key: "diameterUnit",
  get: ({ get }) => get(accountSettingsState).diameter_unit
})

// export const accessTokenState = atom({
//   key: "accessToken",
//   default: selector({
//     key: "accessToken/default",
//     get: () => {
//       const accessTokenFromCookie = document.cookie
//         .split(";")
//         .find($ => $.includes("timbeter_dashboard_token"))
//         ?.split("=")[1]

//       return accessTokenFromCookie ?? null
//     }
//   })
// })

// export const accessTokenExpiresAtState = atom({
//   key: "accessTokenExpiresAt",
//   default: selector({
//     key: "accessTokenExpiresAt/default",
//     get: () => {
//       // const accessTokenExpiCookie = document.cookie
//       //   .split(";")
//       //   .find($ => $.includes("timbeter_dashboard_token"))
//       //   ?.split("=")[1]

//       // if (accessTokenFromCookie) {
//       //   return accessTokenFromCookie
//       // }

//       const fromLocalStorage = localStorage.getItem("access_token_expires_at")

//       return fromLocalStorage ? fromLocalStorage : null
//     }
//   })
// })

export const querySelector = selectorFamily({
  key: "querySelector",
  get: params => async () => {
    if (localStorage.getItem("access_token_expires_at") < Date.now()) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("access_token_expires_at")
      return window.location.assign("/login")
    }

    let url = ""
    let options = {}
    if (params.url) {
      url = params.url
    } else {
      url = params
    }
    if (params.options) {
      options = params.options
      options.body = JSON.stringify(options.body)
    }
    let token = localStorage.getItem("access_token")
    let request = fetch(`${process.env.REACT_APP_TIMBETER_API}${url}`, {
      ...options,
      headers: {
        ...(options?.body && { "Content-Type": "application/json" }),
        Authorization: `Bearer ${token}`
      }
    })
    let response = await request

    if (!response.ok) {
      if (response.status === 401) {
        const wwwAuthenticate = response.headers.get("www-authenticate")
        if (
          wwwAuthenticate.includes("invalid_token") ||
          wwwAuthenticate.includes("expired_token")
        ) {
          return window.dispatchEvent(new Event("expired_token"))
        }
        return []
      }

      if (response.status === 500) {
        return null
      }

      if (response.status === 404) {
        return null
      }
    }

    // if (response.headers.get("content-type").includes("application/json")) {
    //   return response.json()
    // }

    // console.log(response.headers.get("content-type"))
    // return null
    return response.json()
  }
})

export const measurementByIdQuery = selectorFamily({
  key: "measurementByIdQuery",
  get:
    id =>
    async ({ get }) => {
      const measurement = await get(
        querySelector(
          `/measurements/${id}?${new URLSearchParams({
            fields:
              "storage_id,weight,weight_density,photo,photo_optimized,latitude,longitude,photo_thumbnail,volume,coefficient,wood_type_id,log_length,shipment_number,measurement_type_id,wood_quality_id,assortment_id,volume_formula,arrival_date,photo_file_name,load_lines,measurement_area",
            extra_fields:
              "logs,references,load_json,custom_fields,logs_sheet,device_id"
          })}`
        )
      )

      return normalizeJson(measurement.data)
    }
})

export const multipleMeasurementsQuery = selectorFamily({
  key: "multipleMeasurementQuery",
  get:
    ids =>
    ({ get }) =>
      ids.map(id => get(measurementsStateFamily(id)))
})

export const measurementByIdAndTypeQuery = selectorFamily({
  key: "measurementByIdAndTypeQuery",
  get:
    ({ id, type }) =>
    async ({ get }) => {
      if (!id || !type) return null
      if (type === "timber") {
        return get(measurementsStateFamily(id))
      }

      if (type === "container") {
        return get(containerMeasurementState(id))
      }

      if (type === "truck") {
        return get(truckMeasurementsStateFamily(id))
      }

      if (type === "truck_diameter") {
        return get(truckDiameterMeasurement(id))
      }

      if (type === "lumber") {
        return get(lumberMeasurementsStateFamily(id))
      }

      return null
    },
  set:
    ({ id, type }) =>
    ({ set }, updatedMeasurement) => {
      if (type === "container") {
        set(containerMeasurementState(id), updatedMeasurement)
      } else if (type === "timber") {
        set(measurementsStateFamily(id), updatedMeasurement)
      } else if (type === "truck") {
        set(truckMeasurementsStateFamily(id), updatedMeasurement)
      } else if (type === "truck_diameter") {
        set(truckDiameterMeasurement(id), updatedMeasurement)
      } else if (type === "lumber") {
        set(lumberMeasurement(id), updatedMeasurement)
      }
    }
})

export const lumberMeasurement = selectorFamily({
  key: "lumberMeasurement",
  get:
    id =>
    ({ get }) =>
      id ? get(lumberMeasurementsState).find($ => $.id === id) : null,
  set:
    id =>
    ({ get, set }, updatedLumberMeasurement) =>
      set(lumberMeasurementsState, lumberMeasurements =>
        lumberMeasurements.map($ =>
          $.id === id
            ? {
                ...$,
                ...updatedLumberMeasurement
              }
            : $
        )
      )
})

export const containerMeasurementQuery = selectorFamily({
  key: "containerMeasurementQuery",
  get:
    id =>
    async ({ get }) => {
      const response = await get(
        querySelector(
          `/containers/measurements/${id}?extra_fields=logs,references`
        )
      )
      return response.measurement
    }
})

export const woodQualitiesQuery = selector({
  key: "woodQualitiesQuery",
  get: ({ get }) => get(querySelector("/wood_qualities"))
})

export const accountSettingsQuery = selector({
  key: "accountSettingsQuery",
  get: async ({ get }) => {
    const response = await get(querySelector("/account"))
    return response?.user
  }
})

export const organizationsQuery = selector({
  key: "organizationsQuery",
  get: ({ get }) => get(querySelector("/organizations"))
})

export const woodTypesQuery = selector({
  key: "woodTypesQuery",
  get: ({ get }) => get(querySelector("/wood_types"))
})

export const woodAssortmentsQuery = selector({
  key: "woodAssortmentsQuery",
  get: ({ get }) => get(querySelector("/assortments"))
})

export const contractsQuery = selector({
  key: "contractsQuery",
  get: async ({ get }) => {
    const response = await get(querySelector("/containers/contracts"))
    return response.contracts
  }
})

export const containersQuery = selector({
  key: "containersQuery",
  get: async ({ get }) => {
    const response = await get(querySelector("/containers/containers"))
    return response
  }
})

export const containerByIdQuery = selectorFamily({
  key: "containerById",
  get:
    id =>
    ({ get }) =>
      get(containersState).find($ => $.id === id)
})

export const customFieldsQuery = selector({
  key: "customFieldsQuery",
  get: async ({ get }) => {
    const response = await get(querySelector("/custom_fields"))
    return response
  }
})

export const volumeFormulasQuery = selector({
  key: "volumeFormulasQuery",
  get: ({ get }) => get(querySelector("/volume_formulas"))
})

export const openStorageState = selectorFamily({
  key: "openStorage",
  get:
    id =>
    ({ get }) =>
      get(openStorageIdsState).includes(id),
  set:
    id =>
    ({ set, get }, id) =>
      set(openStorageIdsState, [...get(openStorageIdsState), id])
})

export const shortestDiameterLogState = selector({
  key: "LOGS_SHORTEST_DIAMETER",
  get: ({ get }) => {
    const logs = get(logsState)
    let sortedLogs = [...logs].filter($ => !$.isDeleted)
    sortedLogs.sort((a, b) => (Number(a.d) < Number(b.d) ? -1 : 1))
    const minDiameter = sortedLogs[0]?.d
    return minDiameter ? Number(Number(minDiameter).toFixed(2)) : 0
  }
})

export const longestDiameterLogState = selector({
  key: "LOGS_LONGEST_DIAMETER",
  get: ({ get }) => {
    const logs = get(logsState)
    let sortedLogs = [...logs].filter($ => !$.isDeleted)
    sortedLogs.sort((a, b) => (Number(a.d) < Number(b.d) ? -1 : 1))
    const maxDiameter = sortedLogs[sortedLogs.length - 1]?.d
    return maxDiameter ? Number(Number(maxDiameter).toFixed(2)) : 100
  }
})

export const isMeasurementSelectedState = selectorFamily({
  key: "IS_MEASUREMENT_SELECTED",
  get:
    id =>
    ({ get }) => {
      const selectedMeasurements = get(selectedMeasurementsState)

      const isSelected = selectedMeasurements.find($ => $.id === id)

      return !!isSelected
    }
})

export const lastDayMeasurementsSelector = selector({
  key: "lastDayMeasurementsSelector",
  get: ({ get }) => get(currentOrganizationState)?.last_day_measurements
})

export const clearFilterSelector = selector({
  key: "clearFilter",
  get: () => {},
  set: ({ get, set, reset }) => {
    set(timberFilterState("storageNames"), [])
    set(timberFilterState("storageStatuses"), [])
    set(timberFilterState("from"), "")
    set(timberFilterState("to"), null)
    set(timberFilterState("measurementTypes"), [])
    set(timberFilterState("woodAssortments"), [])
    set(timberFilterState("minLength"), "")
    set(timberFilterState("maxLength"), "")
    set(timberFilterState("minVolume"), "")
    set(timberFilterState("maxVolume"), "")
    set(timberFilterState("woodTypes"), [])
    set(timberFilterState("woodQualities"), [])
    set(timberFilterState("devices"), [])
    set(timberFilterState("shipmentNumber"), "")
    reset(filteredStoragesState)
  }
})

export const clearTruckFilterSelector = selector({
  key: "clearTruckFilter",
  get: () => {},
  set: ({ get, set, reset }) => {
    set(truckFilterState("storageNames"), [])
    set(truckFilterState("storageStatuses"), [])
    set(truckFilterState("from"), "")
    set(truckFilterState("to"), null)
    set(truckFilterState("measurementTypes"), [])
    set(truckFilterState("woodAssortments"), [])
    set(truckFilterState("minLength"), "")
    set(truckFilterState("maxLength"), "")
    set(truckFilterState("minVolume"), "")
    set(truckFilterState("maxVolume"), "")
    set(truckFilterState("woodTypes"), [])
    set(truckFilterState("woodQualities"), [])
    set(truckFilterState("devices"), [])
    set(truckFilterState("shipmentNumber"), "")
    reset(filteredTruckStoragesState)
  }
})
