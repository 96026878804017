import TruckMarker, {
  truckMarkerState
} from "truck-measurement/logistics/TruckMarker"
import { Suspense, useEffect, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MapContainer, TileLayer, useMap } from "react-leaflet"
import {
  useRecoilValue,
  atom,
  selector,
  useRecoilCallback,
  useRecoilState,
  selectorFamily
} from "recoil"
import { querySelector } from "common/recoil/selectors"
import Pusher from "pusher-js"
import MarkerClusterGroup from "react-leaflet-markercluster"
import { useNavigate } from "react-router-dom"
import RouteLine from "./RouteLine"
import { Trans } from "@lingui/macro"

const activeTruckRoutesState = atom({
  key: "activeTruckRoutes",
  default: selector({
    key: "activeTruckRoutes/default",
    get: () => querySelector("/truck_measurements/active_routes")
  })
})
let pusherAppKey =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "staging"
    ? "e67995f91093b97e0841"
    : process.env.NODE_ENV === "production"
    ? "f33f23d9d0260c105bd2"
    : ""
if (process.env.REACT_APP_ENV === "backup012024") {
  pusherAppKey = ""
}
const pusher = new Pusher(pusherAppKey, {
  cluster: "eu"
})
const channel = pusher.subscribe("truck-tracking-pusher")

export const selectedTruckRouteIdState = atom({
  key: "selectedTruckRouteId",
  default: null
})

const measurementIdFromRouteId = selectorFamily({
  key: "measurementIdFromRouteId",
  get:
    id =>
    ({ get }) =>
      get(activeTruckRoutesState).find($ => $.id === id)?.truck_measurement_id
})

export default function TruckMap() {
  const [markers, setMarkers] = useState([])
  const activeTruckRoutes = useRecoilValue(activeTruckRoutesState)
  const [selectedTruckRouteId, setselectedTruckRouteId] = useRecoilState(
    selectedTruckRouteIdState
  )
  const measurementId = useRecoilValue(
    measurementIdFromRouteId(selectedTruckRouteId)
  )
  const navigate = useNavigate()
  const [showRoute, setShowRoute] = useState(false)

  const updateTruckMarker = useRecoilCallback(
    ({ set }) =>
      route => {
        set(truckMarkerState(route.id), {
          id: route.id,
          last_checkpoint: route.last_checkpoint
        })
      },
    []
  )

  useEffect(() => {
    channel.bind("checkpoint-created", function (data) {
      updateTruckMarker({
        id: data.checkpoint.truck_route_id,
        last_checkpoint: data.checkpoint
      })
    })

    return () => channel.unbind("checkpoint-created")
  }, [updateTruckMarker])

  useEffect(() => {
    setMarkers(
      activeTruckRoutes.map($ => <TruckMarker key={$.id} truckRoute={$} />)
    )
  }, [activeTruckRoutes])

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 2000)
  }, [])

  function InfoControl() {
    return (
      <div className="leaflet-top leaflet-left" style={{ marginTop: 80 }}>
        <div className="leaflet-control leaflet-bar">
          <div
            className="bg-white text-center"
            style={{
              padding: "8px",
              height: selectedTruckRouteId ? 148 : 36,
              width: 200
            }}
          >
            <div className="d-grid gap-2">
              {selectedTruckRouteId ? (
                <>
                  <Button
                    onClick={() =>
                      navigate(`/truck_measurement/${measurementId}`)
                    }
                  >
                    Go to measurement
                  </Button>
                  <Button
                    onClick={() => setShowRoute(!showRoute)}
                    variant={showRoute ? "primary" : "secondary"}
                  >
                    {showRoute ? (
                      <Trans>Hide route</Trans>
                    ) : (
                      <Trans>Show route</Trans>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setselectedTruckRouteId(null)}
                  >
                    Deselect
                  </Button>
                </>
              ) : (
                <p>No truck selected</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Row>
      <Col id="map">
        <Suspense fallback>
          <MapContainer
            minZoom={2}
            center={[
              activeTruckRoutes.at(-1).last_checkpoint.longitude,
              activeTruckRoutes.at(-1).last_checkpoint.latitude
            ]}
            zoom={10}
            maxZoom={16}
            scrollWheelZoom={true}
            style={{ height: "480px", zIndex: 0 }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {selectedTruckRouteId && showRoute && (
              <Suspense fallback>
                <RouteLine routeId={selectedTruckRouteId} />
              </Suspense>
            )}
            <MarkerClusterGroup maxClusterRadius={80}>
              {markers}
            </MarkerClusterGroup>
            <InfoControl />
            <MyComponent />
          </MapContainer>
        </Suspense>
      </Col>
    </Row>
  )

  function MyComponent() {
    const map = useMap()
    useEffect(() => {
      map.flyToBounds(
        activeTruckRoutes.map($ => [
          $.last_checkpoint.longitude,
          $.last_checkpoint.latitude
        ])
      )
    }, [map])
    return null
  }
}
