import { faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { measurementsModeState } from "common/recoil/atoms"
import { useEffect } from "react"
import { Button, Card, Image } from "react-bootstrap"
import { useSetRecoilState } from "recoil"
import ShipmentsTable from "./ShipmentsTable"

export default function CMPCDashboard() {
  const setModule = useSetRecoilState(measurementsModeState)

  useEffect(() => {
    setModule("cmpc")
  }, [setModule])

  return (
    <div className="d-flex flex-column gap-3">
      <h4 className="text-light m-0">
        <Image width={24} height={24} src="/logos/cmpc.png" className="me-2" />
        <Trans>CMPC dashboard</Trans>
      </h4>
      <div className="d-flex gap-3 flex-wrap">
        <Button disabled variant="outline-light" className="rounded-pill px-3">
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <Trans>Create shipment</Trans>
        </Button>
        <Button
          disabled
          variant="outline-light"
          className="rounded-pill px-3"
          style={{ borderStyle: "dashed" }}
        >
          <FontAwesomeIcon icon={faFileImport} className="me-2" />
          <Trans>Import shipment</Trans>
        </Button>
      </div>
      {/* <Card body style={{ width: "fit-content" }}>
        <FontAwesomeIcon size="lg" className="text-secondary" icon={faHammer} />
        <span className="mx-2">Work in progress</span>
        <FontAwesomeIcon
          size="lg"
          className="text-warning"
          icon={faHelmetSafety}
        />
      </Card> */}
      <ShipmentsTable />
    </div>
  )
}

export function ExtraImagesCard() {
  return (
    <Card
      className="d-flex flex-column p-3 border-0 bg-secondary-subtle"
      style={{ height: "fit-content" }}
    >
      <div className="pb-3 lh-1">
        <div className="fw-semibold">Extra images (3)</div>
        <small className="text-muted">
          {new Intl.DateTimeFormat(undefined, {
            dateStyle: "medium"
          }).format(new Date())}
        </small>
      </div>
      <div className="d-flex flex-row">
        <Image
          height={128}
          width={128}
          thumbnail
          style={{ height: "fit-content" }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
        <Image
          height={128}
          width={128}
          thumbnail
          style={{
            height: "fit-content",
            position: "absolute",
            rotate: "5deg"
          }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
        <Image
          height={128}
          width={128}
          thumbnail
          style={{
            height: "fit-content",
            position: "absolute",
            rotate: "-5deg"
          }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
      </div>
    </Card>
  )
}

export function PulsatingDot() {
  return (
    <div className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1 dot">
      <span className="visually-hidden">unread messages</span>
    </div>
  )
}
