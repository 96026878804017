import { useEffect } from "react"
import { Modal, Row, CloseButton } from "react-bootstrap"
import Canvas from "./Canvas"
import { VIEW } from "common/enums/constants"
import {
  atom,
  atomFamily,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import {
  logsRangeState,
  measurementAreaState,
  measurementEditorLoadsState,
  referencesState
} from "common/recoil/atoms"
import {
  measurementByIdAndTypeQuery,
  volumeFormulasQuery
} from "common/recoil/selectors"
import {
  logsState,
  measurementEditorState,
  selectedLogState
} from "./useEditorState"
import MetricsPane from "./MetricsPane"
import WoodPane from "./WoodPane"
import TogglesPane from "./TogglesPane"
import EditMode from "./EditMode"
import ControlToolbar from "./ControlToolbar"
import useDetectChanges from "./useDetectChanges"
import { selectedPlankState } from "./Plank"
import { measurementEditorVolumeFormulaState } from "./ReferencePane"

export const editorSavingChangesState = atom({
  key: "editorSavingChanges",
  default: false
})

export const editorSettingsState = atomFamily({
  key: "editorSettings",
  default: null
})

export const planksState = atom({
  key: "planks",
  default: []
})

export default function MeasurementEditor() {
  useRecoilValueLoadable(volumeFormulasQuery)
  const { id: measurementId, type: measurementType } = useRecoilValue(
    measurementEditorState
  )
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const resetSelectedLog = useResetRecoilState(selectedLogState)
  const resetSelectedPlank = useResetRecoilState(selectedPlankState)
  const setPlanks = useSetRecoilState(planksState)
  const setLogs = useSetRecoilState(logsState)
  const setReferences = useSetRecoilState(referencesState)
  const setMeasurementArea = useSetRecoilState(measurementAreaState)
  const setLoads = useSetRecoilState(measurementEditorLoadsState)
  const [show, setShow] = useRecoilState(editorSettingsState("show"))
  const setShowReference = useSetRecoilState(
    editorSettingsState("showReference")
  )
  const setShowDiameters = useSetRecoilState(
    editorSettingsState("showDiameters")
  )
  const setShowLoadLines = useSetRecoilState(
    editorSettingsState("showLoadLines")
  )
  const setShowCentroids = useSetRecoilState(
    editorSettingsState("showCentroids")
  )
  const setShowOutlines = useSetRecoilState(editorSettingsState("showOutlines"))
  const setMode = useSetRecoilState(editorSettingsState("mode"))
  const setShowMeasurementArea = useSetRecoilState(
    editorSettingsState("showMeasurementArea")
  )
  const resetThumbs = useResetRecoilState(logsRangeState)
  const resetLogs = useResetRecoilState(logsState)
  const resetMeasurementEditor = useResetRecoilState(measurementEditorState)
  const resetMeasurementEditorVolumeFormula = useResetRecoilState(
    measurementEditorVolumeFormulaState
  )
  const detectChanges = useDetectChanges()
  useEffect(() => {
    setShow(measurementId !== null)
    setMode(VIEW)

    if (measurement) {
      if (measurement.logs) {
        setShowDiameters(true)
        setLogs(measurement.logs)
      }

      if (measurement.measurement_area) {
        setShowMeasurementArea(true)
        setMeasurementArea(measurement.measurement_area)
      }

      if (measurement.loads) {
        setShowLoadLines(true)
        setLoads(measurement.loads)
      }

      if (measurement.references) {
        setShowReference(true)
        setReferences(measurement.references)
      }

      if (measurement.planks) {
        setShowCentroids(true)
        setShowOutlines(true)
        setPlanks(measurement.planks)
      }
    }

    return () => {
      // console.log("unmounting <MeasurementEditor />")
      setShowMeasurementArea(false)
      setShowReference(false)
      setShowLoadLines(false)
      setShowDiameters(false)
      setShowCentroids(false)
      setShowOutlines(false)

      setPlanks([])
      setMeasurementArea([])
      setLoads([])
      setReferences([])
      resetLogs()
      resetSelectedPlank()
      resetThumbs()
      resetMeasurementEditorVolumeFormula()
    }
    // eslint-disable-next-line
  }, [measurementId, measurement])

  window.addEventListener("popstate", handleClose)

  function handleClose() {
    resetSelectedLog()
    resetMeasurementEditor()
  }

  return (
    <Modal
      contentClassName="measurement-editor"
      show={show}
      onHide={() => detectChanges(() => window.history.go(-1))}
      backdrop="static"
      fullscreen
    >
      {measurement && (
        <>
          <Modal.Header className="p-0 border-light border-bottom border-opacity-10">
            <Modal.Title className="fs-5 d-flex align-items-center">
              <div className="py-2 px-3" style={{ lineHeight: "0.8" }}>
                <span>
                  {measurement.photo_file_name || measurement.shown_name}
                </span>
                <br />
                <span
                  style={{ fontSize: "0.625rem" }}
                  className="text-white-50"
                >
                  ID: {measurement.id}
                </span>
              </div>
              <ControlToolbar />
            </Modal.Title>
            <CloseButton
              className="m-0"
              variant="white"
              onClick={() => detectChanges(() => window.history.go(-1))}
            />
          </Modal.Header>
          <Row className="g-0" style={{ zIndex: 2 }}>
            <ViewModePane />
            <EditMode />
          </Row>
          <Modal.Body
            style={{
              background: "black",
              overflow: "hidden",
              overflowY: "scroll",
              padding: 0
            }}
          >
            <Canvas />
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}

function ViewModePane() {
  const mode = useRecoilValue(editorSettingsState("mode"))
  return mode === VIEW ? (
    <>
      <TogglesPane />
      <WoodPane />
      <MetricsPane />
    </>
  ) : null
}
