import useFetch from "common/hooks/useFetch"
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState
} from "recoil"
import { customFieldsState, timberFilterState } from "common/recoil/atoms"
import { querySelector } from "common/recoil/selectors"
import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"

export const showCustomFieldIdState = atom({
  key: "showCustomFieldId",
  default: null
})

export const searchableCustomFields = selector({
  key: "searchableCustomFields",
  get: ({ get }) => {
    const fieldNames = get(customFieldsState)
      .filter($ => $.searchable && !$.archived)
      .map($ => $.key)
    return fieldNames
      .map($ => ({
        ["custom_fields[" + $ + "]"]: get(timberFilterState($))
      }))
      .filter($ => Object.entries($)[1] !== undefined)
      .reduce((accumulator, value) => ({ ...accumulator, ...value }), {})
  }
})

const customFieldState = atomFamily({
  key: "customField",
  default: selectorFamily({
    key: "customField/default",
    get:
      id =>
      ({ get }) =>
        id ? querySelector(`/custom_fields/${id}`) : null
  })
})

export default function useCustomFieldState(customFieldId) {
  const [customField, setCustomField] = useRecoilState(
    customFieldState(customFieldId)
  )
  const [customFields, setCustomFields] = useRecoilState(customFieldsState)
  const fetch = useFetch()

  const updateCustomField = useRecoilCallback(
    () => updatedCustomField => {
      fetch(`/custom_fields/${customFieldId}`, {
        method: "PATCH",
        body: {
          custom_field: updatedCustomField
        }
      }).then(response => {
        if (response) {
          toast.success(<Trans>Saved</Trans>)
          setCustomField({
            ...customField,
            ...updatedCustomField
          })
          setCustomFields(
            customFields.map($ => {
              if ($.id !== customFieldId) return $

              return {
                ...$,
                ...updatedCustomField
              }
            })
          )
        }
      })
    },
    [customFieldId]
  )

  return [customField, updateCustomField]
}
