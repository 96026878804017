import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner
} from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { billingCurrencySelector, pricingState } from "."
import { useSearchParams } from "react-router-dom"

export default function TimbeterMeasurements() {
  const fetch = useFetch()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [measurementsPlan, setMeasurementsPlan] = useState("PLAN_200")
  const pricing = useRecoilValue(pricingState)
  const billingCurrency = useRecoilValue(billingCurrencySelector)
  const [searchParams, setSearchParams] = useSearchParams()

  function buyMeasurements() {
    setLoading(pricing[measurementsPlan].package_id)
    fetch("/checkout/picture_package", {
      method: "POST",
      body: {
        package_id: pricing[measurementsPlan].package_id
      }
    }).then(response => {
      window.location.assign(response.redirect_url)
    })
  }

  useEffect(() => {
    const p = searchParams?.get("p")
    if (
      p &&
      ["PLAN_10", "PLAN_20", "PLAN_50", "PLAN_100", "PLAN_200"].includes(p)
    ) {
      setMeasurementsPlan(p)
      setShowModal(true)
    }
  }, [searchParams, setSearchParams])

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Buy measurements</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <ul className="text-start list-unstyled small bg-light px-4 py-3 rounded">
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Diameter, contour, density & truck measurements
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Measurement sharing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Measurement editing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Panorama
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              Basic storage module
            </li>
          </ul>
          <Alert className="my-2">
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
            <Trans>Bought measurements are valid for 1 year</Trans>
          </Alert>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Select amount</Trans>
            </Form.Label>
            <Form.Select
              value={measurementsPlan}
              onChange={event => setMeasurementsPlan(event.target.value)}
            >
              <option value="PLAN_10">
                10x ({billingCurrency}
                {pricing.PLAN_10.price_per_picture} per measurement)
              </option>
              <option value="PLAN_20">
                20x ({billingCurrency}
                {pricing.PLAN_20.price_per_picture} per measurement)
              </option>
              <option value="PLAN_50">
                50x ({billingCurrency}
                {pricing.PLAN_50.price_per_picture} per measurement)
              </option>
              <option value="PLAN_100">
                100x ({billingCurrency}
                {pricing.PLAN_100.price_per_picture} per measurement)
              </option>
              <option value="PLAN_200">
                200x ({billingCurrency}
                {pricing.PLAN_200.price_per_picture} per measurement)
              </option>
            </Form.Select>
            <div className="bg-light px-3 py-2 rounded mt-3">
              <div>
                <p className="small text-muted">
                  <Trans>Total</Trans>
                </p>
              </div>
              <div>
                <span className="small fw-semibold">{billingCurrency}</span>
                <span className="fw-semibold">
                  {pricing[measurementsPlan].price}
                </span>
              </div>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full position-relative"
            disabled={loading}
            onClick={buyMeasurements}
          >
            <span className="position-absolute start-0 align-items-center ps-3 opacity-25">
              {loading && (
                <Spinner
                  variant="light"
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </span>
            <span>
              <Trans>Confirm</Trans>
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col xs="12" sm="6" md="5">
          <Card className="mb-4 rounded-3 shadow-sm border-0 text-center">
            <Card.Header className="py-3 shadow">
              <p className="fw-semibold py-3 fs-5">
                <img
                  alt="Timbeter"
                  src="/logos/timber.svg"
                  width="36"
                  className="rounded me-2"
                />
                <Trans>Timbeter</Trans>
              </p>
              <p className="text-muted small mt-3">Starting from</p>
              <div className="pt-2 pb-5">
                <Card.Title className="display-6 fw-semibold mb-0 lh-1">
                  <span className="fs-5">{billingCurrency}</span>
                  {pricing.PLAN_200.price_per_picture}
                </Card.Title>
                <small
                  className="text-black-50 opacity-75 fw-normal"
                  style={{ fontSize: "0.75rem" }}
                >
                  per measurement
                </small>
              </div>
              <Button
                variant="dark"
                onClick={() => setShowModal(true)}
                className="w-100 position-relative d-flex align-items-center justify-content-center"
              >
                <Trans>Buy measurements</Trans>
              </Button>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </>
  )
}
