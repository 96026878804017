import {
  faArchive,
  faChevronDown,
  faChevronRight,
  faFileExcel,
  faFileExport,
  faShareNodes
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { Suspense, useEffect } from "react"
import { Badge, Dropdown } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  billExpandedState,
  billOfLadingState,
  contractsState,
  featureEnabled
} from "common/recoil/atoms"
import billOptions from "./billOptions"
import ContainerRowList from "./ContainerRowList"
import EditBillOfLadingModal from "./EditBillOfLadingModal"
import useExportsState from "exports/useExportsState"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import AddContainerModal from "./AddContainerModal"
import { handleCopyToClipboard } from "timber-measurement/TimberStorageRow"

export default function BillOfLadingRow(props) {
  const [expanded, setExpanded] = useRecoilState(
    billExpandedState(props.billOfLading.id)
  )
  const toggleExpand = () => setExpanded(!expanded)
  const { createBillOfLadingInvoiceReport, createBillOfLadingTallySheet } =
    useExportsState()
  const billOfLadingInvoiceEnabled = useRecoilValue(
    featureEnabled("bill_of_lading_invoice")
  )
  const [billOfLading, setBillOfLading] = useRecoilState(
    billOfLadingState(props.billOfLading.id)
  )
  const empty = billOfLading?.containers.length === 0

  const formatVolume = useVolumeFormatter()
  const contracts = useRecoilValue(contractsState)

  useEffect(() => {
    if (!billOfLading) setBillOfLading(props.billOfLading)
    // eslint-disable-next-line
  }, [])
  const fetch = useFetch()

  return (
    billOfLading && (
      <>
        <tr id={billOfLading.id} className="bg-white">
          <td
            colSpan="2"
            title={billOfLading.name}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            <div
              className={`d-flex fw-semibold align-items-center hover-dark cursor-pointer`}
              onClick={
                billOfLading.containers.length > 0 ? toggleExpand : undefined
              }
            >
              {!empty && (
                <div className="text-center" style={{ width: "2rem" }}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={expanded ? faChevronDown : faChevronRight}
                    className="text-primary"
                  />
                </div>
              )}
              <div className={`ms-2 text-primary`}>
                {billOfLading.name}
                {billOfLading.archived && (
                  <Badge
                    className="ms-2"
                    style={{ fontSize: "12px" }}
                    bg="secondary"
                  >
                    Archived
                  </Badge>
                )}
              </div>
            </div>
          </td>
          <td>
            {contracts.find($ => $.id === billOfLading.contract_id)?.name ||
              billOfLading.contract_id ||
              "–"}
          </td>
          <td>{billOfLading.log_count || 0}</td>
          <td>{formatVolume(Number(billOfLading?.volume || 0))}</td>
          <td>{billOfLading.containers.length || "–"}</td>
          <td className="d-flex justify-content-end align-items-center">
            <Dropdown>
              <Dropdown.Toggle as={billOptions} />
              <Dropdown.Menu rootCloseEvent="click">
                <Suspense fallback>
                  <AddContainerModal billId={billOfLading.id} />
                </Suspense>
                <EditBillOfLadingModal id={billOfLading.id} />
                {billOfLading.containers.length > 0 && (
                  <Dropdown.Item
                    onClick={() =>
                      createBillOfLadingTallySheet(billOfLading.id)
                    }
                  >
                    <Trans>Export</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faFileExcel} />
                    </span>
                  </Dropdown.Item>
                )}
                {billOfLading?.token && (
                  <Dropdown.Item
                    onClick={() =>
                      handleCopyToClipboard(
                        "/public_bill_of_lading/",
                        billOfLading.token
                      )
                    }
                  >
                    <Trans>Share</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faShareNodes} />
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    if (window.confirm(t`Are you sure?`)) {
                      fetch("/containers/bill_of_ladings/" + billOfLading.id, {
                        method: "PATCH",
                        body: {
                          archived: !billOfLading.archived
                        }
                      }).then(response => {
                        if (response) {
                          setBillOfLading({
                            ...billOfLading,
                            archived: !billOfLading.archived
                          })
                        }
                        toast(<Trans>Archived!</Trans>)
                      })
                    }
                  }}
                >
                  {billOfLading.archived ? (
                    <Trans>Unarchive</Trans>
                  ) : (
                    <Trans>Archive</Trans>
                  )}
                  <span className="float-end">
                    <FontAwesomeIcon icon={faArchive} />
                  </span>
                </Dropdown.Item>
                {billOfLadingInvoiceEnabled && (
                  <Dropdown.Item
                    onClick={() =>
                      createBillOfLadingInvoiceReport(billOfLading.id)
                    }
                  >
                    <Trans>Generate invoice</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faFileExport} />
                    </span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        {expanded && (
          <Suspense
            fallback={
              <tr className="text-light">
                <td>
                  <Trans>Loading...</Trans>
                </td>
              </tr>
            }
          >
            <ContainerRowList billOfLadingId={billOfLading.id} />
          </Suspense>
        )}
      </>
    )
  )
}
