import { useRef } from "react"
import { Line, Circle } from "react-konva"
import { useRecoilState, useRecoilValue } from "recoil"
import { measurementAreaState } from "common/recoil/atoms"
import { editorSettingsState } from "."
import { EDIT, MEASUREMENT_AREA, VIEW } from "common/enums/constants"

export default function Polygon({ isEditMode }) {
  const [measurementArea, setMeasurementArea] =
    useRecoilState(measurementAreaState)
  const lineRef = useRef(null)
  const edit = useRecoilValue(editorSettingsState("edit"))
  const mode = useRecoilValue(editorSettingsState("mode"))
  const showMeasurementArea = useRecoilValue(
    editorSettingsState("showMeasurementArea")
  )

  const VIEW_AREA = showMeasurementArea && mode === VIEW
  const EDIT_AREA = mode === EDIT && edit === MEASUREMENT_AREA

  return (
    <>
      <Line
        ref={lineRef}
        points={measurementAreaToPoints(measurementArea)}
        stroke={"lime"}
        visible={VIEW_AREA || EDIT_AREA}
        onDblClick={() => {
          if (edit !== MEASUREMENT_AREA) return
          // const cursorPosition = lineRef.current.getStage().getPointerPosition()
          const cursorPosition = lineRef.current.getRelativePointerPosition()
          const x = cursorPosition.x
          const y = cursorPosition.y
          const points = lineRef.current.attrs.points
          const tolerance = 100
          for (let i = 0; i < points.length / 2; i++) {
            const s_x = points[i * 2]
            const s_y = points[i * 2 + 1]
            const e_x = points[(i * 2 + 2) % points.length]
            const e_y = points[(i * 2 + 3) % points.length]
            if (pointToLineDistance(x, y, s_x, s_y, e_x, e_y) < tolerance) {
              let newMeasurementArea = [...measurementArea]
              newMeasurementArea.splice(i + 1, 0, { x, y })
              setMeasurementArea(newMeasurementArea)
              break
            }
          }
        }}
        dashEnabled={true}
        dash={[4, 2]}
        strokeWidth={4}
        fill={"rgba(0, 255, 0, 25%)"}
        closed={true}
      />
      {EDIT_AREA &&
        measurementArea.map((coords, index) => (
          <Circle
            onDblClick={() =>
              setMeasurementArea(
                measurementArea.filter(
                  $ => $.x !== coords.x && $.y !== coords.y
                )
              )
            }
            onDragEnd={event => {
              for (let i = 0; i < measurementArea.length; i++) {
                // const line = {
                //   x1: Math.round(measurementArea[i].x),
                //   y1: Math.round(measurementArea[i].y),
                //   x2: Math.round(
                //     measurementArea[(i + 1) % measurementArea.length].x
                //   ),
                //   y2: Math.round(
                //     measurementArea[(i + 1) % measurementArea.length].y
                //   )
                // }

                for (let u = 0; u < measurementArea.length; u++) {
                  // const otherLine = {
                  //   x1: Math.round(measurementArea[u].x),
                  //   y1: Math.round(measurementArea[u].y),
                  //   x2: Math.round(
                  //     measurementArea[(u + 1) % measurementArea.length].x
                  //   ),
                  //   y2: Math.round(
                  //     measurementArea[(u + 1) % measurementArea.length].y
                  //   )
                  // }
                  // console.log(
                  //   intersect(
                  //     line.x1,
                  //     line.y1,
                  //     line.x2,
                  //     line.y2,
                  //     otherLine.x1,
                  //     otherLine.y1,
                  //     otherLine.x2,
                  //     otherLine.y2
                  //   )
                  // )
                }
              }

              let updatedMeasurementArea = [...measurementArea]
              updatedMeasurementArea[index] = {
                x: event.target.x(),
                y: event.target.y()
              }
              setMeasurementArea(updatedMeasurementArea)
            }}
            onDragMove={event => {
              const x = Math.round(event.target.x())
              const y = Math.round(event.target.y())
              let updatedMeasurementArea = [...measurementArea]
              updatedMeasurementArea[index] = {
                x,
                y
              }
              setMeasurementArea(updatedMeasurementArea)

              lineRef.current.points(
                measurementAreaToPoints(updatedMeasurementArea)
              )
              lineRef.current.getLayer().batchDraw()

              // detect intersection
              for (let i = 0; i < measurementArea.length; i++) {
                // console.log(`Point ${i+1}`, measurementArea[i].x, measurementArea[i].y)
                const line = {
                  x1: Math.round(measurementArea[i].x),
                  y1: Math.round(measurementArea[i].y),
                  x2: Math.round(
                    measurementArea[(i + 1) % measurementArea.length].x
                  ),
                  y2: Math.round(
                    measurementArea[(i + 1) % measurementArea.length].y
                  )
                }

                // console.log(
                //   `Line ${i + 1}: ${Math.round(line.x1)}, ${Math.round(
                //     line.y1
                //   )} → ${Math.round(line.x2)}, ${Math.round(line.y2)}`
                // )
                // console.log({
                //   x,
                //   y
                // })

                // console.log(x, y, line.x1, line.x2, line.y1, line.y2)
                if (
                  x === line.x1 ||
                  y === line.y1 ||
                  x === line.x2 ||
                  y === line.y2
                ) {
                  // console.log("neighbor edge:", line)
                  continue
                }
                // if (
                //   Math.abs(x - line.x1) < 50 ||
                //   Math.abs(x - line.x2) < 50 ||
                //   Math.abs(y - line.y1) < 50 ||
                //   Math.abs(y - line.y2) < 50
                // ) {
                //   continue
                // }
                if (
                  pointOnLine(
                    { x: line.x1, y: line.y1 },
                    { x: line.x2, y: line.y2 },
                    {
                      x,
                      y
                    }
                  )
                ) {
                  // console.log("intersection detected")
                }
              }
            }}
            key={index}
            draggable
            fill="white"
            x={coords.x}
            y={coords.y}
            radius={16}
            stroke="black"
            strokeWidth={1}
          />
        ))}
    </>
  )

  // konva expects points as [x1, y1, x2, y2, ...]
  function measurementAreaToPoints(measurementArea) {
    return measurementArea.reduce(
      (points, coordinates) => [...points, coordinates.x, coordinates.y],
      []
    )
  }
}

function pointOnLine(pt1, pt2, pt3) {
  const dx = (pt3.x - pt1.x) / (pt2.x - pt1.x)
  const dy = (pt3.y - pt1.y) / (pt2.y - pt1.y)
  const onLine = Math.abs(dx - dy) < 0.01

  return onLine
}

// function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
//   if (x1 === x3 && y1 === y3 && x2 === x4 && y2 === y4) return false

//   // Check if none of the lines are of length 0
//   if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
//     return false
//   }

//   const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)

//   // Lines are parallel
//   if (denominator === 0) {
//     return false
//   }

//   let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
//   let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

//   // is the intersection along the segments
//   if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
//     return false
//   }

//   // Return a object with the x and y coordinates of the intersection
//   let x = x1 + ua * (x2 - x1)
//   let y = y1 + ua * (y2 - y1)

//   return { x, y }
// }

function pointToLineDistance(x, y, x1, y1, x2, y2) {
  const A = x - x1
  const B = y - y1
  const C = x2 - x1
  const D = y2 - y1

  const dot = A * C + B * D
  const len_sq = C * C + D * D
  const param = dot / len_sq

  let xx, yy

  if (param < 0) {
    xx = x1
    yy = y1
  } else if (param > 1) {
    xx = x2
    yy = y2
  } else {
    xx = x1 + param * C
    yy = y1 + param * D
  }

  const dx = x - xx
  const dy = y - yy

  return Math.sqrt(dx * dx + dy * dy)
}
