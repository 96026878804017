import { Trans } from "@lingui/macro"
import { querySelector } from "common/recoil/selectors"
import { Table } from "react-bootstrap"
import { atom, selector, useRecoilValue } from "recoil"
import ShipmentRow from "./ShipmentRow"

export const shipmentsQuery = selector({
  key: "shipmentsQuery",
  get: ({ get }) => get(querySelector("/shipments"))
})

export const shipmentsState = atom({
  key: "shipmentsState",
  default: shipmentsQuery
})

export default function ShipmentsTable() {
  const shipments = useRecoilValue(shipmentsState)

  return (
    <Table className="rounded-table">
      <thead>
        <tr>
          <th />
          <th>
            <Trans>Shipment number</Trans>
          </th>
          <th>
            <Trans>Recent route</Trans>
          </th>
          <th>
            <Trans>Created at</Trans>
          </th>
          <th>
            <Trans>Logs</Trans>
          </th>
          <th>
            <Trans>Volume</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {shipments.map($ => (
          <ShipmentRow key={$?.id} shipment={$} />
        ))}
      </tbody>
    </Table>
  )
}
