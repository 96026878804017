import { Trans } from "@lingui/macro"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import {
  featureEnabled,
  measurementCustomFieldsSelector,
  measurementsStateFamily
} from "common/recoil/atoms"
import MeasurementCustomField from "./MeasurementCustomField"

export default function MeasurementCustomFields({ measurementId }) {
  const customFields = useRecoilValue(measurementCustomFieldsSelector)
  const measurement = useRecoilValue(measurementsStateFamily(measurementId))
  const enabled = useRecoilValue(featureEnabled("custom_fields"))

  return enabled && customFields.filter($ => !$.archived).length !== 0 ? (
    <div className="p-3 border rounded shadow-sm">
      {customFields
        .filter($ => !$.archived)
        .map($ => {
          const cc = measurement?.custom_fields.find(c => c.key === $.key)

          return (
            <MeasurementCustomField
              key={$.id}
              customField={{ ...$, value: cc?.value }}
            />
          )
        })}
      <div className="text-end">
        <Link to="/custom_fields" className="text-muted">
          <Trans>Edit custom fields</Trans>
        </Link>
      </div>
    </div>
  ) : null
}
