import { capitalize } from "lodash"
import { formatDate } from "common/utils/formatDateTime"
import { Col, Form, Image, Row } from "react-bootstrap"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { woodTypesState } from "common/recoil/atoms"
import { useEffect } from "react"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { containerMeasurementState } from "./ContainerMeasurementRowList"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import {
  isContainerSelectedState,
  selectedContainerMeasurementsState
} from "./Toolbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"

export default function ContainerMeasurementRow(props) {
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedContainerMeasurementsState
  )
  const [measurement, setMeasurement] = useRecoilState(
    containerMeasurementState(props.measurement.id)
  )
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const formatVolume = useVolumeFormatter()
  const selected = useRecoilValue(
    isContainerSelectedState(props.measurement.id)
  )

  useEffect(() => {
    setMeasurement(props.measurement)
  }, [props.measurement, setMeasurement])

  const woodTypes = useRecoilValue(woodTypesState)

  return (
    measurement && (
      <Col xs="auto" className="d-flex m-2 flex-column bg-white border">
        <Row className="g-0 bg-primary bg-gradient">
          <div className=" d-inline-flex align-items-center justify-content-between">
            <div className="small ms-2 text-light">
              {measurement.shown_name}
            </div>
            <div className="me-1">
              {measurement.locked_at && (
                <FontAwesomeIcon
                  size="sm"
                  icon={faLock}
                  className="opacity-50"
                />
              )}
              <Form.Control
                size="sm"
                className="d-inline-flex ms-2"
                style={{ padding: "0 7px", minHeight: "16px" }}
                type="checkbox"
                onChange={() => {
                  if (selected) {
                    setSelectedMeasurements(
                      selectedMeasurements.filter($ => $.id !== measurement.id)
                    )
                  } else {
                    setSelectedMeasurements([
                      ...selectedMeasurements,
                      measurement
                    ])
                  }
                }}
                checked={selected}
              />
            </div>
          </div>
        </Row>
        <Row xs="auto" className="g-0 p-2">
          <Col>
            <Image
              thumbnail
              onClick={() => {
                window.history.pushState("forward", null, "#editor")
                measurementEditor({ id: measurement.id, type: "container" })
              }}
              src={measurement.photo_thumb}
              onError={event =>
                (event.target.src = "/android-chrome-192x192.png")
              }
              // onClick={() => setShowEditor(true)}
              className="me-2 hover-dark"
              style={{
                width: "104px",
                height: "104px",
                cursor: "pointer"
              }}
            />
          </Col>
          <Col className="lh-1 align-self-center">
            <table>
              <tbody>
                <tr className="small">
                  <td className="fw-semibold p-0">Created at</td>
                  <td className="p-0 ps-2">
                    {formatDate(measurement.taken_at, "short")}
                  </td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Wood type</td>
                  <td className="p-0 ps-2">
                    {
                      woodTypes.find(
                        ({ id }) => id === measurement.wood_type_id
                      )?.name
                    }
                  </td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Log count</td>
                  <td className="p-0 ps-2">{measurement.logs.length}</td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Log length</td>
                  <td className="p-0 ps-2">{measurement.log_length} m</td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Volume</td>
                  <td className="p-0 ps-2">
                    {formatVolume(measurement.volume)}
                  </td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Formula</td>
                  <td className="p-0 ps-2">
                    {capitalize(measurement.volume_formula)}
                  </td>
                </tr>
                <tr className="small">
                  <td className="fw-semibold p-0">Coefficient</td>
                  <td className="p-0 ps-2">{measurement.coefficient || "–"}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
    )
  )
}
