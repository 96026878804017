import { Card, Col, Container, Row, Button } from "react-bootstrap"
import {
  atom,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil"
import {
  billsOfLadingQuery,
  billsOfLadingState,
  containersState
} from "common/recoil/atoms"
import Select from "common/other/Select"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import {
  containerMeasurementsQuery,
  containerMeasurementState
} from "./ContainerMeasurementRowList"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { containersByBillOfLadingIdQuery } from "./ContainerRowList"

export const selectedContainerMeasurementsState = atom({
  key: "selectedContainerMeasurements",
  default: []
})

export const isContainerSelectedState = selectorFamily({
  key: "isContainerSelected",
  get:
    id =>
    ({ get }) =>
      get(selectedContainerMeasurementsState).find($ => $.id === id)
        ? true
        : false
})

export default function ContainerToolbar() {
  const fetch = useFetch()
  const containers = useRecoilValue(containersState)
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedContainerMeasurementsState
  )
  const formatVolume = useVolumeFormatter()
  const refreshBillsOfLading = useRecoilRefresher_UNSTABLE(billsOfLadingQuery)

  const refreshContainerMeasurements = useRecoilCallback(
    ({ refresh }) =>
      id =>
        refresh(containerMeasurementsQuery(id))
  )
  const refreshContainersByContainerId = useRecoilCallback(
    ({ snapshot, refresh }) =>
      async containerId => {
        const billsOfLading = await snapshot.getLoadable(billsOfLadingState)
          .contents
        const billOfLading = billsOfLading.find($ =>
          $.containers.includes(containerId)
        )
        refresh(containersByBillOfLadingIdQuery(billOfLading.id))
      }
  )

  const lockMeasurements = useRecoilCallback(
    ({ snapshot, set }) =>
      measurement_ids => {
        measurement_ids.forEach(id => {
          const measurement = snapshot.getLoadable(
            containerMeasurementState(id)
          ).contents
          set(containerMeasurementState(id), {
            ...measurement,
            locked_at: new Date()
          })
        })
      },
    []
  )

  const getMeasurementsContainerIds = useRecoilCallback(
    ({ reset, snapshot, refresh, set }) =>
      measurement_ids =>
        [
          ...new Set(
            measurement_ids.map(id => {
              const measurement = snapshot.getLoadable(
                containerMeasurementState(id)
              ).contents
              return measurement.container_id
            })
          )
        ]
  )

  return (
    <Container
      fluid="lg"
      className="sticky-top"
      style={{
        top: "1rem",
        display: selectedMeasurements.length ? "block" : "none",
        width: "auto"
      }}
    >
      <Card
        bg="primary"
        className="shadow-lg text-white text-center bg-gradient p-3"
      >
        <Row className="mb-2">
          <Col>
            <p className="small">
              {selectedMeasurements.length} container measurements selected
            </p>
            <p className="opacity-50 small">
              {selectedMeasurements.map($ => $.shown_name).join(", ")}
            </p>
            <p className="fw-semibold fs-5">
              {formatVolume(
                selectedMeasurements.reduce(
                  (acc, val) => Number(acc) + Number(val?.volume ?? val.volume),
                  0
                )
              )}
            </p>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="me-2"
              style={{
                display: "inline-block",
                width: "200px"
              }}
            >
              <label className="text-white-50 small">
                <Trans>Move to container</Trans>
              </label>
              <Select
                isSearchable={true}
                placeholder={t`Search containers...`}
                onChange={event => {
                  fetch("/containers/measurements/batch_actions", {
                    method: "PATCH",
                    body: {
                      measurement_ids: selectedMeasurements.map(({ id }) => id),
                      container_id: event.value
                    }
                  }).then(response => {
                    refreshBillsOfLading()
                    refreshContainersByContainerId(event.value)
                    refreshContainersByContainerId(
                      selectedMeasurements[0].container_id
                    )
                    refreshContainerMeasurements(event.value)
                    refreshContainerMeasurements(
                      selectedMeasurements[0].container_id
                    )
                    setSelectedMeasurements([])
                  })
                }}
                options={containers?.map(container => ({
                  value: container.id,
                  label: container.name
                }))}
              />
            </div>
            <Button
              variant="warning"
              className="me-2"
              onClick={() => {
                const ids = selectedMeasurements.map(({ id }) => id)

                if (window.confirm(t`Are you sure?`)) {
                  fetch(`/containers/measurements/batch_actions`, {
                    method: "PATCH",
                    body: {
                      measurement_ids: ids,
                      locked_at: new Date()
                    }
                  }).then(response => {
                    lockMeasurements(ids)
                    setSelectedMeasurements([])
                  })
                }
              }}
            >
              <FontAwesomeIcon icon={faLock} className="me-2" />
              <Trans>Lock</Trans>
            </Button>
            <Button
              variant="outline-light"
              className="me-2"
              onClick={() => setSelectedMeasurements([])}
            >
              <Trans>Deselect</Trans>
            </Button>
            <Button
              variant="danger"
              className="me-2"
              onClick={() => {
                const ids = selectedMeasurements.map(({ id }) => id)

                if (window.confirm(t`Are you sure?`)) {
                  fetch(`/containers/measurements/batch_actions`, {
                    method: "PATCH",
                    body: {
                      measurement_ids: ids,
                      delete: true
                    }
                  })
                    .then(response => {
                      const containerIds = getMeasurementsContainerIds(ids)
                      containerIds.forEach($ =>
                        refreshContainersByContainerId($)
                      )
                      containerIds.forEach($ => refreshContainerMeasurements($))
                      refreshBillsOfLading()
                    })
                    .finally(() => setSelectedMeasurements([]))
                }
              }}
            >
              <Trans>Delete</Trans>
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}
