import { faFileLines } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Card, Col, Container, NavbarBrand, Row, Table } from "react-bootstrap"
import { useParams } from "react-router-dom"

export default function PublicBillOfLading() {
  const { token } = useParams()
  const [billOfLading, setBillOfLading] = useState()
  const [containers, setContainers] = useState([])
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (token) {
      fetch(
        `${process.env.REACT_APP_TIMBETER_API}/containers/bill_of_ladings/public/${token}`
      )
        .then(response => {
          return response.json()
        })
        .then(json => {
          setBillOfLading(json)
          setContainers(json?.containers)
        })
        .catch(() => {
          setNotFound(true)
        })
    }
  }, [token])

  if (!billOfLading && !notFound) {
    return (
      <Container className="p-3">
        <Card body>
          <h1>
            <Trans>Loading...</Trans>
          </h1>
        </Card>
      </Container>
    )
  }

  if (notFound) {
    return (
      <Container className="p-3">
        <Card body>
          <h1>
            <Trans>Bill of lading was not found</Trans>
          </h1>
        </Card>
      </Container>
    )
  }

  return (
    billOfLading && (
      <Container className="p-3 d-flex flex-column gap-3">
        <div className="d-flex">
          <NavbarBrand className="d-flex align-items-center">
            <img
              src="/logos/timbeter.svg"
              className="logo ms-3"
              alt="Timbeter"
            />
          </NavbarBrand>
        </div>
        <Card body>
          <Row>
            <Col xs="auto" className="align-self-center">
              <FontAwesomeIcon icon={faFileLines} size="2x" />
            </Col>
            <Col xs="auto">
              <small className="text-muted">Bill of lading</small>
              <br />
              <span>{billOfLading?.bill_of_lading?.name}</span>
            </Col>
            <Col xs="auto">
              <small className="text-muted">Container count</small>
              <br />
              <span>{billOfLading?.summary?.container_count}</span>
            </Col>
            <Col xs="auto">
              <small className="text-muted">Log count</small>
              <br />
              <span>{billOfLading?.summary?.log_count}</span>
            </Col>
            <Col xs="auto">
              <small className="text-muted">Total volume</small>
              <br />
              <span>{billOfLading?.summary?.volume} m³</span>
            </Col>
          </Row>
        </Card>

        <Table responsive borderless striped>
          <thead>
            <tr>
              <th>
                <small className="text-muted">Container</small>
              </th>
              <th>
                <small className="text-muted">Log count</small>
              </th>
              <th>
                <small className="text-muted">Volume</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {containers.map(container => (
              <tr key={container?.name}>
                <td>
                  {container?.log_count > 0 || container?.volume > 0 ? (
                    <a
                      href={
                        window.location.origin +
                        "/public_container/" +
                        container?.token
                      }
                    >
                      {container?.name}
                    </a>
                  ) : (
                    container?.name
                  )}
                </td>
                {/* <td>
                  <Row>
                    <Col xs="auto">
                      <img
                        alt="container"
                        src={container.photo_url}
                        height="64"
                        width="64"
                      />
                    </Col>
                    <Col className="p-0 align-self-center">
                      <Row>
                        <a
                          href={
                            window.location.origin +
                            "/public_container/" +
                            container.token
                          }
                        >
                          {container.photo_file_name}
                        </a>
                      </Row>
                      <Row>
                        <span className="text-muted">
                          {new Date(container.created_at).toLocaleString()}
                        </span>
                      </Row>
                    </Col>
                  </Row>
                </td> */}
                <td>{container?.log_count}</td>
                <td>{Number(container?.volume || 0).toFixed(2)} m³</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    )
  )
}
