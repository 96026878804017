import { useState, useEffect, Suspense } from "react"
import { Link, useSearchParams } from "react-router-dom"
import {
  faMapMarkerAlt,
  faChartArea,
  faChevronDown,
  faChevronRight,
  faEdit,
  faFileExport,
  faTrashCan,
  faShareNodes,
  faBoxesStacked
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import useFetch from "common/hooks/useFetch"
import {
  storageStatuses,
  storageStatusesColors
} from "common/enums/storageStatuses"
import StorageMarker from "timber-measurement/map/StorageMarker"
import {
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  storagesState,
  showMapState,
  storageExpandedState,
  featureEnabled
} from "common/recoil/atoms"
import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"
import { t } from "@lingui/macro"
import ActiveState from "./ActiveState"
import AddManualMeasurementModal from "timber-measurement/AddManualMeasurementModal"
import storageOptions from "timber-measurement/storageOptions"
import MeasurementRowList, { RowLoading } from "./MeasurementRowList"
import { setStorageMarkers } from "common/recoil/selectors"
import useExportsState from "exports/useExportsState"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
export function handleCopyToClipboard(url, token) {
  navigator.clipboard.writeText(window.location.host + url + token)
  toast(<Trans>Copied to clipboard!</Trans>)
}

export const timberStorageSelector = selectorFamily({
  key: "timberStorage",
  get:
    storageId =>
    ({ get }) =>
      get(storagesState).find($ => $.id === storageId),
  set:
    storageId =>
    ({ set }, updatedStorage) =>
      set(storagesState, storages =>
        storages.map($ =>
          $.id === storageId
            ? {
                ...$,
                ...updatedStorage
              }
            : $
        )
      )
})

export default function TimberStorageRow({ storage }) {
  const fetch = useFetch()
  const [showActiveState, setShowActiveState] = useState(false)
  const [storages, setStorages] = useRecoilState(storagesState)
  const [expanded, setExpanded] = useRecoilState(
    storageExpandedState(storage?.id)
  )
  const formatVolume = useVolumeFormatter()
  const setShowMap = useSetRecoilState(showMapState)
  let storageVolume = 0
  if (storage?.volume?.unit) {
    storageVolume = Number(storage?.volume.scalar)
  } else {
    storageVolume = Number(storage?.volume)
  }
  const storageEmpty = Number(storage?.logs_count) === 0 && storageVolume === 0
  const setMarkers = useSetRecoilState(setStorageMarkers)
  const wendelLogExportExcelEnabled = useRecoilValue(
    featureEnabled("wendel_log_export_excel")
  )
  const redOctoberSummaryExcelEnabled = useRecoilValue(
    featureEnabled("red_october_summary_excel")
  )
  const { createWendelLogReportExcel, createRedOctoberSummaryExcel } =
    useExportsState()
  const [params] = useSearchParams()

  useEffect(() => {
    if (expanded && showActiveState) {
      setShowActiveState(false)
    }
    // eslint-disable-next-line
  }, [expanded])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (Number(params.get("storage")) === storage?.id) {
      const element = document.getElementById(params.get("storage"))
      element && element.scrollIntoView()
      setExpanded(true)
    }
  }, [params, setExpanded, storage?.id])

  return (
    storage && (
      <>
        <tr id={storage.id} className="bg-white">
          <td
            className={`ps-3 ${!storageEmpty && "cursor-pointer"}`}
            onClick={!storageEmpty ? toggleExpand : null}
          >
            {!storageEmpty && (
              <FontAwesomeIcon
                size="lg"
                icon={expanded ? faChevronDown : faChevronRight}
                className="text-primary"
              />
            )}
          </td>
          <td
            className={`${!storageEmpty && "cursor-pointer"}`}
            onClick={!storageEmpty ? toggleExpand : null}
          >
            <div
              className={`${!storageEmpty && "cursor-pointer"} lh-normal`}
              onClick={!storageEmpty ? toggleExpand : null}
            >
              <div
                style={{ maxWidth: "18ch" }}
                className="text-primary hover-dark click-dark fw-semibold text-truncate"
              >
                <OverlayTrigger overlay={<Tooltip>{storage.name}</Tooltip>}>
                  <div className=" text-wrap">{storage.name}</div>
                </OverlayTrigger>
              </div>
              <small className="d-xl-none">{formatVolume(storageVolume)}</small>
            </div>
          </td>
          <td className="text-center p-0 d-none d-xl-table-cell">
            {storage.latitude && storage.longitude ? (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {Number(storage.latitude).toFixed(5)}
                    <br />
                    {Number(storage.longitude).toFixed(5)}
                  </Tooltip>
                }
              >
                <div>
                  <FontAwesomeIcon
                    size="xl"
                    icon={faMapMarkerAlt}
                    className={`text-primary hover-dark cursor-pointer`}
                    onClick={() => {
                      setShowMap(true)
                      setMarkers(
                        storages?.map((s, i) => {
                          return (
                            <StorageMarker
                              key={i}
                              storage={s}
                              shouldOpenPopup={storage.id === s.id}
                            />
                          )
                        })
                      )
                      setTimeout(
                        () =>
                          window.document
                            .getElementById("map")
                            ?.scrollIntoView(),
                        100
                      )
                    }}
                  />
                </div>
              </OverlayTrigger>
            ) : (
              <FontAwesomeIcon
                size="xl"
                icon={faMapMarkerAlt}
                className={`text-secondary`}
              />
            )}
          </td>
          <td className={`${!expanded && "text-body"} d-none d-xl-table-cell`}>
            {new Intl.DateTimeFormat("en-GB", { dateStyle: "medium" }).format(
              new Date(storage.updated_at)
            )}
          </td>
          <td className=" d-none d-xl-table-cell">
            {/* <FontAwesomeIcon
            icon={faCircle}
            className="me-2"
            style={{
              width: "12px",
              color: storageStatusesColors[storage.storage_state_id]
            }}
          />
          {storageStatuses[storage.storage_state_id] || "ENUM_ERROR"} */}
            <Badge
              bg={storageStatusesColors[storage.storage_state_id]}
              text={
                storageStatusesColors[storage.storage_state_id] === "none" &&
                "dark"
              }
            >
              {storageStatuses[storage.storage_state_id] || "ENUM_ERROR"}
            </Badge>
          </td>
          <td className={`${!expanded && "text-body"} d-none d-xl-table-cell`}>
            {storage.logs_count}
          </td>
          <td className={`${!expanded && "text-body"}  d-none d-xl-table-cell`}>
            {formatVolume(storageVolume)}
          </td>
          <td>
            <div style={{ display: "flex" }} className="justify-content-end">
              <FontAwesomeIcon
                onClick={() => {
                  setShowActiveState(!showActiveState)
                  if (!showActiveState) {
                    setExpanded(false)
                  }
                }}
                icon={faChartArea}
                size="lg"
                className={`text-primary cursor-pointer me-2  ${
                  showActiveState
                    ? "p-1 bg-primary text-white rounded"
                    : "p-1 text-inherit"
                }`}
                style={{
                  visibility: storageVolume === 0 && "hidden"
                }}
                disabled
              ></FontAwesomeIcon>
              <Dropdown>
                <Dropdown.Toggle as={storageOptions} />
                <Dropdown.Menu
                  rootCloseEvent="click"
                  style={{ width: "18rem" }}
                >
                  {/* <Link to={`/storage/${storage.id}`} className="dropdown-item">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faShareAlt}
                    className="me-2"
                  />
                  Share
                </Link> */}
                  <Suspense fallback>
                    <AddManualMeasurementModal storage={storage} />
                  </Suspense>
                  <Link
                    className="dropdown-item"
                    to={{
                      pathname: `/storage/${storage.id}`
                    }}
                  >
                    <Trans>Edit</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </Link>
                  <Dropdown.Item
                    onClick={() =>
                      handleCopyToClipboard("/public_storage/", storage.token)
                    }
                  >
                    <Trans>Share</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faShareNodes} />
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={createInventory}>
                    <Trans>Create inventory</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faBoxesStacked} />
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={deleteStorage}>
                    <Trans>Remove</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                  </Dropdown.Item>
                  {(wendelLogExportExcelEnabled ||
                    redOctoberSummaryExcelEnabled) && <Dropdown.Divider />}
                  {wendelLogExportExcelEnabled && (
                    <Dropdown.Item
                      onClick={() => createWendelLogReportExcel(storage.id)}
                    >
                      <Trans>Export</Trans> (Wendel)
                    </Dropdown.Item>
                  )}
                  {redOctoberSummaryExcelEnabled && (
                    <Dropdown.Item
                      onClick={() => createRedOctoberSummaryExcel(storage.id)}
                    >
                      <FontAwesomeIcon icon={faFileExport} className="me-2" />
                      <Trans>Export</Trans> (Red October)
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
        </tr>
        <Suspense fallback>
          {showActiveState && <ActiveState id={storage.id} />}
        </Suspense>
        <Suspense fallback={<RowLoading />}>
          {expanded && <MeasurementRowList storage={storage} />}
        </Suspense>
      </>
    )
  )

  function createInventory() {
    fetch("/inventories", {
      method: "POST",
      body: {
        storage_id: storage.id
      }
    }).then(response => {
      if (response) {
        toast.success(<Trans>Inventory has been created</Trans>)
      }
    })
  }

  function deleteStorage() {
    if (window.confirm(t`Are you sure?`)) {
      fetch(`/storages/${storage.id}`, {
        method: "DELETE"
      })
        .then(() => {
          setStorages(storages.filter($ => $.id !== storage.id))
          toast.success(<Trans>Storage deleted</Trans>)
        })
        .catch(() => {
          toast.error(<Trans>Failed to delete storage</Trans>)
        })
    }
  }
}
