import { Trans } from "@lingui/macro"
import {
  logsRangeState,
  measurementAreaState,
  measurementEditorLoadsState,
  referencesState
} from "common/recoil/atoms"
import {
  diameterUnitSelector,
  longestDiameterLogState,
  shortestDiameterLogState
} from "common/recoil/selectors"
import { useEffect, useState } from "react"
import { Col, Form } from "react-bootstrap"
import { Range, getTrackBackground } from "react-range"
import { useRecoilState, useRecoilValue } from "recoil"
import { editorSettingsState, planksState } from "."
import { logsInRange } from "./Logs"
import { logsState, measurementEditorState } from "./useEditorState"

export default function TogglesPane() {
  // const { id: measurementId, type: measurementType } = useRecoilValue(
  //   measurementEditorState
  // )
  // const measurement = useRecoilValue(
  //   measurementByIdAndTypeQuery({
  //     id: measurementId,
  //     type: measurementType
  //   })
  // )
  const logs = useRecoilValue(logsInRange)
  const logsAll = useRecoilValue(logsState)
  const logsTotalVolume = logsAll.reduce((acc, val) => acc + val.v, 0)
  const logsVolume = logs.filter($ => $).reduce((acc, val) => acc + val.v, 0)
  const references = useRecoilValue(referencesState)
  const [showReference, setShowReference] = useRecoilState(
    editorSettingsState("showReference")
  )
  const [showCentroids, setShowCentroids] = useRecoilState(
    editorSettingsState("showCentroids")
  )
  const [showOutlines, setShowOutlines] = useRecoilState(
    editorSettingsState("showOutlines")
  )
  const [showDiameters, setShowDiameters] = useRecoilState(
    editorSettingsState("showDiameters")
  )
  const [showLoadLines, setShowLoadLines] = useRecoilState(
    editorSettingsState("showLoadLines")
  )
  const loads = useRecoilValue(measurementEditorLoadsState)
  const planks = useRecoilValue(planksState)
  const measurementArea = useRecoilValue(measurementAreaState)
  const [showMeasurementArea, setShowMeasurementArea] = useRecoilState(
    editorSettingsState("showMeasurementArea")
  )
  const longestDiameterLog = useRecoilValue(longestDiameterLogState)
  const shortestDiameterLog = useRecoilValue(shortestDiameterLogState)
  const [thumbs, setThumbs] = useRecoilState(logsRangeState)
  const [rangeThumbs, setRangeThumbs] = useState([])
  const diameterUnit = useRecoilValue(diameterUnitSelector)
  const { type } = useRecoilValue(measurementEditorState)

  useEffect(() => {
    if (thumbs) {
      setRangeThumbs(thumbs)
    }
  }, [thumbs])

  return (
    <>
      <Col xs="auto" className="px-3 py-2 small">
        {references?.length ? (
          <Form.Check
            checked={showReference}
            onChange={() => setShowReference(!showReference)}
            type="checkbox"
            label={<Trans>Show reference lines</Trans>}
          />
        ) : null}
        {(type === "timber" ||
          type === "container" ||
          type === "truck_diameter") &&
        logs?.length > 0 ? (
          <Form.Check
            checked={showDiameters}
            onChange={() => setShowDiameters(!showDiameters)}
            type="checkbox"
            label={<Trans>Show log diameters</Trans>}
          />
        ) : null}
        {loads?.length ? (
          <Form.Check
            checked={showLoadLines}
            onChange={() => setShowLoadLines(!showLoadLines)}
            type="checkbox"
            label={<Trans>Show load lines</Trans>}
          />
        ) : null}
        {planks?.length ? (
          <Form.Check
            checked={showCentroids}
            onChange={() => setShowCentroids(!showCentroids)}
            type="checkbox"
            label={<Trans>Show centroids</Trans>}
          />
        ) : null}
        {planks?.length ? (
          <Form.Check
            checked={showOutlines}
            onChange={() => setShowOutlines(!showOutlines)}
            type="checkbox"
            label={<Trans>Show outlines</Trans>}
          />
        ) : null}
        {measurementArea?.length ? (
          <Form.Check
            checked={showMeasurementArea}
            onChange={() => setShowMeasurementArea(!showMeasurementArea)}
            type="checkbox"
            label="Show measurement area"
          />
        ) : null}
      </Col>
      {(type === "timber" ||
        type === "container" ||
        type === "truck_diameter") && (
        <>
          {logs?.length > 0 &&
            shortestDiameterLog !== longestDiameterLog &&
            shortestDiameterLog < longestDiameterLog &&
            shortestDiameterLog <= rangeThumbs[0] &&
            longestDiameterLog >= rangeThumbs[1] && (
              <Col className="px-3 py-2" xs="auto">
                <div>
                  <div
                    className="text-white-50 small text-center"
                    style={{ marginBottom: "-10px" }}
                  >
                    {!isNaN(
                      Math.round((logsVolume / logsTotalVolume) * 100)
                    ) ? (
                      `${Math.round((logsVolume / logsTotalVolume) * 100)}%`
                    ) : (
                      <br />
                    )}
                  </div>
                  <Range
                    step={0.1}
                    min={shortestDiameterLog}
                    max={longestDiameterLog}
                    values={rangeThumbs}
                    onChange={setRangeThumbs}
                    onFinalChange={setThumbs}
                    renderTrack={({ props, children }) => (
                      <div
                        style={{
                          ...props.style,
                          height: "24px",
                          display: "flex",
                          width: "8rem"
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "4px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values: rangeThumbs,
                              colors: ["#ccc", "#0d6efd", "#ccc"],
                              min: shortestDiameterLog,
                              max: longestDiameterLog
                            }),
                            alignSelf: "center"
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ index, props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "16px",
                          width: "6px",
                          display: "flex",
                          background: "#0d6efd",
                          borderRadius: "2px",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "24px",
                            color: "white",
                            fontSize: "12px",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {Number(rangeThumbs[index]).toFixed(1)}{" "}
                          {type === "container" ? "cm" : diameterUnit}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </Col>
            )}
        </>
      )}
    </>
  )
}
