import { useEffect, useState } from "react"
import { Col, Container, NavbarBrand, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Location from "./Location"
import Timber from "./Timber"
import Logs from "./Logs"
import LogsDiameters from "./LogsDiameters"

export default function PublicMeasurement() {
  const { token } = useParams()
  const [measurement, setMeasurement] = useState()

  useEffect(() => {
    if (token) {
      fetch(
        `${process.env.REACT_APP_TIMBETER_API}/measurements/public/${token}`
      )
        .then(response => {
          return response.json()
        })
        .then(json => {
          setMeasurement(json.measurement)
        })
    }
  }, [token])

  return measurement ? (
    <Container className="mb-3">
      <div className="d-flex py-3">
        <NavbarBrand className="d-flex align-items-center">
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
        </NavbarBrand>
      </div>
      <Row>
        <Col xs={12} md={6}>
          <Location
            latitude={measurement.latitude}
            longitude={measurement.longitude}
            storage={measurement.storage}
          />
          <LogsDiameters logsSheet={measurement?.logs_sheet} />
        </Col>
        <Col xs={12} md={6}>
          <Timber measurement={measurement} token={token} />
          {measurement?.photo_url.includes("missing.png") ? (
            <div className="rounded bg-light text-muted border px-2">
              No photo
            </div>
          ) : (
            <Logs
              imgUrl={measurement.photo_url}
              logs={measurement.logs}
              reference={measurement?.references[0]}
              measurementArea={measurement?.measurement_area}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={10}></Col>
      </Row>
    </Container>
  ) : null
}
