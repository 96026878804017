import { useEffect, useRef } from "react"
import { Col, Row } from "react-bootstrap"
import { MapContainer, TileLayer } from "react-leaflet"
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilValueLoadable
} from "recoil"
import {
  markersState,
  showMapState,
  storagesState,
  woodTypesState
} from "common/recoil/atoms"
import { setStorageMarkers } from "common/recoil/selectors"
import StorageMarker from "./StorageMarker"
import MarkerClusterGroup from "react-leaflet-markercluster"

export default function Map() {
  const show = useRecoilValue(showMapState)
  useRecoilValueLoadable(woodTypesState)
  const markers = useRecoilValue(markersState)
  const setMarkers = useSetRecoilState(setStorageMarkers)
  const storages = useRecoilValue(storagesState)
  const clusterGroupRef = useRef(null)

  useEffect(() => {
    setMarkers(
      storages?.map(
        (storage, index) =>
          storage.latitude &&
          storage.longitude && <StorageMarker key={index} storage={storage} />
      )
    )
  }, [storages, setMarkers])

  useEffect(() => {
    const handleUnspiderfy = event => {
      try {
        // event.cluster.spiderfy()
        // event?.markers
        //   ?.find(marker => marker?.options?.name === clickedMarker)
        //   .openPopup()
      } catch (error) {
        console.log("error opening popup", error)
      }
    }
    const clustgroup = clusterGroupRef?.current

    if (clustgroup) {
      // console.log(clustgroup)
      clustgroup.on("unspiderfied", handleUnspiderfy)
    }

    return () => {
      clustgroup?.off("unspiderfied", handleUnspiderfy)
    }
  }, [markers])

  useEffect(() => window.dispatchEvent(new Event("resize"), []))

  return (
    show && (
      <>
        <Row className="mt-3">
          <Col
            id="map"
            style={{
              display: show ? "block" : "none"
            }}
          >
            <MapContainer
              minZoom={1}
              bounds={
                storages.filter(s => s.latitude && s.longitude)?.length > 1
                  ? storages.map(s => [s.latitude, s.longitude])
                  : [
                      [-45, -89],
                      [45, 90]
                    ]
              }
              scrollWheelZoom={true}
              style={{ height: "480px", zIndex: 0 }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <MarkerClusterGroup
                ref={clusterGroupRef}
                disableClusteringAtZoom={18}
                chunkedLoading={true}
                spiderfyOnMaxZoom={false}
              >
                {markers}
              </MarkerClusterGroup>
            </MapContainer>
          </Col>
        </Row>
        <hr className="text-light" />
      </>
    )
  )
}
