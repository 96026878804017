import { EDIT, PLANKS } from "common/enums/constants"
import { useEffect, useRef, useState } from "react"
import { Circle, Group, Rect, Transformer } from "react-konva"
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { editorSettingsState, planksState } from "."

export const selectedPlankState = atom({
  key: "selectedPlank",
  default: null
})

export default function Plank({ plank, index }) {
  const showCentroids = useRecoilValue(editorSettingsState("showCentroids"))
  const showOutlines = useRecoilValue(editorSettingsState("showOutlines"))
  const [x, setX] = useState(Number(plank.x))
  const [y, setY] = useState(Number(plank.y))
  const [width] = useState(Number(plank.width))
  const [height] = useState(Number(plank.height))
  const [centroidX, setCentroidX] = useState(x + width / 2)
  const [centroidY, setCentroidY] = useState(y + height / 2)
  const mode = useRecoilValue(editorSettingsState("mode"))
  const edit = useRecoilValue(editorSettingsState("edit"))
  const [selectedPlank, setSelectedPlank] = useRecoilState(selectedPlankState)
  const transformerRef = useRef(null)
  const rectRef = useRef(null)
  const [isDeleted, setIsDeleted] = useState(plank?.isDeleted)
  const setPlanks = useSetRecoilState(planksState)

  useEffect(() => {
    setCentroidX(x + (width * rectRef?.current.scaleX()) / 2)
    setCentroidY(y + (height * rectRef?.current.scaleY()) / 2)
  }, [x, y, height, width])

  useEffect(() => {
    if (mode === EDIT && index === selectedPlank) {
      transformerRef.current.nodes([rectRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
    // eslint-disable-next-line
  }, [selectedPlank, mode])

  return (
    <Group
      onClick={() => {
        if (mode === EDIT && edit === PLANKS) {
          setSelectedPlank(index)
        }
      }}
      onDblClick={edit === PLANKS && removePlank}
      visible={!isDeleted}
    >
      <Rect
        onDragEnd={updatePlank}
        onDragMove={movePlank}
        draggable={index === selectedPlank}
        ref={rectRef}
        x={x}
        visible={showOutlines}
        y={y}
        width={width}
        height={height}
        stroke="blue"
        fill={index === selectedPlank ? "rgba(0, 255, 0, 0.25)" : "transparent"}
        strokeWidth={1}
        strokeScaleEnabled={false}
        onTransform={transformPlank}
        onTransformEnd={updatePlank}
      />
      {mode === EDIT && index === selectedPlank && (
        <Transformer
          ref={transformerRef}
          flipEnabled={false}
          anchorSize={8}
          anchorCornerRadius={8}
          anchorStroke="black"
          borderStrokeWidth={0}
          ignoreStroke={true}
          rotateEnabled={false}
          keepRatio={false}
          centeredScaling={false}
          shouldOverdrawWholeArea={true}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right"
          ]}
        />
      )}
      <Circle
        x={centroidX}
        y={centroidY}
        stroke={"red"}
        strokeWidth={1}
        radius={3}
        visible={showCentroids}
        fill={"red"}
      />
    </Group>
  )

  function removePlank() {
    setPlanks(planks =>
      planks.map(($, i) => {
        if (i !== index) return $
        return {
          ...$,
          isDeleted: true
        }
      })
    )
    setIsDeleted(true)
    setSelectedPlank(null)
  }

  function updatePlank() {
    setPlanks(planks =>
      planks.map(($, i) => {
        if (i !== index) return $
        return {
          ...$,
          x,
          y,
          width: width * rectRef?.current.scaleX(),
          height: height * rectRef?.current.scaleY()
        }
      })
    )
  }

  function transformPlank() {
    setCentroidX(rectRef?.current.x() + (width * rectRef?.current.scaleX()) / 2)
    setCentroidY(
      rectRef?.current.y() + (height * rectRef?.current.scaleY()) / 2
    )
  }

  function movePlank() {
    setX(rectRef?.current.x())
    setY(rectRef?.current.y())
  }
}
