import {
  faCaretDown,
  faCaretUp,
  faCloudArrowDown,
  faExclamationTriangle,
  faFileArrowDown,
  faSearch,
  faSort,
  faTimesCircle,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { useMemo, useState } from "react"
import { Badge, Col, Row, Spinner, Table } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import { usersState } from "common/recoil/atoms"
import { excelExportsState, pdfExportsState } from "./useExportsState"
import { toast } from "react-toastify"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"

export default function ExportsRoute() {
  const [excelExports, setExcelExports] = useRecoilState(excelExportsState)
  const [pdfExports, setPdfExports] = useRecoilState(pdfExportsState)
  const statuses = [
    {
      value: 0,
      label: "In progress",
      component: () => (
        <Spinner
          title={t`Generating export...`}
          variant="info"
          as="span"
          size="sm"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )
    },
    {
      value: 1,
      label: "Done",
      component: reportURL => (
        <a href={reportURL} download title={t`Ready for download`}>
          <FontAwesomeIcon
            icon={faCloudArrowDown}
            size="lg"
            className="text-primary cursor-pointer hover-dark click-dark"
          />
        </a>
      )
    },
    {
      value: 2,
      label: "Partially done",
      component: () => (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="lg"
          className="text-warning"
        />
      )
    },
    {
      value: 3,
      label: "Failed",
      component: () => (
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="lg"
          className="text-danger"
        />
      )
    }
  ]

  return (
    <>
      <h4 className="text-light">
        <FontAwesomeIcon icon={faFileArrowDown} className="me-2" />
        <Trans>Exports</Trans>
      </h4>
      <hr className="text-light" />
      <Row>
        <Col xs="12">
          <ExportsTable />
        </Col>
      </Row>
    </>
  )

  function ExportsTable() {
    const users = useRecoilValue(usersState)
    const [sorting, setSorting] = useState()
    const [globalFilter, setGlobalFilter] = useState("")
    const columnHelper = createColumnHelper()
    const columns = useMemo(
      () => [
        columnHelper.accessor("name", {
          header: <Trans>Name</Trans>,
          cell: info => (
            <span>
              {info.getValue() ? info.getValue() : "-"}{" "}
              {new Date(info.row.original.created_at) > Date.now() - 60000 && (
                <Badge className="ms-1" bg="info">
                  New
                </Badge>
              )}
            </span>
          ),
          footer: info => info.column.id
        }),
        columnHelper.accessor("user_id", {
          header: <Trans>User</Trans>,
          filterFn: "creatorFilter",
          cell: info =>
            users?.find($ => $.id === info.getValue())?.email || "-",
          footer: info => info.column.id
        }),
        columnHelper.accessor("status", {
          header: <Trans>Status</Trans>,
          cell: info =>
            statuses[info?.getValue()]?.component(info.row.original?.url) ? statuses[info?.getValue()]?.component(info.row.original?.url) : null,
          footer: info => info.column.id
        }),
        columnHelper.accessor("created_at", {
          header: <Trans>Created at</Trans>,
          cell: info => (
            <>
              {new Date(info.getValue()).toLocaleString()}
              <FontAwesomeIcon
                className="cursor-pointer float-end text-secondary hover-dark click-dark ms-2"
                icon={faTrashCan}
                onClick={() => {
                  if (window.confirm(t`Are you sure?`)) {
                    if (info.row.original?.report_type?.includes("excel")) {
                      fetch("/excel_exports/" + info.row.original.id, {
                        method: "DELETE"
                      }).then(response => {
                        if (response) {
                          setExcelExports(
                            excelExports.filter(
                              $ => $.id !== info.row.original.id
                            )
                          )
                          toast.success(<Trans>Deleted</Trans>)
                        }
                      })
                    } else if (
                      info.row.original?.report_type?.includes("pdf")
                    ) {
                      fetch("/pdf_exports/" + info.row.original.id, {
                        method: "DELETE"
                      }).then(response => {
                        if (response) {
                          setPdfExports(
                            pdfExports.filter(
                              $ => $.id !== info.row.original.id
                            )
                          )
                          toast.success(<Trans>Deleted</Trans>)
                        }
                      })
                    }
                  }
                }}
              />
            </>
          ),
          footer: info => info.column.id
        })
      ],
      [columnHelper, users]
    )

    const exports = [...excelExports, ...pdfExports].sort((a, b) => {
      return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
    })
    // .filter($ => {
    //   if (!filterType.length) return true
    //   return (
    //     $.report_type && filterType.map($ => $.value).includes($.report_type)
    //   )
    // })
    // .filter($ => {
    //   if (!filterUser.length) return true
    //   return $.user_id && filterUser.map($ => $.value).includes($.user_id)
    // })
    // .filter($ => {
    //   if (!filterStatus.length) return true
    //   return (
    //     $.status !== null && filterStatus.map($ => $.value).includes($.status)
    //   )
    // })
    // .map(($, i) => (
    //   <ExportRow
    //     key={i}
    //     data={$}
    //     isPDF={$.report_type?.includes("pdf")}
    //     isExcel={$.report_type?.includes("excel")}
    //   />
    // ))

    const table = useReactTable({
      data: exports,
      columns: columns,
      getCoreRowModel: getCoreRowModel(),
      state: {
        sorting,
        globalFilter
      },
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      filterFns: {
        global: globalFilter
      },
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: row => row?.original?.name?.includes(globalFilter)
    })
    return (
      // <Table responsive>
      //   <thead>
      //     <tr>
      //       <th>
      //         <Trans>Exports</Trans>
      //         <small className="float-end text-black-50">
      //           {exports?.length} <Trans>exports found</Trans>
      //         </small>
      //       </th>
      //     </tr>
      //   </thead>
      //   <tbody>
      //     {exports}
      //     {!excelExports?.length && !pdfExports?.length && (
      //       <tr>
      //         <td colSpan="5" className="text-center">
      //           <div className="my-3">
      //             <FontAwesomeIcon
      //               icon={faFileExcel}
      //               size="3x"
      //               className="opacity-25 mb-2"
      //             />
      //             <p className="h5 fw-semibold text-muted">
      //               <Trans>No exports found</Trans>
      //             </p>
      //             <p className="py-2 text-muted">
      //               <Trans>
      //                 You can generate Excel and PDF exports from the dashboard.
      //               </Trans>
      //             </p>
      //           </div>
      //         </td>
      //       </tr>
      //     )}
      //   </tbody>
      // </Table>
      <Table hover responsive>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  <div
                    {...{
                      style: header.column.getCanSort()
                        ? {
                            cursor: "pointer",
                            userSelect: "none"
                          }
                        : null,
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          className="ms-2 opacity-50"
                        />
                      ),
                      desc: (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="ms-2 opacity-50"
                        />
                      )
                    }[header.column.getIsSorted()] ?? (
                      <FontAwesomeIcon
                        icon={faSort}
                        className="ms-2 opacity-50"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr className="text-center text-black-50">
              <td colSpan="5" className="py-3">
                <FontAwesomeIcon
                  icon={faSearch}
                  size="2x"
                  className="mb-2 opacity-50"
                />
                <p>
                  <Trans>No results found for</Trans> "{globalFilter}"
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}
