import { Trans } from "@lingui/macro"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import {
  lengthUnitSelector,
  measurementByIdAndTypeQuery,
  referenceUnitSelector
} from "common/recoil/selectors"
import { capitalize } from "lodash"
import { Col } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { logsInRange } from "./Logs"
import { measurementEditorState } from "./useEditorState"

export default function MetricsPanel() {
  const { id, type: measurementType } = useRecoilValue(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({ id, type: measurementType })
  )
  const formatVolume = useVolumeFormatter()
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const referenceUnit = useRecoilValue(referenceUnitSelector)
  const { type } = useRecoilValue(measurementEditorState)
  const logs = useRecoilValue(logsInRange)

  return (
    <Col xs="auto" className="py-2">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="text-white-50 small px-2">
              <Trans>Volume</Trans>
            </td>
            <td>{formatVolume(measurement.volume)}</td>
            <td className="text-white-50 small px-2">
              <Trans>Length</Trans>
            </td>
            <td>
              {measurement.log_length
                ? measurement.log_length +
                  " " +
                  (type === "container" ? "m" : lengthUnit)
                : "–"}
            </td>
            {type !== "truck" && (
              <>
                <td className="text-white-50 small px-2">
                  <Trans>Formula</Trans>
                </td>
                <td>{capitalize(measurement.volume_formula)}</td>
              </>
            )}
          </tr>
          <tr>
            {type !== "truck" && (
              <>
                <td className="text-white-50 small px-2">
                  <Trans>Count</Trans>
                </td>
                <td>
                  {logs?.filter($ => $).length ||
                    measurement?.planks?.length ||
                    "–"}
                </td>
              </>
            )}
            <td className="text-white-50 small px-2">
              <Trans>Reference</Trans>
            </td>
            <td>
              {measurement.references?.length
                ? measurement.references[0]?.size + " " + referenceUnit
                : "–"}
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  )
}
