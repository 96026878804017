import { Trans, t } from "@lingui/macro"
import Loading from "common/other/Loading"
import {
  measurementsModeState,
  timberFilterState,
  truckFilterState
} from "common/recoil/atoms"
import { Suspense, useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  subscriptionExistsSelector,
  subscriptionStatusSelector
} from "billing/TimbeterPro"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import { Alert, Button } from "react-bootstrap"
import { capitalize } from "lodash"
import { lastDayMeasurementsSelector } from "common/recoil/selectors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faCube,
  faInfoCircle,
  faTableCells,
  faTruckMoving
} from "@fortawesome/free-solid-svg-icons"
import LogsIcon from "./LogsIcon"

export default function Measurements() {
  const module = useRecoilValue(measurementsModeState)
  const navigate = useNavigate()
  const location = useLocation()
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const subscriptionStatus = useRecoilValue(subscriptionStatusSelector)
  const organization = useRecoilValue(currentOrganizationState)
  const lastDayMeasurements = useRecoilValue(lastDayMeasurementsSelector)
  const [timberFilterFrom, setTimberFilterFrom] = useRecoilState(
    timberFilterState("from")
  )
  const [truckFilterFrom, setTruckFilterFrom] = useRecoilState(
    truckFilterState("from")
  )
  useEffect(() => {
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)
    if (lastDayMeasurements && timberFilterFrom === null) {
      setTimberFilterFrom(today)
    }
    if (lastDayMeasurements && truckFilterFrom === null) {
      setTruckFilterFrom(today)
    }
    if (location.pathname.split("/").filter($ => $).length === 1) {
      navigate(module, { replace: true })
    }
    // eslint-disable-next-line
  }, [location, navigate, module, lastDayMeasurements, setTimberFilterFrom])
  return (
    <div>
      {subscriptionExists ? (
        <div>
          {subscriptionStatus === "active" && (
            <Alert
              className="py-1 d-inline-block w-auto px-3 me-3 cursor-pointer"
              onClick={() => navigate("/billing")}
              variant="success"
            >
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              <Trans>You have an active subscription</Trans>
            </Alert>
          )}
          {subscriptionStatus === "trialing" && (
            <Alert
              className="py-1 d-inline-block px-3 cursor-pointer"
              onClick={() => navigate("/billing")}
              variant="info"
            >
              <Trans>You have a trial subscription</Trans>
            </Alert>
          )}
          {subscriptionStatus === "canceled" && (
            <Alert
              className="py-1 d-inline-block px-3 cursor-pointer"
              onClick={() => navigate("/billing")}
              variant="secondary"
            >
              <Trans>Your subscription is canceled</Trans>
            </Alert>
          )}
        </div>
      ) : (
        <Alert
          className="py-1 px-3"
          variant={organization?.remaining_measurements < 1 ? "danger" : "info"}
        >
          {organization.remaining_measurements ?? 0}{" "}
          <Trans>remaining measurements</Trans>{" "}
          <Button
            variant="dark"
            onClick={() => navigate("/billing/measurements")}
            size="sm"
            className="py-0 rounded-pill fw-medium ms-2"
          >
            <Trans>Buy more</Trans>
          </Button>
        </Alert>
      )}
      {organization?.last_day_measurements ? (
        <Alert className="py-1 d-inline-block me-3" variant="secondary">
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          <Trans>Last day measurements activated</Trans>
        </Alert>
      ) : null}
      <h4 className="text-light mb-0">
        {module === "timber" && <LogsIcon fill="white" width="24" />}
        {module === "container" && (
          <FontAwesomeIcon icon={faCube} className="me-2" />
        )}
        {module === "truck" && (
          <FontAwesomeIcon icon={faTruckMoving} className="me-2" />
        )}
        {module === "lumber" && (
          <FontAwesomeIcon icon={faTableCells} className="me-2" />
        )}
        {capitalize(module)} <Trans>measurements</Trans>
      </h4>
      <hr className="text-light" />
      <Suspense fallback={<Loading text={t`Loading storages`} />}>
        <Outlet />
      </Suspense>
    </div>
  )
}
