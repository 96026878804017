import { useCallback, useEffect, useRef, useState } from "react"
import { Circle, Text, Group, Line } from "react-konva"
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  logsState,
  measurementEditorState,
  selectedLogState
} from "./useEditorState"
import { diameterUnitSelector } from "common/recoil/selectors"
import { referenceInCmSelector, referenceInPxSelector } from "./Reference"
import { editorSettingsState } from "."

export const selectedLogQrCodeState = selector({
  key: "selectedLogQrCode",
  get: ({ get }) => {
    const log = get(logsState)?.find(($, i) => i === get(selectedLogState))
    return log?.qr_code ? log.qr_code : ""
  },
  set: ({ get, set }, qrCode) => {
    set(
      logsState,
      get(logsState).map(($, i) => {
        if (i !== get(selectedLogState)) return $
        return {
          ...$,
          qr_code: qrCode
        }
      })
    )
  }
})

export const selectedLogAssortmentState = selector({
  key: "selectedLogAssortment",
  get: ({ get }) => {
    const log = get(logsState)?.find(($, i) => i === get(selectedLogState))
    return log?.wood_characteristic_id ? log.wood_characteristic_id : ""
  },
  set: ({ get, set }, assortmentId) => {
    set(
      logsState,
      get(logsState).map(($, i) => {
        if (i !== get(selectedLogState)) return $
        return {
          ...$,
          wood_characteristic_id: assortmentId
        }
      })
    )
  }
})

export const selectedLogCullState = selector({
  key: "selectedLogCull",
  get: ({ get }) => {
    const log = get(logsState)?.find(($, i) => i === get(selectedLogState))
    return log?.cull_id ? log.cull_id : ""
  },
  set: ({ get, set }, cullId) => {
    set(
      logsState,
      get(logsState).map(($, i) => {
        if (i !== get(selectedLogState)) return $
        return {
          ...$,
          cull_id: cullId
        }
      })
    )
  }
})

function calculateDiameter(radius, referenceInPixels, referenceInCm) {
  // const newDiameter = +(
  //   Math.round((radius * 2) / (referenceInPixels / referenceInCm) + "e+2") +
  //   "e-2"
  // )
  // const newDiameter = Math.round(
  //   (radius * 2) / (referenceInPixels / referenceInCm)
  // )
  const newDiameter = roundToOneDecimalPlace(
    (radius * 2) / (referenceInPixels / referenceInCm)
  )
  return newDiameter
}

function Log({ log, index }) {
  const circleRef = useRef(null)
  const mode = useRecoilValue(editorSettingsState("mode"))
  const setLogs = useSetRecoilState(logsState)
  const [selected, setSelected] = useRecoilState(selectedLogState)
  // const setSelected = useSetRecoilState(selectedLogState)
  const [x, setX] = useState(log.x)
  const [y, setY] = useState(log.y)
  const [diameter, setDiameter] = useState(Number(log.d))
  const [radius, setRadius] = useState(Number(log.r))
  const [isDeleted, setIsDeleted] = useState(log.isDeleted)
  const [qrCode, setQrCode] = useState(log?.qr_code)
  const [containerLog] = useState(log.qr_code !== undefined)
  const diameterUnit = useRecoilValue(diameterUnitSelector)
  const { type } = useRecoilValue(measurementEditorState)
  const referenceInPixels = useRecoilValue(referenceInPxSelector)
  const referenceInCm = useRecoilValue(referenceInCmSelector)
  // console.log("initial", parseFloat(Number(log.d).toFixed(1)))
  // console.log(
  //   "initial2",
  //   referenceInPixels && referenceInCm
  //     ? (log.r * 2) / (referenceInPixels / referenceInCm)
  //     : "-"
  // )
  const updateCircle = useCallback(
    e => {
      const newRadius =
        (circleRef.current.width() / 2) * circleRef.current.scaleX()
      const newDiameter = calculateDiameter(
        newRadius,
        referenceInPixels,
        referenceInCm
      )
      const newX = circleRef?.current.x()
      const newY = circleRef?.current.y()

      setDiameter(newDiameter)
      setX(newX)
      setY(newY)
      setRadius(newRadius)
      setLogs(prevLogs =>
        prevLogs.map((logItem, idx) => {
          if (idx !== index) return logItem
          return {
            ...logItem,
            x: newX || x,
            y: newY || y,
            d: Number(newDiameter),
            r:
              diameterUnit === "in" && type !== "container"
                ? newRadius / 2.54
                : newRadius
          }
        })
      )
    },
    [
      circleRef,
      referenceInPixels,
      referenceInCm,
      x,
      y,
      setLogs,
      index,
      diameterUnit,
      type
    ]
  )

  useEffect(() => {
    setQrCode(log?.qr_code)
    setIsDeleted(log.isDeleted)
  }, [log])

  function deleteCircle() {
    setLogs(logs =>
      logs.map(($, i) => {
        if (i !== index) return $
        return {
          ...$,
          isDeleted: true
        }
      })
    )
    setIsDeleted(true)
    setSelected(null)
  }

  useEffect(() => {
    if (referenceInPixels && referenceInCm) {
      // const newRadius =
      //   (circleRef.current.width() / 2) * circleRef.current.scaleX()
      const newDiameter = roundToTwoDecimalPlaces(
        (radius * 2) / (referenceInPixels / referenceInCm)
      )

      // const newDiameter = +(
      //   Math.round(
      //     (newRadius * 2) / (referenceInPixels / referenceInCm) + "e+2"
      //   ) + "e-2"
      // )

      // const newDiameter = Math.round(
      //   (newRadius * 2) / (referenceInPixels / referenceInCm)
      // )
      setDiameter(newDiameter)
    }
  }, [referenceInPixels, referenceInCm, radius])

  const handleTransform = useCallback(
    e => {
      const newRadius =
        (circleRef.current.width() / 2) * circleRef.current.scaleX()
      const newDiameter = calculateDiameter(
        newRadius,
        referenceInPixels,
        referenceInCm
      )
      setDiameter(newDiameter)
    },
    [circleRef, referenceInPixels, referenceInCm]
  )

  const isViewMode = diameter > 0 && mode === "VIEW"
  const editSelected = mode === "EDIT" && selected === index

  return (
    <Group id={"group" + index} onDblClick={deleteCircle} visible={!isDeleted}>
      {(isViewMode || editSelected) && (
        <Text
          listening={false}
          x={radius < 85 ? x - radius * 0.6 : x - radius * 0.7}
          y={y - radius * 0.25}
          fillAfterStrokeEnabled="true"
          stroke="black"
          strokeWidth={radius * 0.05}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          fontSize={radius * 0.7}
          fill="white"
          text={roundToOneDecimalPlace(diameter)}
        />
      )}
      <Circle
        draggable
        onDragEnd={updateCircle}
        ref={circleRef}
        id={index.toString()}
        x={x}
        y={y}
        // fill={selected === index ? "rgba(0, 0, 255, 0.25)" : "transparent"}
        radius={
          diameterUnit === "in" && type !== "container" ? radius * 2.54 : radius
        }
        onTransform={handleTransform}
        onTransformEnd={updateCircle}
        stroke={containerLog ? (qrCode ? "dodgerblue" : "lime") : "lime"}
        strokeWidth={2}
        strokeScaleEnabled={false}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
      />
      {log?.cull_id && (
        <>
          <Line
            points={[
              Number(x - log.r),
              Number(y - log.r),
              Number(x + log.r),
              Number(y + log.r)
            ]}
            stroke={"lime"}
            strokeWidth={4}
          />
          <Line
            points={[
              Number(x + log.r),
              Number(y - log.r),
              Number(x - log.r),
              Number(y + log.r)
            ]}
            stroke={"lime"}
            strokeWidth={4}
          />
        </>
      )}
    </Group>
  )
}

export default Log

function roundToOneDecimalPlace(num) {
  return num ? parseFloat(num.toFixed(1)) : num
}

function roundToTwoDecimalPlaces(num) {
  return num ? parseFloat(num.toFixed(2)) : num
}
