import {
  faDownLeftAndUpRightToCenter,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { debounce } from "lodash"
import { Fragment, useEffect, useRef, useState } from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { Circle, Image, Layer, Line, Stage, Text } from "react-konva"
import useImage from "use-image"

export default function Logs({ imgUrl, logs, reference, measurementArea }) {
  const [img] = useImage(imgUrl)
  const stageRef = useRef()
  const [scale, setScale] = useState(0)
  const [initialScale, setInitialScale] = useState(0)
  const [loading, setLoading] = useState(true)
  const fitStage = () => {
    const widthScale = window.innerWidth / img?.width
    const heightScale = window.innerHeight / img?.height
    const calculatedScale = widthScale < heightScale ? widthScale : heightScale
    setScale(calculatedScale * 0.5)
    setInitialScale(calculatedScale * 0.5)
    setLoading(false)
  }

  useEffect(() => {
    if (img) {
      fitStage()
    }
    // eslint-disable-next-line
  }, [img])

  const handleWheel = debounce(e => {
    const stage = stageRef.current
    const scale = stage.scaleX()
    const scaleBy = 1.25

    e.evt.preventDefault()
    let pointer = stage.getPointerPosition()

    let mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale
    }

    let newScale = e.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy

    // if (newScale > window.innerWidth / img.width) {
    let newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale
    }
    stage.position(newPos)
    setScale(newScale)
    // }
  }, 5)

  window.addEventListener("resize", fitStage)

  const handleZoomIn = () => setScale(scale => scale * 1.2)
  const handleZoomOut = () => setScale(scale => scale * 0.8)
  const reset = () => {
    setScale(initialScale)
    stageRef.current.position({ x: 0, y: 0 })
  }

  return (
    !loading && (
      <>
        <ButtonGroup>
          <Button
            size="lg"
            variant="dark"
            className="rounded-0"
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="me-2" />
            Zoom in
          </Button>
          <Button
            size="lg"
            variant="light"
            className="rounded-0"
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassMinus} className="me-2" />
            Zoom out
          </Button>
          <Button
            size="lg"
            variant="secondary"
            className="rounded-0"
            onClick={reset}
          >
            <FontAwesomeIcon
              icon={faDownLeftAndUpRightToCenter}
              className="me-2"
            />
            Reset
          </Button>
        </ButtonGroup>
        <Stage
          onWheel={handleWheel}
          draggable
          ref={stageRef}
          width={img.width * initialScale}
          height={img.height * initialScale}
          scale={{ x: scale, y: scale }}
        >
          <Layer>
            <Image image={img} />
            {logs.map(log => (
              <Fragment key={log.id}>
                <Circle
                  x={log.x}
                  y={log.y}
                  radius={Number(log.r)}
                  stroke="lime"
                  strokeWidth={2}
                  strokeScaleEnabled={false}
                  perfectDrawEnabled={false}
                  shadowForStrokeEnabled={false}
                  hitStrokeWidth={0}
                />
                <Text
                  x={log.r < 150 ? log.x - log.r * 0.7 : log.x - log.r * 0.85}
                  y={log.y - log.r * 0.25}
                  fillAfterStrokeEnabled="true"
                  stroke="black"
                  strokeWidth={log.r * 0.05}
                  shadowForStrokeEnabled={false}
                  perfectDrawEnabled={false}
                  fontSize={log.r * 0.7}
                  fill="white"
                  text={Number(log.d).toFixed(1)}
                />
              </Fragment>
            ))}
            {reference ? (
              <Line
                points={[
                  Number(reference.x1),
                  Number(reference.y1),
                  Number(reference.x2),
                  Number(reference.y2)
                ]}
                stroke={"red"}
                strokeWidth={8}
              />
            ) : null}
            {measurementArea ? (
              <Line
                points={measurementArea.reduce(
                  (points, coordinates) => [
                    ...points,
                    coordinates.x,
                    coordinates.y
                  ],
                  []
                )}
                stroke={"lime"}
                dashEnabled={true}
                dash={[4, 2]}
                strokeWidth={2}
                fill={"rgba(0, 255, 0, 25%)"}
                closed={true}
              />
            ) : null}
          </Layer>
        </Stage>
      </>
    )
  )
}
