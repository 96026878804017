import { Container, Row, Col, Image } from "react-bootstrap"
import LoginForm from "./LoginForm"
import { Trans } from "@lingui/macro"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const accessToken = localStorage.getItem("access_token")
  const accessTokenExpiresAt = localStorage.getItem("access_token_expires_at")
  window.Intercom("boot", {
    app_id: "zgewn51r"
  })

  useEffect(() => {
    if (accessToken && accessTokenExpiresAt > Date.now()) {
      navigate(location.state?.from?.pathname || "/", {
        replace: true
      })
    }
    // eslint-disable-next-line
  }, [accessToken])

  return (
    <Container className="position-absolute top-50 start-50 translate-middle">
      <title>
        <Trans>Login</Trans> | Timbeter
      </title>
      <Row className="justify-content-center">
        <Col xs="auto">
          <div className="text-center mb-4 mt-2">
            <Image
              style={{ width: "16rem" }}
              fluid
              src="/logos/timbeter.svg"
              alt="Timbeter logo"
            />
          </div>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  )
}
