import { EDIT } from "common/enums/constants"
import { Button, ButtonToolbar } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { editorSettingsState } from "."
import EditableItemsControl from "./EditableItemsControl"
import EditingLockControls from "./EditingLockControls"
import ModeControls from "./ModeControls"
import { measurementEditorState } from "./useEditorState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import {
  faCaretLeft,
  faCaretRight,
  faFlag,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus
} from "@fortawesome/free-solid-svg-icons"
import useNavigateMeasurements from "./useNavigateMeasurements"
import { useState } from "react"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import useFetch from "common/hooks/useFetch"
import useNavigateContainerMeasurements from "./useNavigateContainerMeasurements"
import useNavigateTruckMeasurements from "./useNavigateTruckMeasurements"
import useNavigateLumberMeasurements from "./useNavigateLumberMeasurements"
import useNavigateTruckDiameterMeasurements from "./useNavigateTruckDiameterMeasurements"
import useNavigateDuplicateMeasurements from "./useNavigateDuplicateMeasurements"

export default function ControlToolbar() {
  const mode = useRecoilValue(editorSettingsState("mode"))
  console.log(measurementEditorState)
  const {
    type: measurementType,
    storage,
    id: measurementId,
    duplicateCaseMeasurements = []
  } = useRecoilValue(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const { nextMeasurement, previousMeasurement } = useNavigateMeasurements()
  const { nextTruckMeasurement, previousTruckMeasurement } =
    useNavigateTruckMeasurements()
  const { nextContainerMeasurement, previousContainerMeasurement } =
    useNavigateContainerMeasurements()
  const { nextLumberMeasurement, previousLumberMeasurement } =
    useNavigateLumberMeasurements()
  const { nextTruckDiameterMeasurement, previousTruckDiameterMeasurement } =
    useNavigateTruckDiameterMeasurements()
  const { previousDuplicateMeasurement, nextDuplicateMeasurement } =
    useNavigateDuplicateMeasurements()
  const [loading, setLoading] = useState(false)
  const [poorDetection, setPoorDetection] = useState(
    measurement?.poor_detection
  )
  const fetch = useFetch()

  const updatePoorDetection = () => {
    fetch(`/measurements/${measurementId}`, {
      method: "PATCH",
      body: {
        poor_detection: !poorDetection
      }
    }).then(updatedMeasurement => {
      // console.log(updatedMeasurement)
      setPoorDetection(!poorDetection)
    })
  }

  return (
    <ButtonToolbar>
      <ModeControls />
      {mode === EDIT && <EditableItemsControl />}
      {measurementType === "container" && <EditingLockControls />}
      <div className="d-flex">
        <Button
          variant="outline-light"
          onClick={() => {
            window.dispatchEvent(new Event("zoom_in"))
          }}
          className={`d-flex flex-column justify-content-center rounded-0 border-0 h-100 opacity-50 border-start border-end border-light border-opacity-10 align-items-center`}
        >
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        </Button>
        <Button
          variant="outline-light"
          onClick={() => {
            window.dispatchEvent(new Event("zoom_out"))
          }}
          className={`d-flex flex-column justify-content-center h-100 rounded-0 border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
        >
          <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
        </Button>
        <Button
          variant="outline-light"
          onClick={() => {
            window.dispatchEvent(new Event("reset_zoom"))
          }}
          className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
        >
          <span className="mt-1" style={{ fontSize: "0.625rem" }}>
            <Trans>Reset zoom</Trans>
          </span>
        </Button>
        {measurementType === "container" && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousContainerMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextContainerMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {storage && measurementType === "truck" && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousTruckMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextTruckMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {measurementType === "lumber" && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousLumberMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextLumberMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {storage && measurementType === "timber" && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {measurementType === "timber" && duplicateCaseMeasurements?.length > 0 && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousDuplicateMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextDuplicateMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {measurementType === "truck_diameter" && (
          <>
            <Button
              variant="outline-light"
              id="previous-measurement"
              disabled={loading}
              onClick={() => {
                previousTruckDiameterMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              variant="outline-light"
              id="next-measurement"
              disabled={loading}
              onClick={() => {
                nextTruckDiameterMeasurement()
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
              }}
              className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 opacity-50 border-end border-light border-opacity-10 align-items-center`}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </>
        )}
        {measurementType === "timber" && (
          <Button
            variant="outline-light"
            onClick={() => {
              if (
                window.confirm(
                  poorDetection
                    ? t`This measurement is already flagged for poor log detection quality. Click OK to undo it`
                    : t`Click OK if the measurement has poor log detection quality`
                )
              ) {
                updatePoorDetection()
              }
            }}
            className={`d-flex flex-column rounded-0 h-100 justify-content-center border-0 ${
              poorDetection && "text-danger"
            } opacity-50 border-end border-light border-opacity-10 align-items-center`}
          >
            <FontAwesomeIcon icon={faFlag} size="xs" />
          </Button>
        )}
      </div>
    </ButtonToolbar>
  )
}
