import { Spinner as BootstrapSpinner } from "react-bootstrap"

export default function Spinner({ variant }) {
  return (
    <BootstrapSpinner
      as="span"
      animation="border"
      size="sm"
      variant={variant || "light"}
      role="status"
      aria-hidden="true"
    />
  )
}
