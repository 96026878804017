import { Row, Col, Button } from "react-bootstrap"
import MeasurementLocation from "./MeasurementLocation"
import MeasurementTimber from "./MeasurementDetails"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import MeasurementLogs from "./MeasurementLogs"
import { measurementsStateFamily } from "common/recoil/atoms"
import { Suspense, useEffect } from "react"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import MeasurementDevice from "./MeasurementDevice"
import { Trans, t } from "@lingui/macro"
import useExportsState from "exports/useExportsState"
import normalizeJson from "common/utils/normalizeJson"
import MeasurementVolume from "./MeasurementVolume"
import MeasurementCulls from "./MeasurementCulls"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

export default function MeasurementSingleView() {
  const navigate = useNavigate()
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(measurementId)
  )
  const fetch = useFetch()
  const { createMeasurementPdf } = useExportsState()
  useEffect(() => {
    if (!measurement) {
      fetch(
        `/measurements/${measurementId}?${new URLSearchParams({
          fields:
            "storage_id,weight,weight_density,photo,photo_optimized,latitude,longitude,photo_thumbnail,volume,coefficient,wood_type_id,log_length,shipment_number,measurement_type_id,wood_quality_id,assortment_id,volume_formula,arrival_date,photo_file_name,load_lines,measurement_area,device_id,poor_detection",
          extra_fields: "logs,references,load_json,custom_fields,logs_sheet"
        })}`
      ).then(response => {
        setMeasurement(normalizeJson(response.data))
      })
    }
    // eslint-disable-next-line
  }, [])

  return measurement ? (
    <Suspense fallback={<Loading text={t`Loading measurement...`} />}>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col>
              <span
                className="fw-medium text-white-50 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
                <Trans>Back to dashboard</Trans>
              </span>
              <hr className="text-light" />

              <h5 className="text-light mb-0">
                {measurement.photo_file_name}
                <Button
                  onClick={() => createMeasurementPdf(measurement.id)}
                  size="sm"
                  variant="light"
                  className="ms-3"
                >
                  <Trans>Export to PDF</Trans>
                </Button>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="text-white-50">
              <b className="me-1">ID:</b>
              <span>{measurement.id}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xxl={4}>
          <MeasurementLocation measurement={measurement} />
          <MeasurementDevice deviceId={measurement.device_id} />
          {measurement.logs?.length !== 0 && (
            <MeasurementLogs measurement={measurement} />
          )}
        </Col>
        <Col lg={6} xxl={4} className="mt-4 mt-md-0">
          <MeasurementTimber measurement={measurement} />
        </Col>
      </Row>
      <Row>
        <Col>
          <MeasurementVolume
            logs={measurement.logs}
            measurementId={measurement.id}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MeasurementCulls logs={measurement.logs} />
        </Col>
      </Row>
    </Suspense>
  ) : (
    <Loading />
  )
}
