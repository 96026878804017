import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { deviceKeySelector } from "common/recoil/atoms"
import useAuthActions from "login/useAuthActions"
import { Alert, Button, Card, Form, Image } from "react-bootstrap"
import { useRecoilValue } from "recoil"

export default function Setup() {
  const deviceKey = useRecoilValue(deviceKeySelector)
  const { logOut } = useAuthActions()

  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="text-center mb-4 mt-2">
        <Image style={{ width: "16rem" }} fluid src="/logos/timbeter.svg" />
      </div>
      <Card>
        <Card.Body>
          {deviceKey ? (
            <>
              <div className="d-flex align-items-center mb-3">
                <p>
                  To start using Timbeter you will have to connect a device.
                </p>
              </div>
              <div className="p-3 text-center bg-dark rounded">
                <h6 className="py-1 rounded text-light text-center">
                  1. Download Timbeter app
                </h6>
                <p className="small text-muted">
                  Install our iOS or Android app and log in.
                </p>
                <div className="pt-2">
                  <a
                    target="_blank"
                    className="me-3"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/developer?id=Timbeter+Ltd&hl=en"
                  >
                    <img
                      alt="Google Play"
                      src="https://timbeter.com/wp-content/themes/timbeter-theme/dist/images/icons/android.svg"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/developer/timbeter-o%C3%BC/id1249312901"
                    rel="noreferrer"
                  >
                    <img
                      alt="App Store"
                      src="https://timbeter.com/wp-content/themes/timbeter-theme/dist/images/icons/ios.svg"
                    />
                  </a>
                </div>
              </div>
              <div className="p-3 text-center bg-dark rounded mt-3">
                <h6 className="py-1 rounded text-light text-center">
                  2. Enter the code
                </h6>
                <p className="small text-muted">
                  Open the app and enter the code.
                </p>
                <div className="justify-content-center mt-3">
                  <Form.Control
                    disabled
                    readOnly
                    defaultValue={deviceKey}
                    className="font-monospace text-center mb-3"
                  />
                </div>
              </div>
              <div className="mt-3 text-center">
                <p className="small text-muted mb-3">
                  <Trans>After you have entered the code click confirm.</Trans>
                </p>
                <Button
                  className="w-full"
                  onClick={() => window.location.replace("/")}
                >
                  <Trans>Confirm</Trans>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex align-items-center mb-3">
                <p>
                  To start using Timbeter you will have to connect a device.
                </p>
              </div>
              <div className="mt-4">
                <Alert variant="warning" className="mb-0">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="lg"
                    className="me-2 opacity-50"
                  />
                  <span className="small">
                    <Trans>
                      No device invitation key available. Contact our support.
                    </Trans>
                  </span>
                </Alert>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
      <Button
        variant="link"
        className="text-light w-full text-decoration-none fw-medium"
        onClick={logOut}
      >
        <Trans>Log out</Trans>
      </Button>
    </div>
  )
}
