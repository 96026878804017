import { formatDate } from "common/utils/formatDateTime"
import { selectorFamily, useRecoilState } from "recoil"
import { containerExpandedState } from "common/recoil/atoms"
import ContainerMeasurementRowList from "./ContainerMeasurementRowList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretDown,
  faCaretRight,
  faFileExcel,
  faShareNodes,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons"
import billOptions from "./billOptions"
import { Dropdown } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import EditContainerModal from "./EditContainerModal"
import { Suspense } from "react"
import useExportsState from "exports/useExportsState"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { querySelector } from "common/recoil/selectors"
import { toast } from "react-toastify"
import { handleCopyToClipboard } from "timber-measurement/TimberStorageRow"
import Spinner from "common/other/Spinner"

export const containerQuery = selectorFamily({
  key: "containerQuery",
  get:
    id =>
    ({ get }) =>
      get(querySelector("/containers/containers/" + id))
})

export default function ContainerRow({ container }) {
  const [expanded, setExpanded] = useRecoilState(
    containerExpandedState(container.id)
  )
  const toggleExpand = () => setExpanded(!expanded)
  const { createContainerLogByLogReport } = useExportsState()
  const formatVolume = useVolumeFormatter()
  const deleteContainer = () =>
    toast(<Trans>This feature is currently unavailable</Trans>)

  return (
    container && (
      <>
        <tr className={`bg-white`}>
          <td
            style={{ width: "2rem" }}
            className="text-primary fw-semibold hover-dark cursor-pointer"
            title={container.name}
            onClick={toggleExpand}
          >
            <div
              className={`d-flex align-items-center hover-dark cursor-pointer`}
            >
              {
                <div className="text-center" style={{ width: "2rem" }}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={expanded ? faCaretDown : faCaretRight}
                    className="text-primary"
                  />
                </div>
              }
              <div className={`ms-2 text-primary`}> {container.name}</div>
            </div>
          </td>
          <td>{formatDate(container.created_at)}</td>
          <td>{container.container_log_count || 0}</td>
          <td>{formatVolume(container.container_volume)}</td>
          <td />
          <td className="d-flex justify-content-end align-items-center">
            <Dropdown>
              <Dropdown.Toggle as={billOptions} />
              <Dropdown.Menu rootCloseEvent="click">
                <EditContainerModal container={container} />
                {container.container_log_count > 0 && (
                  <Dropdown.Item
                    onClick={() => createContainerLogByLogReport(container.id)}
                  >
                    <Trans>Export to Excel</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faFileExcel} />
                    </span>
                  </Dropdown.Item>
                )}
                {container?.token && (
                  <Dropdown.Item
                    onClick={() =>
                      handleCopyToClipboard(
                        "/public_container/",
                        container.token
                      )
                    }
                  >
                    <Trans>Share</Trans>
                    <span className="float-end">
                      <FontAwesomeIcon icon={faShareNodes} />
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={deleteContainer}>
                  <Trans>Delete</Trans>
                  <span className="float-end">
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
        {expanded && (
          <Suspense
            fallback={
              <tr>
                <td>
                  <Spinner variant={"dark"} />
                </td>
              </tr>
            }
          >
            <ContainerMeasurementRowList containerId={container.id} />
          </Suspense>
        )}
      </>
    )
  )
}
