import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import App from "./App"
import { RecoilRoot } from "recoil"
import ErrorPage from "common/other/ErrorPage"

import "App.scss"
import "App.css"
import "leaflet/dist/leaflet.css"
import "react-leaflet-markercluster/dist/styles.min.css"
import "react-toastify/dist/ReactToastify.css"
import LoadingPage from "common/other/LoadingPage"

const isDevelopment = process.env.NODE_ENV === "development"
if (!isDevelopment) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_NOTIFIER_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production", "staging"],
    releaseStage: process.env.REACT_APP_ENV
  })
}

const ErrorBoundary = !isDevelopment
  ? Bugsnag.getPlugin("react").createErrorBoundary(React)
  : null

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RecoilRoot>
        {!isDevelopment ? (
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Suspense fallback={<LoadingPage />}>
              <App />
            </Suspense>
          </ErrorBoundary>
        ) : (
          <Suspense fallback={<LoadingPage />}>
            <App />
          </Suspense>
        )}
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)
