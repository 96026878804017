import { Trans } from "@lingui/macro"
import { Button, Card, Table } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { getReportById } from "./useReportsState"
import MeasurementSquare, {
  MeasurementSquareError,
  MeasurementSquareLoading
} from "analyze_reports/MeasurementSquare"
import { Suspense, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { volumeFormulasState } from "common/recoil/atoms"
import useExportsState from "exports/useExportsState"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import { volumeUnitSelector } from "common/recoil/selectors"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import currencyToSymbol from "common/utils/currencyToSymbol"
import ErrorBoundary from "common/other/ErrorBoundary"

export default function EditReportCard() {
  const { id } = useParams()
  const [report, setReport] = useRecoilState(getReportById(id))
  // const reportData = useRecoilValue(reportDataByIdQuery(id))
  const [reportData, setReportData] = useState()
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const { createReportsExcel } = useExportsState()
  const fetch = useFetch()
  const confirmReport = () => {
    fetch(`/reports/${id}/confirm`, {
      method: "PATCH"
    }).then(response => {
      if (response) {
        setReport({
          confirmed: true
        })
        toast.success(<Trans>Report confirmed</Trans>)
      }
    })
  }

  useEffect(() => {
    fetch("/reports/" + id).then(response => {
      setReportData(response)
    })
    // eslint-disable-next-line
  }, [])

  return reportData ? (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex align-items-center">
          <span className="flex-grow-1 fw-semibold fs-4">
            {report.name}
            <Button
              className="ms-2"
              size="sm"
              disabled={report.confirmed}
              onClick={confirmReport}
            >
              {report.confirmed ? (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  <Trans>Confirmed</Trans>
                </>
              ) : (
                <Trans>Confirm report</Trans>
              )}
            </Button>
          </span>
        </Card.Title>
        {report.measurement_ids?.length > 0 ? (
          <Button
            onClick={() => createReportsExcel(report.id)}
            className="mb-3"
          >
            <FontAwesomeIcon icon={faFileExcel} className="me-2" />
            <Trans>Export to excel</Trans>
          </Button>
        ) : null}
        {/* <Button>
        <Trans>Delete</Trans>
      </Button> */}
        <p className="small text-muted">
          <Trans>Reported at</Trans>{" "}
          {new Date(report.reported_at).toLocaleDateString()}
        </p>
        <hr />
        <h5>Included measurements</h5>
        <div className="rounded d-flex flex-wrap">
          {report.measurement_ids?.length ? (
            report.measurement_ids?.map(id => (
              <ErrorBoundary
                key={id}
                fallback={<MeasurementSquareError id={id} />}
              >
                <Suspense fallback={<MeasurementSquareLoading key={id} />}>
                  <MeasurementSquare key={id} id={id} />
                </Suspense>
              </ErrorBoundary>
            ))
          ) : (
            <>
              <p>There are no measurements in this report.</p>
              <p>
                You can add measurements to reports by selecting them in the{" "}
                <Link to="/">dashboard</Link>
              </p>
            </>
          )}
        </div>
        {reportData.logs?.length > 0 ? (
          <>
            <hr />
            <h5>
              <Trans>Logs</Trans>
              <span className="ms-2 text-muted small">
                Volume formula: {volumeFormulas[report.volume_formula]}
              </span>
            </h5>

            <ReportLogs
              logs={reportData.logs}
              assortments={reportData.characteristics}
              culls={reportData.culls}
            />
            <hr />
            <h5>Summary</h5>
            <ReportSummary
              logs={reportData.logs}
              assortments={reportData.characteristics}
              culls={reportData.culls}
            />
            <hr />
            <h5>Culls</h5>
            <ReportCulls culls={reportData.culls} logs={reportData.logs} />
          </>
        ) : null}
      </Card.Body>
    </Card>
  ) : null
}

function ReportLogs({ logs, assortments, culls }) {
  let diameters = [...new Set(logs.map(log => log.diameter))]
  diameters.sort()

  return (
    <Table bordered hover size="sm" responsive>
      <thead>
        <tr>
          <th>Diameter</th>
          {assortments.map(a => (
            <th key={a.name}>{a.name}</th>
          ))}
          <th>
            <Trans>Cull</Trans>
          </th>
          <th>
            <Trans>Total</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {diameters.map(d => {
          const count = logs
            .filter(log => log.diameter === d)
            .reduce((acc, val) => acc + val.log_count, 0)
          const totalVolume = logs
            .filter(log => log.diameter === d)
            .reduce((acc, val) => acc + Number(val.volume), 0)
            .toFixed(4)

          return (
            <tr key={d}>
              <td>{d}</td>
              {assortments.map(a => (
                <td key={a.id}>
                  <span className=" small fw-bold me-1">
                    {
                      logs.find(
                        $ =>
                          $.characteristic_id === a.id &&
                          $.diameter === d &&
                          !$.cull &&
                          !$.cull_id
                      )?.log_count
                    }{" "}
                  </span>
                  <span>
                    {logs
                      .filter($ => $.diameter === d && !$.cull && !$.cull_id)
                      ?.reduce((acc, val) => acc + Number(val.volume), 0)
                      .toFixed(4)}
                  </span>
                </td>
              ))}
              <td>
                <span className="small fw-bold me-1">
                  {logs.filter($ => $.diameter === d && ($.cull || $.cull_id))
                    ?.length > 0
                    ? logs
                        .filter($ => $.diameter === d && ($.cull || $.cull_id))
                        ?.reduce((acc, val) => acc + Number(val.log_count), 0)
                    : null}
                </span>
                {logs.filter($ => $.diameter === d && ($.cull || $.cull_id))
                  ?.length > 0
                  ? logs
                      .filter($ => $.diameter === d && ($.cull || $.cull_id))
                      ?.reduce((acc, val) => acc + Number(val.volume), 0)
                      .toFixed(4)
                  : null}
              </td>
              <td>
                <span className="small fw-bold me-1">{count}</span>{" "}
                {totalVolume}
              </td>
            </tr>
          )
        })}
        <tr className="table-info">
          <td>Total</td>
          <td>
            <span className=" small fw-bold me-1">
              {logs
                .filter($ => !$.cull && !$.cull_id)
                ?.reduce((acc, val) => acc + Number(val.log_count), 0)}{" "}
            </span>
            <span>
              {logs
                .filter($ => !$.cull && !$.cull_id)
                ?.reduce((acc, val) => acc + Number(val.volume), 0)
                .toFixed(4)}
            </span>
          </td>
          <td>
            <span className=" small fw-bold me-1">
              {logs.filter($ => $.cull_id || $.cull)?.length > 0
                ? logs
                    .filter($ => $.cull_id || $.cull)
                    ?.reduce((acc, val) => acc + Number(val.log_count), 0)
                : null}{" "}
            </span>
            <span>
              {logs.filter($ => $.cull_id || $.cull)?.length > 0
                ? logs
                    .filter($ => $.cull_id || $.cull)
                    ?.reduce((acc, val) => acc + Number(val.volume), 0)
                    .toFixed(4)
                : null}
            </span>
          </td>
          <td>
            <span className=" small fw-bold me-1">
              {logs?.reduce((acc, val) => acc + Number(val.log_count), 0)}{" "}
            </span>
            <span>
              {logs
                ?.reduce((acc, val) => acc + Number(val.volume), 0)
                .toFixed(4)}
            </span>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

function ReportSummary({ logs, assortments, culls }) {
  // let diameters = [...new Set(logs.map(log => log.diameter))]
  // diameters.sort()
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const org = useRecoilValue(currentOrganizationState)
  const totalCullVolume = logs
    .filter($ => $.cull_id || $.cull)
    ?.reduce((acc, val) => acc + Number(val.volume), 0)
  const totalVolume = logs?.reduce((acc, val) => acc + Number(val.volume), 0)
  const cullPercentage = ((totalCullVolume / totalVolume) * 100).toFixed(2)
  let totalAssortmentSum = 0

  return (
    <Table bordered hover size="sm" responsive>
      <thead>
        <tr>
          <th>
            <Trans>Wood assortment</Trans>
          </th>
          <th>
            <Trans>Volume</Trans>
          </th>
          <th>
            <Trans>Percentage</Trans>
          </th>
          <th>
            <Trans>Price per</Trans> {volumeUnit}
          </th>
          <th>
            <Trans>Sum</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {assortments.map(a => {
          const volume = logs
            .filter($ => !$.cull && !$.cull_id)
            ?.reduce((acc, val) => acc + Number(val.volume), 0)
          const percentage = (volume / totalVolume) * 100
          totalAssortmentSum = totalAssortmentSum + volume * a.price
          return (
            <tr key={a.id}>
              <td>{a.name}</td>
              <td>
                {volume.toFixed(4)} {volumeUnit}
              </td>
              <td>{percentage.toFixed(2)}%</td>
              <td>
                {a.price}
                {currencyToSymbol(org.currency)}
              </td>
              <td>
                {(volume * a.price).toFixed(2)}
                {currencyToSymbol(org.currency)}
              </td>
            </tr>
          )
        })}
        <tr>
          <td>
            <Trans>Cull</Trans>
          </td>
          <td>
            {totalCullVolume.toFixed(4)} {volumeUnit}
          </td>
          <td>{cullPercentage}%</td>
          <td>0</td>
          <td>0</td>
        </tr>
        <tr className="table-info">
          <td colSpan={4}>
            <Trans>Sum total</Trans>
          </td>
          <td>
            {totalAssortmentSum.toFixed(2)}
            {currencyToSymbol(org.currency)}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

function ReportCulls({ culls, logs }) {
  const volumeUnit = useRecoilValue(volumeUnitSelector)

  return (
    <Table size="sm" bordered hover responsive>
      <thead>
        <tr>
          <th>
            <Trans>Reason</Trans>
          </th>
          <th>
            <Trans>Volume</Trans>
          </th>
          <th>
            <Trans>Count</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {culls.map(c => (
          <tr key={c.name}>
            <td>{c.name}</td>
            <td>
              {logs
                .filter(
                  l =>
                    l.cull === c.name ||
                    (l.cull_id === c.id && !l.characteristic_id)
                )
                .reduce((acc, val) => acc + Number(val.volume), 0)
                .toFixed(4)}{" "}
              {volumeUnit}
            </td>
            <td>
              {logs
                .filter(
                  l =>
                    l.cull === c.name ||
                    (l.cull_id === c.id && !l.characteristic_id)
                )
                .reduce((acc, val) => acc + Number(val.log_count), 0)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
