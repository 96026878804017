import { useEffect, useState } from "react"
import {
  faCamera,
  faCloudArrowUp,
  faMagnifyingGlass,
  faMapMarkerAlt,
  faMessage,
  faPencilRuler,
  faRefresh,
  faUpLong
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import measurementTypes from "common/enums/measurementTypes"
import { Link, useSearchParams } from "react-router-dom"
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementsStateFamily,
  selectedMeasurementsState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import {
  isMeasurementSelectedState,
  lengthUnitSelector
} from "common/recoil/selectors"
import { measurementEditorState } from "./editor/useEditorState"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { Trans } from "@lingui/macro"

export default function TimberMeasurementRow(props) {
  const [searchParams] = useSearchParams()
  const assortments = useRecoilValue(woodAssortmentsState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const woodTypes = useRecoilValue(woodTypesState)
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(props.measurement.id)
  )
  useEffect(() => {
    if (!measurement) {
      setMeasurement(props.measurement)
    }
    // eslint-disable-next-line
  }, [])

  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const isSelected = useRecoilValue(isMeasurementSelectedState(measurement?.id))
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const formatVolume = useVolumeFormatter()
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const [commentVisible, setCommentVisible] = useState(false)

  let takenAt = measurement?.taken_at
  let updatedAt = measurement?.updated_at
  let createdAt = measurement?.created_at
  let measured_at = measurement?.measured_at
  getMeasurementType()
  return (
    measurement && (
      <>
        <tr
          className={`${
            searchParams.get("id") === measurement.id
              ? "bg-body-secondary"
              : "bg-white"
          }`}
        >
          <td>
            <input
              disabled={measurement.inventory_id && "d-none"}
              type="checkbox"
              onChange={() => {
                if (isSelected) {
                  setSelectedMeasurements(
                    selectedMeasurements.filter($ => $.id !== measurement.id)
                  )
                } else {
                  setSelectedMeasurements([
                    ...selectedMeasurements,
                    measurement
                  ])
                }
              }}
              checked={isSelected}
            />
          </td>
          <td>
            <div className="d-flex align-items-center">
              {props.manual ? (
                <>
                  <div
                    className="me-2 border rounded d-flex align-items-center justify-content-center"
                    style={{
                      width: "62px",
                      height: "62px"
                    }}
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={faPencilRuler}
                      className="text-primary"
                    />
                  </div>
                  <Link
                    className="text-primary link"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: "/measurement/" + measurement.id,
                      state: { measurement }
                    }}
                  >
                    <span>Manual measurement</span>
                    <br />
                    <span className="text-black-50">
                      {measurement?.taken_at?.substring(0, 10)}
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  <Image
                    thumbnail
                    src={measurement.photo_thumbnail}
                    onError={e =>
                      (e.target.src = "/android-chrome-192x192.png")
                    }
                    onClick={() => {
                      window.history.pushState("forward", null, "#editor")
                      measurementEditor({
                        id: measurement.id,
                        type: "timber",
                        storage: props?.storage
                      })
                    }}
                    className="me-2 hover-dark"
                    style={{
                      width: "64px",
                      height: "64px",
                      cursor: "pointer"
                    }}
                    title={measurement.id}
                  />
                  <p style={{ whiteSpace: "break-spaces" }}>
                    <Link
                      className={
                        measurement.inventory_id
                          ? "text-warning link"
                          : "text-primary link"
                      }
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname: "/measurement/" + measurement.id,
                        state: { measurement }
                      }}
                    >
                      {measurement.photo_file_name
                        ? measurement.photo_file_name.split(".")[0]
                        : "NO_PHOTO_ERROR"}
                    </Link>
                    {/* {measurement.validity && (
              <FontAwesomeIcon
                title="Valid measurement"
                icon={faCircleCheck}
                size="lg"
                className="ms-2 text-success"
              />
            )} */}
                    <br />
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div className="text-start">
                            <div>
                              <FontAwesomeIcon
                                icon={faRefresh}
                                className="me-2"
                              />
                              <span className="small">
                                <Trans>Updated at</Trans> {updatedAt}
                              </span>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faUpLong}
                                className="opacity-25"
                                size="lg"
                              />
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCloudArrowUp}
                                className="me-2"
                              />
                              <span className="small">
                                <Trans>Synced at</Trans> {createdAt}
                              </span>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faUpLong}
                                className="opacity-25"
                                size="lg"
                              />
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="me-2"
                              />
                              <span className="small">
                                <Trans>Analyzed at</Trans> {measured_at}
                              </span>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faUpLong}
                                className="me-2 opacity-25"
                                size="lg"
                              />
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCamera}
                                className="me-2"
                              />
                              <span className="small">
                                <Trans>Photo taken at</Trans> {takenAt}
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      }
                    >
                      <span className="text-black-50 small">
                        <span className="fw-medium text-muted me-2">
                          {measurement.id}
                        </span>
                        {measurement?.taken_at?.substring(0, 10)}
                      </span>
                    </OverlayTrigger>
                    {measurement?.comment && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        onClick={() => setCommentVisible(!commentVisible)}
                        className={`ms-2 opacity-${
                          commentVisible ? "75" : "25"
                        }`}
                        size="sm"
                      />
                    )}
                  </p>
                </>
              )}
            </div>
          </td>
          <td className="d-none d-lg-table-cell">
            {measurementTypes[measurement.measurement_type_id] || "ENUM_ERROR"}
          </td>
          <td className="text-center  d-none d-lg-table-cell">
            {measurement.latitude > 0 && measurement.longitude > 0 ? (
              <FontAwesomeIcon
                title={`${measurement.latitude}\n${measurement.longitude}`}
                size="xl"
                icon={faMapMarkerAlt}
                className="text-primary hover-dark cursor-pointer"
                onClick={props.showOnMap}
              />
            ) : (
              <FontAwesomeIcon
                size="xl"
                icon={faMapMarkerAlt}
                className="text-secondary"
              ></FontAwesomeIcon>
            )}
          </td>
          <td
            className="text-truncate d-none d-lg-table-cell"
            style={{ maxWidth: "10rem" }}
          >
            {getMeasurementType()}
          </td>
          <td className="d-none d-lg-table-cell">
            {measurement.log_length} {lengthUnit}
          </td>
          <td className="d-none d-lg-table-cell">{measurement.logs.length}</td>
          <td style={{ textAlign: "center" }}>
            {formatVolume(measurement.volume)}
            <br />
            <small className="text-muted">{measurement.coefficient}</small>
          </td>
        </tr>
        {commentVisible && (
          <tr className="bg-secondary-subtle">
            <td colSpan={8}>{measurement.comment}</td>
          </tr>
        )}
      </>
    )
  )

  function getMeasurementType() {
    if (measurement?.assortment_id) {
      return assortments?.find($ => $.id === measurement?.assortment_id)?.name
    } else if (measurement?.wood_quality_id) {
      return (
        <>
          <span>
            {woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name}
          </span>
          <br />
          <span className="small text-muted">
            {
              woodQualities?.find($ => $.id === measurement?.wood_quality_id)
                ?.name
            }
          </span>
        </>
      )
      // type and quality
    }

    return woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name
  }
}
