import { Card, Button, Table } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { useRecoilValue } from "recoil"
import { volumeFormulasState } from "common/recoil/atoms"
import useExportsState from "exports/useExportsState"
import { replaceObjectKeys } from "common/utils/normalizeJson"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel, faTable } from "@fortawesome/free-solid-svg-icons"

export default function MeasurementLogs({ measurement }) {
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const { createLogByLogReport } = useExportsState()

  measurement = {
    ...measurement,
    logs_sheet: {
      logs: measurement.logs_sheet.logs,
      summary: replaceObjectKeys(measurement.logs_sheet.summary)
    }
  }

  return (
    <Card className="mt-4">
      <Card.Header className="d-inline-flex align-items-center justify-content-between flex-wrap">
        <span>
          <FontAwesomeIcon icon={faTable} className="me-2" />
          <Trans>Logs diameters</Trans>
        </span>
        <Button size="sm" onClick={() => createLogByLogReport(measurement.id)}>
          <FontAwesomeIcon icon={faFileExcel} className="me-2" />
          <Trans>Export to Excel</Trans>
        </Button>
      </Card.Header>
      <MeasurementLogsTable />
    </Card>
  )

  function MeasurementLogsTable() {
    return (
      <Table size="sm" className="caption-top">
        <caption className="ms-3 small">
          <Trans>
            {volumeFormulas
              ? volumeFormulas[measurement.volume_formula] ||
                measurement.volume_formula ||
                "–"
              : "Loading..."}{" "}
            volume formula
          </Trans>
        </caption>
        <thead>
          <tr>
            <th />
            <th>
              <Trans>Diameter</Trans>
            </th>
            <th>
              <Trans>Log length</Trans>
            </th>
            <th>
              <Trans>Count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {measurement.logs_sheet.logs.map(($, i) => (
            <tr key={i}>
              <td />
              <td>
                {$.diameter}{" "}
                <small>{measurement.logs_sheet.summary["diameter-unit"]}</small>
              </td>
              <td>
                {$["log-length"]}{" "}
                <small>{measurement.logs_sheet.summary["length-unit"]}</small>
              </td>
              <td>{$.logs}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="table-info">
            <td className="p-2">
              <small>
                <Trans>Average</Trans>
              </small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.summary.average_diameter}{" "}
              <small>{measurement.logs_sheet.summary.diameter_unit}</small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.logs[0]["log-length"]}{" "}
              <small>{measurement.logs_sheet.summary.length_unit}</small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.summary.total_logs}{" "}
              <small>
                <Trans>in total</Trans>
              </small>
            </td>
          </tr>
        </tfoot>
      </Table>
    )
  }
}
